import React from 'react';

// Utility function to adjust color brightness
const adjustBrightness = (color, amount) => {
    let colorValue = color.replace('#', '');
    if (colorValue.length === 3) {
        colorValue = colorValue.split('').map((c) => c + c).join('');
    }

    const num = parseInt(colorValue, 16);
    const r = Math.min(255, Math.max(0, (num >> 16) + amount));
    const g = Math.min(255, Math.max(0, ((num >> 8) & 0x00FF) + amount));
    const b = Math.min(255, Math.max(0, (num & 0x0000FF) + amount));

    return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
};

// Function to generate the cylinder path with a subtler bottom arc
// const getCylinderPath = (x, y, width, height) => {
//     const topRadius = width / 2;           // Full width radius for the top arc
//     const bottomRadius = width / 4;        // Smaller radius for the bottom arc
//     const topY = y;
//     const bottomY = y + height;

//     console.log(bottomRadius);


//     return `M${x},${bottomY}
//             A${bottomRadius},${bottomRadius} 0 0,1 ${x + width},${bottomY}
//             L${x + width},${topY}
//             A${topRadius},${topRadius} 0 0,0 ${x},${topY}
//             Z`;
// };

// const getCylinderPath = (x, y, width, height) => {
//     const topRadius = width / 2;           // Full width radius for the top arc
//     const bottomRadius = width / 4;        // Smaller radius for the bottom arc
//     const topY = y;                        // Y position for the top of the cylinder
//     const bottomY = y + height;            // Y position for the bottom of the cylinder

//     return `
//         M${x},${bottomY}                             // Move to the bottom left
//         A${bottomRadius},${bottomRadius} 0 0,1 ${x + width},${bottomY} // Bottom arc
//         L${x + width},${topY + topRadius}            // Line to the top right arc
//         A${topRadius},${topRadius} 0 0,0 ${x},${topY + topRadius}  // Top arc
//         L${x},${bottomY}                              // Line back to bottom left
//         Z`;                                           // Close the path
// };

// Function to generate a shiny cylinder path
const getShinyCylinderPath = (x, y, width, height) => {
    const topRadius = width / 2;           // Full width radius for the top arc
    const bottomRadius = width / 4;        // Smaller radius for the bottom arc
    const topY = y;                        // Y position for the top of the cylinder
    const bottomY = y + height;            // Y position for the bottom of the cylinder

    return `
        M${x},${bottomY}                             // Move to the bottom left
        A${bottomRadius},${bottomRadius} 0 0,1 ${x + width},${bottomY} // Bottom arc
        L${x + width},${topY + topRadius}            // Line to the top right arc
        A${topRadius},${topRadius} 0 0,0 ${x},${topY + topRadius}  // Top arc
        L${x},${bottomY}                              // Line back to bottom left
        Z`;                                           // Close the path
};

const getCylinderPath = (x, y, width, height, fatbar) => {
    var topRadius = width / 2;           // Full width radius for the top arc
    var bottomRadius = width / 6;        // Smaller radius for the bottom arc
    var middleRadius = width / 4;        // Smaller radius for the bottom arc
    var topY = y;                        // Y position for the top of the cylinder
    var bottomY = y + height;            // Y position for the bottom of the cylinder
    if (fatbar) {
        topRadius = width * 1.4;// / 10;
        bottomRadius = 0;
        middleRadius = 0;
    }

    return `
        M${x},${bottomY}                             
        A${bottomRadius},${bottomRadius} 0 0,1 ${x + width},${bottomY} 
        L${x + width},${topY + middleRadius}            
        A${topRadius},${topRadius} 0 0,0 ${x},${topY + middleRadius}  
        L${x},${bottomY}                              
        Z`;                                           
};

const getCylinderPathLower = (x, y, width, height, fatbar) => {
    
    var topRadius = width / 2;           // Full width radius for the top arc
    var bottomRadius = width * 1.2;// / 6;        // Smaller radius for the bottom arc
    var middleRadius = width / 10;        // Smaller radius for the bottom arc
    var topY = y;                        // Y position for the top of the cylinder
    var bottomY = y + height;            // Y position for the bottom of the cylinder
    if (fatbar) {
        topRadius = width * 1.4;// / 10;
        bottomRadius = 0;
        middleRadius = 0;
    }

    return `
        M${x},${bottomY}                             
        A${bottomRadius},${bottomRadius} 0 0,1 ${x + width},${bottomY} 
        L${x + width},${topY + middleRadius}            
        A${topRadius},${topRadius} 0 0,0 ${x},${topY + middleRadius}  
        L${x},${bottomY}                              
        Z`;                                           
};

const getOppositeArcCylinderPath = (x, y, width, height) => {
    const topRadius = width / 2;         // Radius for the top arc
    const bottomRadius = width / 2;      // Radius for the bottom arc
    const bottomY = y + height;          // Bottom Y position

    return `
        M${x},${y} 
        A${topRadius},${topRadius} 0 0,1 ${x + width},${y} 
        L${x + width},${bottomY} 
        A${bottomRadius},${bottomRadius} 0 0,0 ${x},${bottomY} 
        L${x},${y} 
        Z`;
};

const getCompleteConvexCylinderPath = (x, y, width, height) => {
    const topRadius = width / 2;            // Radius for the top arc
    const bottomRadius = width / 2;         // Radius for the bottom arc
    const bottomY = y + height;              // Bottom Y position

    return `
        M${x},${y} 
        A${topRadius},${topRadius} 0 0,1 ${x + width},${y}  // Top arc
        L${x + width},${bottomY - bottomRadius} // Right side to bottom arc
        A${bottomRadius},${bottomRadius} 0 0,0 ${x + width - bottomRadius * 2},${bottomY} // Bottom arc
        L${x + bottomRadius * 2},${bottomY} // Left side to bottom arc
        A${bottomRadius},${bottomRadius} 0 0,0 ${x},${bottomY - bottomRadius} // Bottom arc
        L${x},${y} 
        Z`;
};


// CylinderBar component with gradient fill
const CylinderBar = (props) => {
    const { fill, x, y, width, height, id, lowerbar, fatbar } = props;
    const topColor = adjustBrightness(fill, 30);   // Lighter shade for the top
    const bottomColor = adjustBrightness(fill, -40); // Darker shade for the bottom

    if (lowerbar) {
        return (
            <>
            <defs>
                <linearGradient id={id} x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style={{ stopColor: topColor, stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: bottomColor, stopOpacity: 1 }} />
                </linearGradient>
            </defs>            
                <path d={getCylinderPathLower(x, y, width, height, fatbar)} stroke="none" fill={`url(#${id})`} />
                {/* Optional highlight to enhance the shiny effect */}
                {/* <path d={getShinyCylinderPath(x, y, width, height)} stroke="none" fillOpacity="0.1" fill={topColor} /> */}
            </>
        );    
    } else {
        return (
            <>
            <defs>
                <linearGradient id={id} x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style={{ stopColor: topColor, stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: bottomColor, stopOpacity: 1 }} />
                </linearGradient>
            </defs>            
                <path d={getCylinderPath(x, y, width, height, fatbar)} stroke="none" fill={`url(#${id})`} />
                {/* Optional highlight to enhance the shiny effect */}
                {/* <path d={getShinyCylinderPath(x, y, width, height)} stroke="none" fillOpacity="0.1" fill={topColor} /> */}
            </>
        );    
    }
};

export default CylinderBar;