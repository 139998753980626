import React, {useState, useEffect} from 'react'
import UserEvaluationSummary from './UserEvaluationSummary'
import { Button, Card } from 'react-bootstrap'
import { styles } from '../layout/styles'
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Spinner from '../layout/Spinner';

const UserEvaluationList = (props) => {
    const { userevaluationsessions, loadingMenu} = props;
    const location  = useLocation();
    const processleveltypeid = location.state.processleveltypeid;
    const uesid = location.state.uesid;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    if (!userevaluationsessions) {
        return (
            <div>Loading evaluation sessions...</div>
        )
    } else {
        if (loading) {
            return (
                <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                    <Spinner/>
                </div>
            )
        } else {
            return (
                <div style={styles.MainInnerDiv}>
                    <div style={styles.DashboardContainer}>
                        <UserEvaluationSummary userevaluationsessions={userevaluationsessions} processleveltypeid={processleveltypeid} uesid={uesid}/>
                    </div>
                </div>
            )        
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const userevaluationsessions = state.entity.userevaluationsessions;
    return {
        auth: state.auth.user,
        userevaluationsessions: userevaluationsessions,
        loadingMenu: state.auth.loadingMenu,
    }
}

export default connect(mapStateToProps, null)(UserEvaluationList)

