import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { assignLookupSelection } from '../../store/actions/entityActions';
import { getUserLookup } from '../../store/actions/authActions';
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';
import {UserLookupPolicy} from '../../config/Constant';

const UserLookupList = (props) => {
    const { auth, companyData, profile, userLookup, loadingMenu, fromLookupParam, lookupParamType, lookupParamValue } = props;    
    const [loading, setLoading] = useState(false);
    const [nodeName, setnodeName] = useState('');
    const [sliderValue, setSliderValue] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');

    const userList = userLookup?.Users;

    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }
    let lookupType = location?.state?.lookupParamType;
    if ((lookupType === null) || (lookupType === undefined)) {
        lookupType = lookupParamType;
    }
    let lookupValue = location?.state?.lookupParamValue;
    if ((lookupValue === null) || (lookupValue === undefined)) {
        lookupValue = lookupParamValue;
    }

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    async function searchPillars() {
        setLoading(true);
        let oParams = {
            SkillName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        if (lookupType === UserLookupPolicy) {
            oParams = {
                SkillName:nodeName,
                PolicyIDs:lookupValue,
                RecordSize:'10',
                PageNumber:'1',
            };            
        }
        await props.getUserLookup(oParams);
        setLoading(false);
    }
    
    async function previousPage() {
        setLoading(true);
        const previousPageNumber = userLookup?.Users?.PagingMetaData?.PageNumber - 1;
        let oParams = {
            FullName:nodeName,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        if (lookupType === UserLookupPolicy) {
            oParams = {
                FullName:nodeName,
                PolicyID:lookupValue,
                RecordSize:'10',
                PageNumber:'1',
            };            
        }
        console.log(oParams);
        await props.getUserLookup(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = userLookup?.Users?.PagingMetaData?.PageNumber + 1;
        let oParams = {
            SkillName:nodeName,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        if (lookupType === UserLookupPolicy) {
            oParams = {
                SkillName:nodeName,
                PolicyID:lookupValue,
                RecordSize:'10',
                PageNumber:'1',
            };            
        }
        console.log(oParams);
        await props.getUserLookup(oParams);
        setLoading(false);
    }
    
    const addInitialSliderArray = (competency) => {
        return userList?.map((evalRec) => ({
                            UserID:evalRec.UserID, 
                            FullName:evalRec.FullName,
                            VendorUserID:evalRec.VendorUserID,
                            Email:evalRec.Email,
                            takeAction:false,
                            showDetails:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(userList);
        setSliderValue(initialScoreValues);
    }, [userList])
    
    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    

    function checkMainPillar(tab, id, id1) {
        if (!fromLookup) {
            return (
                <></>
            );
        } else {
            return (
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" 
                        checked={getDetailSwitchValue(id)} id={`switch${'detail' + id}`} 
                        style={(getDetailSwitchValue(id)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                        onChange={(e) => {handleSwitchDetailChange(id)}}>
                    </input>
                    <label class="form-check-label" for={`switch${'detail' + id}`}>Select</label>
                </div>
            );
        } 
    }

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;        
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (row.UserID === rowIndex)) {
                bReturn = row.showDetails;
            }
        })
        return bReturn;
    }
 
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);//[...sliderValue];
        updatedSliderValues.map((row, index) => {
            if (row.UserID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
        getSelectedValue();
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }

    async function getSelectedValue() {
        let currentSelectedValue = '';
        sliderValue.map((row, index) => {
            if (row.showDetails) {
                if (currentSelectedValue !== '') {
                    currentSelectedValue += '&';
                }
                currentSelectedValue += row.UserID + '=' + row.FullName + '|' + row.VendorUserID + '|' + row.Email;
            } 
        });
        setSelectedValue(currentSelectedValue);
        await props.assignLookupSelection(5, currentSelectedValue);
        return currentSelectedValue;
    }

    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Users</h3></td>
                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field'>
                        <input id="searchUserLookupNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Users'/>
                        <a onClick={() => searchPillars()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );
    }

    return (
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          

                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {((!userList) || (userList.length === 0)) ? (             
                                <div style={styles.tableText}>
                                    No Users.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextMain}>Name</th>
                                                <th style={styles.tableText}>Employee ID</th>
                                                <th style={styles.tableText}>Email</th>
                                                <th style={styles.tableText}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userList && userList.map((evalRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{evalRec?.UserID}</td>
                                                        <td style={styles.tableTextSubHeader}>
                                                            {evalRec?.FullName}
                                                        </td>
                                                        <td style={styles.tableText}>{evalRec?.VendorUserID}</td>
                                                        <td style={styles.tableText}>{evalRec?.Email}</td>
                                                        <td style={styles.tableText}>{checkMainPillar(1, evalRec?.UserID, -1)}</td>
                                                    </tr>
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{userLookup?.RecordFound.toLocaleString()}   Users&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(userLookup?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(userLookup?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {userLookup?.PagingMetaData?.PageNumber}  of  {userLookup?.PagingMetaData?.TotalPage.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                    </Card>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      loadingMenu: state.auth.loadingMenu,
      userLookup: state.auth.userLookup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserLookup: (oParams) => dispatch(getUserLookup(oParams)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLookupList)