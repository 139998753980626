import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import { useNavigate } from "react-router-dom"
import { getUserReportData, getUserReportPillarData } from '../../store/actions/authActions';
import Spinner from '../layout/Spinner';

const ReportList = (props) => {
    const { auth, companyData, profile, reports, loadingMenu } = props;    
    const [loading, setLoading] = useState(false);
    const [nodeName, setnodeName] = useState('');

    const report = reports;
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    async function handleLaunchReport(repRec) {
        //e.preventDefault();
        // console.log(repRec);
        try {
            setLoading(true);
            if ((repRec?.ShowEvaluationSelector === true) && (repRec?.ParameterSummary !== null)) {
                await props.getUserReportPillarData(auth?.UserID, parseInt(repRec?.ParameterSummary));
            } else {
                await props.getUserReportData(auth?.UserID);
            }
            setLoading(false);
            navigate('/launchreport/' + repRec?.ReportID);    
        } catch (err){
            console.log('Error is report launch - ' + repRec?.ReportID);
            setLoading(false);
        }
    }

    return (
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                        <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                                    <Card.Title>
                                        <div>
                                            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                                                <tbody>
                                                <tr>
                                                    <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Reports</h3></td>
                                                    <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                                                        <div className='input-field'>
                                                            <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding} onChange={setSearchNodeName} placeholder='Search Report'/>
                                                        </div>                                            
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>                            
                                        </div>
                                    </Card.Title>
                                </Card.Header>                   
                                {(loading) ? (            
                    <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                        <Spinner/>
                    </div>) : (
            

                                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                    {((!report) || (report.length === 0)) ? (                                        
                                        <div style={styles.tableText}>
                                            No Reports.
                                        </div>)
                                        :
                                        (
                                            <Table responsive="sm" striped bordered hover variant="light">
                                                <thead>
                                                    <tr>
                                                        <th style={styles.tableText}>#</th>
                                                        <th style={styles.tableTextLeft}>Name</th>
                                                        <th style={styles.tableTextLeft}>Description</th>
                                                        <th style={styles.tableText}>Launch</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {report && report.map((repRec, index) => {
                                                        return (
                                                            <>
                                                            <tr>
                                                                <td style={styles.tableText}>{index + 1}</td>
                                                                <td style={styles.tableTextLeft}>{repRec?.ReportName}</td>
                                                                <td style={styles.tableTextLeft}>{repRec?.ReportDescription}</td>

                                                                <td style={styles.tableText}>      
                                                                    {((loading ) ?    
                                                                        <div>                                                    
                                                                            <span class="material-symbols-outlined" style={{color:companyData?.AccentColor, fontSize:'25px', cursor: 'pointer'}}>rocket_launch</span>
                                                                            <span style={styles.LinkBoldGrey}>Launching...</span>
                                                                        </div>
                                                                        :
                                                                        <a onClick={() => handleLaunchReport(repRec)}>
                                                                            <span class="material-symbols-outlined" style={{color:companyData?.Grey, fontSize:'25px', cursor: 'pointer'}}>rocket</span>
                                                                        </a>)
                                                                    }
                                                                </td>

                                                            </tr>
                                                            </>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </Table>                                                            
                                        )
                                    }
                                </Card.Body>
                                )}
                                <Card.Footer className="text-left">
                                </Card.Footer>
                        </Card>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      reports: state.auth.reports, 
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserReportData: (id) => dispatch(getUserReportData(id)),
        getUserReportPillarData: (id, pillarTypeID) => dispatch(getUserReportPillarData(id, pillarTypeID)),
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(ReportList)