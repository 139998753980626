import React, { useEffect, useState } from 'react'
import { Button, Card, ListGroup, Badge, Table, Image, ButtonGroup, ToggleButton, Form, Modal, CardBody } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { styles } from '../layout/styles'
import moment from 'moment';
import { getUserAttributesLookups, assignLookupSelection, getJobPositions,
    insertJobPositionSkill, deleteJobPositionSkill, getJobPositionSkills,
    insertJobPositionEvaluationQuestionGroup, deleteJobPositionEvaluationQuestionGroup, getJobPositionEvaluationQuestionGroups,
    insertJobPositionRequirement, deleteJobPositionRequirement, getJobPositionRequirements, 
    } from '../../store/actions/entityActions';
import { connect, useSelector } from 'react-redux'
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';

const JobPositions = (props) => {
    const { auth, companyData, jobpositions, userattributelookups, fromLookupParam, loadingMenu } = props;
    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }

    const jobPositionList = jobpositions?.jobpositions?.JobPositions;
    const { JobPositionRequirement, JobPositionSkill, JobPositionEvaluationQuestionGroup, JobPositionRequirementSuccession, JobPositionSkillSuccession } = jobpositions?.jobpositions || {};
    const licensesData = userattributelookups?.Licenses;
    const credentialData = userattributelookups?.Credentials;
    const credentialBodyTypeData = userattributelookups?.CredentialBodyTypes;
    const educationData = userattributelookups?.Educations;
    const experienceData = userattributelookups?.Experiences;
    const jpRequirementData = userattributelookups?.JobPositionRequirements;
    const lslData = userattributelookups?.LeadershipAssessmentLevels;
    const schoolData = userattributelookups?.Schools;
    const skillLevelData = userattributelookups?.SkillLevels;
    const skillData = userattributelookups?.Skills;
    const stateData = userattributelookups?.StateCodes;
    const certificationData = userattributelookups?.Certifications;
    const ratingData = userattributelookups?.Ratings;
    const evalGroupData = userattributelookups?.EvaluationQuestionGroups;

    const navigate = useNavigate();
    const [oneActionClicked, setoneActionClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentUserID, setcurrentUserID] = useState(-1);
    const [detailClicked, setdetailClicked] = useState(true);
    const [showDeleteAlertModal, setshowDeleteAlertModal ] = useState(false);
    const [deleteSuggestion, setdeleteSuggestion] = useState([]);
    const [nodeName, setnodeName] = useState('');
    const [requirementTypeClicked, setrequirementTypeClicked] = useState(-1);
    const [selectedValue, setSelectedValue] = useState('');

    async function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }
    
    async function searchJobPosition() {
        setLoading(true);
        const jpParams = {
            JobPositionDescription:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        console.log(jpParams);
        await props.getJobPositions(jpParams);
        setLoading(false);
    }
    
    async function previousPage() {
        setLoading(true);
        const previousPageNumber = jobpositions?.jobpositions?.PagingMetaData?.PageNumber - 1;
        const jpParams = {
            JobPositionDescription:nodeName,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        console.log(jpParams);
        await props.getJobPositions(jpParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = jobpositions?.jobpositions?.PagingMetaData?.PageNumber + 1;
        const jpParams = {
            JobPositionDescription:nodeName,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        console.log(jpParams);
        await props.getJobPositions(jpParams);
        setLoading(false);
    }
    
    function displayMyTeamHeader() {
        return (
            <Table responsive="lg" >
                <thead>
                <tr>
                    <th style={{ width:'70%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> <h3 style={styles.SubHeader}>Job Positions</h3>
                    </th>
                    <th style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <div className='input-field'>
                            <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding90} onChange={setSearchNodeName} placeholder='Search Position'/>
                            <a onClick={() => searchJobPosition()}>
                                <span style={styles.ActionInsideIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                            </a>
                        </div>                                            
                    </th>
                </tr>
                </thead>
                <tbody>
                </tbody>
            </Table>
        );
    }

    function handleDeleteAlertModalClose() { 
        setshowDeleteAlertModal(false);
    }
    
    function handleDeleteAlertModalShow(suggestion) { 
        setshowDeleteAlertModal(true);
        setdeleteSuggestion(suggestion);
    }

    async function handleDeleteAlertModalYes() { 
        setshowDeleteAlertModal(false);
    }

    function handleDeleteAlertModalNo() { 
        setshowDeleteAlertModal(false);
        console.log('Do not delete.');
    }    

    let currentRecIndex = 0;

    const [sliderValue, setSliderValue] = useState([]);

    const addInitialSliderArray = (jpList) => {
        let initsuccessorShowDetailsValue = '';
        if (JobPositionRequirementSuccession) {
            var uniqueSuccessors = JobPositionRequirementSuccession?.filter((v,i,a)=>a.findIndex(t=>(t.UserID === v.UserID))===i)
            if (uniqueSuccessors && uniqueSuccessors.map((successor, supIndex) => {
                if (initsuccessorShowDetailsValue !== '') {
                    initsuccessorShowDetailsValue += '&';    
                }
                initsuccessorShowDetailsValue += successor.UserID + '=false';
            }));
        } 
//        console.log(initsuccessorShowDetailsValue);   


        return jobPositionList?.map((jpDetail) => ({
                JobPositionID:jpDetail.JobPositionID, 
                JobPositionDescription: jpDetail.JobPositionDescription,
                JPRequirementTypeID:(jpRequirementData?.length > 0 ) ? jpRequirementData[0].JobPositionRequirementTypeID : -1,
                AverageSalary:jpDetail.AverageSalary,
                JPRequirementRatingTypeID:-1,
                JPEntityRowTypeID:-1,
                JPEntityID:-1,
                JPEducationTypeID:-1,
                JPEducationMinYears:-1,
                JPEducationMaxYears:-1,
                JPExperienceTypeID:-1,
                JPExperienceMinYears:-1,
                JPExperienceMaxYears:-1,
                JPLicenseTypeID:-1,
                JPCertificationID:-1,
                JPRequirementNotes:'',
                JPSkillID:-1,
                JPSkillLevelID:-1,
                JPEvalsGroupID:-1,
                JPEvalGroupRatingTypeID:-1,
                newRequirementClicked:false,
                newEvalGroupClicked:false,
                newSkillClicked:false,
                takeAction:false,
                showDetails:false,
                SelectedRequirementType:'-1',
                successorShowDetails:initsuccessorShowDetailsValue,
            }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(jobPositionList);
        setSliderValue(initialScoreValues);
    }, [jobpositions])

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (parseInt(row.JobPositionID) === parseInt(rowIndex))) {
                bReturn = row.showDetails;
            }
        });
        return bReturn;
    }
 
    function getSuccessorDetailSwitchValue(rowIndex, successorIndex) {
        let bReturn = false;
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (parseInt(row.JobPositionID) === parseInt(rowIndex))) {       
                const successorDetails = row.successorShowDetails?.split('&');
                successorDetails.map((successorDetail) => {
                    const successorID = parseInt(successorDetail.split('=')[0].trim());
                    if (successorID === successorIndex) {
                        if (successorDetail.split('=')[1].trim() === 'true') {
                            bReturn = true;
                        } else {
                            bReturn = false;
                        }
                    }
                });   
            }
        });
        return bReturn;
    }
 
    function getJPRequirementTypeValue(rowIndex) {
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (row.JobPositionID === rowIndex)) {
                return row.SelectedRequirementType;
            }
        })
    }
 
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.JobPositionID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
        getSelectedValue();
    }

    function handleSuccessorSwitchDetailChange(rowIndex, successorIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        const previousShowValue = getSuccessorDetailSwitchValue(rowIndex, successorIndex);
        updatedSliderValues.map((row, index) => {
            if (row.JobPositionID === rowIndex) {
                let newShowValues = '';
                const successorDetails = row.successorShowDetails?.split('&');
                successorDetails.map((successorDetail) => {
                    if (newShowValues !== '') {
                        newShowValues += '&';
                    }
                    const successorID = successorDetail.split('=')[0];
                    if (parseInt(successorID) === parseInt(successorIndex)) {
                        if (previousShowValue) {
                            newShowValues += successorDetail.split('=')[0] + '=' + 'false';
                        } else {
                            newShowValues += successorDetail.split('=')[0] + '=' + 'true';
                        }
                    } else {
                        newShowValues += successorDetail;
                    }
                });
                row.successorShowDetails = newShowValues;
            }
        });
        setSliderValue(updatedSliderValues);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }

    async function getSelectedValue() {
        let currentSelectedValue = '';
        sliderValue.map((row, index) => {
            if (row.showDetails) {
                if (currentSelectedValue !== '') {
                    currentSelectedValue += '&';
                }
                currentSelectedValue += row.JobPositionID + '=' + row.JobPositionDescription + '|' + row.AverageSalary;
            } 
        });
        setSelectedValue(currentSelectedValue);
        await props.assignLookupSelection(13, currentSelectedValue);
        return currentSelectedValue;
    }

    function getcurrentRecordIndex(){
        currentRecIndex++;        
        return currentRecIndex;
    }

    async function handleAddChildernValues(tab, rowIndex, value) {
        setLoading(true);

        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.JobPositionID === rowIndex) {
                if (tab === 1) {
                    row.JPEntityRowTypeID = -1;
                    row.JPEntityID = -1;
                    row.JPRequirementTypeID = jpRequirementData[value].JobPositionRequirementTypeID;      
                    const reqType = row.JPRequirementTypeID.split('|')[1];
                    row.SelectedRequirementType = reqType;
                    setrequirementTypeClicked(reqType);
                } else if (tab === 2) {
                    row.JPRequirementRatingTypeID = value;                    
                } else if (tab === 3) {
                    row.JPEntityRowTypeID = 11;
                    row.JPEducationTypeID = value;                    
                } else if (tab === 4) {
                    row.JPEducationMinYears = value;                    
                } else if (tab === 5) {
                    row.JPEducationMaxYears = value;                    
                } else if (tab === 6) {
                    row.JPEntityRowTypeID = 12;
                    row.JPExperienceTypeID = value;                    
                } else if (tab === 7) {
                    row.JPExperienceMinYears = value;                    
                } else if (tab === 8) {
                    row.JPExperienceMaxYears = value;                    
                } else if (tab === 9) {
                    row.JPEntityRowTypeID = 13;
                    row.JPLicenseTypeID = value;                    
                } else if (tab === 10) {
                    row.JPEntityRowTypeID = 14;
                    row.JPCertificationID = value;                    
                } else if (tab === 11) {
                    row.JPRequirementNotes = value;                    
                } else if (tab === 12) {
                    row.JPSkillID = value;                    
                } else if (tab === 13) {
                    row.JPSkillLevelID = value;                    
                } else if (tab === 14) {
                    row.JPEvalsGroupID = value;                    
                } else if (tab === 15) {
                    row.JPEvalGroupRatingTypeID = value;      
                } 
            }
        
        });
        setSliderValue(updatedSliderValues);

        setLoading(false);
    }

    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);

        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.JobPositionID === rowIndex) {
                if (tab === 1) {
                    (row.newRequirementClicked) ? row.newRequirementClicked = false : row.newRequirementClicked = true;                    
                } else if (tab === 2) {
                    (row.newSkillClicked) ? row.newSkillClicked = false : row.newSkillClicked = true;                    
                } else if (tab === 3) {
                    (row.newEvalGroupClicked) ? row.newEvalGroupClicked = false : row.newEvalGroupClicked = true;                    
                } 
            }
        });
        setSliderValue(updatedSliderValues);

        setLoading(false);
    }

    async function handleSaveChildernClick(tab, currentJP) {
        setLoading(true);
        const jpRequestList = sliderValue.filter((jobP) => jobP.JobPositionID === currentJP.JobPositionID);

        let sJPIds = '-1';
        jobPositionList && jobPositionList.map((jobPosition, index) => {
            sJPIds += ',' + jobPosition.JobPositionID;
        });

        const refreshParams = {
            'UserID':auth?.UserID,
            'JobPositionIDs':sJPIds
        }

        if (tab === 1) {
            let iEntityID = -1;
            let iMinYears = -1;
            let iMaxYears = -1;
            console.log(jpRequestList[0]?.JPEntityRowTypeID);
            if (jpRequestList[0]?.JPEntityRowTypeID === 11) {
                iEntityID = jpRequestList[0]?.JPEducationTypeID;
                iMinYears = jpRequestList[0]?.JPEducationMinYears;
                iMaxYears = jpRequestList[0]?.JPEducationMaxYears;
            } else if (jpRequestList[0]?.JPEntityRowTypeID === 12) {
                iEntityID = jpRequestList[0]?.JPExperienceTypeID;
                iMinYears = jpRequestList[0]?.JPExperienceMinYears;
                iMaxYears = jpRequestList[0]?.JPExperienceMaxYears;
            } else if (jpRequestList[0]?.JPEntityRowTypeID === 13) {
                iEntityID = jpRequestList[0]?.JPLicenseTypeID;
                console.log(jpRequestList[0]?.JPLicenseTypeID);
            } else if (jpRequestList[0]?.JPEntityRowTypeID === 14) {
                iEntityID = jpRequestList[0]?.JPCertificationID;
            } 

            const oParams = {
                "UserLicenseTypeID": -1,
                "JobPositionID": jpRequestList[0]?.JobPositionID,
                "JobPositionRequirementTypeID":jpRequestList[0]?.JPRequirementTypeID.split('|')[0],
                "EvaluationRatingTypeID":jpRequestList[0]?.JPRequirementRatingTypeID,
                "EntityRowTypeID":jpRequestList[0]?.JPEntityRowTypeID,
                "EntityID":iEntityID,
                "MinimumYears":iMinYears,
                "MaximumYears":iMaxYears,
                "Comments":jpRequestList[0]?.JPRequirementNotes,
                "IsDeleted": false,
                "CreatedByUserID":auth?.UserID
            }
            await props.insertJobPositionRequirement(oParams);
            await props.getJobPositionRequirements(refreshParams);
        } 
        else if (tab === 2) {
            const oParams = {
                "UserSkillID": -1,
                "JobPositionID": jpRequestList[0]?.JobPositionID,
                "SkillID":jpRequestList[0]?.JPSkillID,
                "SkillLevelID":jpRequestList[0]?.JPSkillLevelID,
                "Comments": jpRequestList[0]?.JPRequirementNotes, 
                "CreatedByUserID":auth?.UserID
            }
            console.log(oParams);
            await props.insertJobPositionSkill(oParams);
            await props.getJobPositionSkills(refreshParams);
        } else if (tab === 3) {
            const oParams = {
                "JobPositionID": jpRequestList[0]?.JobPositionID,
                "JobPositionEvaluationQuestionGroupID":jpRequestList[0]?.JPEvalsGroupID,
                "RequiredEvaluationRatingTypeID":jpRequestList[0]?.JPEvalGroupRatingTypeID,
                "Comments": jpRequestList[0]?.JPRequirementNotes, 
                "CreatedByUserID":auth?.UserID
            }
            console.log(oParams);
            await props.insertJobPositionEvaluationQuestionGroup(oParams);
            await props.getJobPositionEvaluationQuestionGroups(refreshParams);
        }
        setLoading(false);
    }

    async function handleDeleteChildernClick(tab, currentJP, id, otherId) {
        setLoading(true);
        let sJPIds = '-1';
        jobPositionList && jobPositionList.map((jobPosition, index) => {
            sJPIds += ',' + jobPosition.JobPositionID;
        });

        const refreshParams = {
            'UserID':auth?.UserID,
            'JobPositionIDs':sJPIds
        }

        if (tab === 1) {
            const oParams = {
                "JobPositionID": currentJP?.JobPositionID,
                "UserID": currentJP?.UserID,
                "JobPositionRequirementID": id,
            }
            console.log(oParams);
            await props.deleteJobPositionRequirement(oParams);
            await props.getJobPositionRequirements(refreshParams);
        } else if (tab === 2) {
            const oParams = {
                "JobPositionID": currentJP?.JobPositionID,
                "UserID": currentJP?.UserID,
                "SkillID": id,
                "SkillLevelID": otherId,
            }
            await props.deleteJobPositionSkill(oParams);
            await props.getJobPositionSkills(refreshParams);
        } else if (tab === 3) {
            const oParams = {
                "JobPositionID": currentJP?.JobPositionID,
                "UserID": currentJP?.UserID,
                "JobPositionEvaluationQuestionGroupID": id,
            }
            console.log(oParams);
            await props.deleteJobPositionEvaluationQuestionGroup(oParams);
            await props.getJobPositionEvaluationQuestionGroups(refreshParams);
        }

        setLoading(false);
    }

    function displayAddButtons(tabValue, currentJP) {
        const jpRequestList = sliderValue.filter((jobP) => jobP.JobPositionID === currentJP.JobPositionID);

        if (tabValue === 1 ) {
            if (jpRequestList[0].newRequirementClicked) {
                return (
                    <a onClick={() => handleAddChildernClick(1, currentJP.JobPositionID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New License'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(1, currentJP.JobPositionID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New License'>note_add</span>
                    </a>
                );
            }
        } else if (tabValue === 2 ) {
            if (jpRequestList[0].newSkillClicked) {
                return (
                    <a onClick={() => handleAddChildernClick(2, currentJP.JobPositionID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Credential'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(2, currentJP.JobPositionID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Credential'>note_add</span>
                    </a>
                );
            }
        } else if (tabValue === 3 ) {        
            if (jpRequestList[0].newEvalGroupClicked) {
                return (
                    <a onClick={() => handleAddChildernClick(3, currentJP.JobPositionID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Job Requirement'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(3, currentJP.JobPositionID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Job Requirement'>note_add</span>
                    </a>
                );
            }
        } 

    }

    function displayNewJPReqEducation(currentJP) {
        //if (getJPRequirementTypeValue(currentJP.JobPositionID)) { 
        if (requirementTypeClicked === '11') {
            return (
            <tr>
                <td style={{textAlign:'left'}}>
                    <div>
                        <label htmlFor="educationtype" style={styles.LabelDataEntryNoPadding}>Education</label>
                        <select id="educationtype" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(3, currentJP.JobPositionID, e.target.value))}>
                            {educationData.map((education, supIndex) => (
                                <option value={education.EducationTypeID}>{education.EducationTypeName}</option>        
                            ))}
                        </select>                                     
                    </div>                    
                </td>
                <td style={{textAlign:'left'}}>
                    <div>
                        <label htmlFor="eduMinYear" style={styles.LabelDataEntryNoPadding}>Min. Years</label>
                        <input id="eduMinYear" type="number" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(4, currentJP.JobPositionID, e.target.value))}/>
                    </div>                    
                </td>
                <td style={{textAlign:'left'}}>
                    <div>
                        <label htmlFor="licenseExpires" style={styles.LabelDataEntryNoPadding}>Max. Years</label>
                        <input id="eduMaxYear" type="number" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(5, currentJP.JobPositionID, e.target.value))}/>
                    </div>                    
                </td>
            </tr>
        )
        } else {
            return (<></>)
        }
    }

    function displayNewJPReqExperience(currentJP) {
        //if (getJPRequirementTypeValue(currentJP.JobPositionID)) { 
        if (requirementTypeClicked === '12') {
            return (
                <tr>
                    <td style={{textAlign:'left'}}>
                        <div>
                            <label htmlFor="experiencetype" style={styles.LabelDataEntryNoPadding}>Experience</label>
                            <select id="experiencetype" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(6, currentJP.JobPositionID, e.target.value))}>
                                {experienceData.map((experience, supIndex) => (
                                    <option value={experience.ExperienceTypeID}>{experience.ExperienceTypeName}</option>        
                                ))}
                            </select>                                     
                        </div>                    
                    </td>
                    <td style={{textAlign:'left'}}>
                        <div>
                            <label htmlFor="expMinYear" style={styles.LabelDataEntryNoPadding}>Min. Years</label>
                            <input id="expMinYear" type="number" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(7, currentJP.JobPositionID, e.target.value))}/>
                        </div>                    
                    </td>
                    <td style={{textAlign:'left'}}>
                        <div>
                            <label htmlFor="expMaxYear" style={styles.LabelDataEntryNoPadding}>Max. Years</label>
                            <input id="expMaxYear" type="number" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(8, currentJP.JobPositionID, e.target.value))}/>
                        </div>                    
                    </td>
                </tr>
        )
        } else {
            return (<></>)
        }
    }

    function displayNewJPReqLicense(currentJP) {
        //if (getJPRequirementTypeValue(currentJP.JobPositionID)) { 
        if (requirementTypeClicked === '13') {
            return (
                <tr>    
                    <td colSpan='3' style={{textAlign:'left'}}>
                        <div>
                            <label htmlFor="licenseExpires" style={styles.LabelDataEntryNoPadding}>License</label>
                            <select id="licensetype" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(9, currentJP.JobPositionID, e.target.value))}>
                                {licensesData.map((license, supIndex) => (
                                    <option value={license.LicenseTypeID}>{license.LicenseTypeName}</option>        
                                ))}
                            </select>                                     
                        </div>                    
                    </td>
                </tr>    
            )
        } else {
            return (<></>)
        }
    }

    function displayNewJPReqCertification(currentJP) {
        //if (getJPRequirementTypeValue(currentJP.JobPositionID)) { 
        if (requirementTypeClicked === '14') {
            return (
                <tr>    
                    <td colSpan='3' style={{textAlign:'left'}}>
                        <div>
                            <label htmlFor="certification" style={styles.LabelDataEntryNoPadding}>Certification</label>
                            <select id="certification" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(10, currentJP.JobPositionID, e.target.value))}>
                                {certificationData.map((certification, supIndex) => (
                                    <option value={certification.CertificationID}>{certification.CertificationName}</option>        
                                ))}
                            </select>                                     
                        </div>                    
                    </td>
                </tr>
            )
        } else {
            return (<></>)
        }
    }

    function displayNewJPRequirement(currentJP) {
        const jpRequestList = sliderValue.filter((jobP) => jobP.JobPositionID === currentJP.JobPositionID);
        if (jpRequestList[0].newRequirementClicked) {
            return (
                <table class="table caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td colSpan='2' style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Type</label>
                                    <select id="requirementtype" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(1, currentJP.JobPositionID, e.target.selectedIndex))}>
                                        {jpRequirementData.map((requirement, supIndex) => (
                                            <option value={requirement.JobPositionRequirementID}>{requirement.JobPositionRequirementTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="reqRatings" style={styles.LabelDataEntryNoPadding}>Ratings</label>
                                    <select id="reqRatings" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(2, currentJP.JobPositionID, e.target.value))}>
                                        {ratingData.map((rating, supIndex) => (
                                            <option value={rating.EvaluationRatingTypeID}>{rating.EvaluationRatingTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                        </tr>
                        {displayNewJPReqEducation(currentJP)}
                        {displayNewJPReqExperience(currentJP)}
                        {displayNewJPReqLicense(currentJP)}
                        {displayNewJPReqCertification(currentJP)}
                        <tr>
                            <td colSpan='2' style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="reqNotes" style={styles.LabelDataEntryNoPadding}>Notes</label>
                                    <input id="reqNotes" type="text" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(11, currentJP.JobPositionID, e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'right'}}>
                                <div>
                                    {/* <a onClick={() => handleSaveChildernClick(1, currentJP)}>
                                        <span style={styles.ActionIcon} class="material-symbols-outlined" title='Save Requirement'>save</span>
                                    </a> */}
                                    <Button onClick={() => handleSaveChildernClick(1, currentJP)} disabled={loading} style={styles.CDPillButtonSMGrey}>Save</Button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>                                   
            );
        }
    }

    function displayJPRequirementName(requirement) {
        if (requirement?.EntityRowTypeID === 11) {
            return  (requirement?.EducationTypeName) + 
            ((requirement?.MinimumYears === -1) ? '' : ', Min. Years - ' + requirement?.MinimumYears + ', ') + 
            ((requirement?.MaximumYears === -1) ? '' : ' Max. Years - ' + requirement?.MaximumYears + ', ');
        } else if (requirement?.EntityRowTypeID === 12) {
            return   (requirement?.ExperienceTypeName) + 
            ((requirement?.MinimumYears === -1) ? '' : ', Min. Years - ' + requirement?.MinimumYears + ', ') + 
            ((requirement?.MaximumYears === -1) ? '' : ' Max. Years - ' + requirement?.MaximumYears + ', ') ;
        } else if (requirement?.EntityRowTypeID === 13) {
            return   (requirement?.LicenseTypeName);
        } else if (requirement?.EntityRowTypeID === 14) {
            return   (requirement?.CertificationName);
        } else {
            return '';
        }
    }

    function displayJPRequirements(currentJP) {
        const jpRequirements = JobPositionRequirement?.filter((jobP) => (jobP.JobPositionID === currentJP.JobPositionID));

        return (
            (jpRequirements?.length === 0) ? <>{displayNewJPRequirement(currentJP)}</> : 
            <>
            {displayNewJPRequirement(currentJP)}
            <table class="table table-sm caption-top text-left">  
                <thead>
                    <tr>
                        <th style={styles.tableText}>#</th>
                        <th style={styles.tableTextLeft}>Type</th>
                        <th style={styles.tableTextLeft}>Name</th>
                        <th style={styles.tableTextLeft}>Rating</th>
                        <th style={styles.tableTextLeft}>Notes</th>
                        <th style={styles.tableText}>Delete</th>
                    </tr>
                </thead>                        
                <tbody> 
                {jpRequirements.map((requirement, supIndex) => (
                    <tr> 
                        <td style={styles.tableText}>{supIndex + 1}</td> 
                        <td style={styles.tableTextLeft}>{requirement?.JobPositionRequirementTypeName}</td> 
                        <td style={styles.tableTextLeft}>{displayJPRequirementName(requirement)}</td> 
                        <td style={styles.tableTextLeft}>{requirement?.EvaluationRatingTypeName}</td> 
                        <td style={styles.tableTextLeft}>{requirement?.Comments}</td> 
                        <td style={styles.tableText}>
                            <div>
                                <a onClick={() => handleDeleteChildernClick(1, currentJP, requirement?.JobPositionRequirementID, null)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Requirement'>delete</span>
                                </a>
                            </div>
                        </td>
                    </tr> 
                ))
                }                        
                </tbody>
            </table>                                   
            </>
        );
    }

    function displayNewSkillRequirement(currentJP) {
        const jpRequestList = sliderValue.filter((jobP) => jobP.JobPositionID === currentJP.JobPositionID);
        if (jpRequestList[0].newSkillClicked) {
            return (
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td colSpan='2' style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Name</label>
                                    <select id="skilltype" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(12, currentJP.JobPositionID, e.target.value))}>
                                        {skillData.map((skill, supIndex) => (
                                            <option value={skill.SkillID}>{skill.SkillName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                        </tr>                
                        <tr>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="licenseNumber" style={styles.LabelDataEntryNoPadding}>Level</label>
                                    <select id="skillLeveltype" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(13, currentJP.JobPositionID, e.target.value))}>
                                        {skillLevelData.map((skillLevel, supIndex) => (
                                            <option value={skillLevel.SkillLevelID}>{skillLevel.SkillLevelName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'center'}}>
                                <div>
                                    {/* <a onClick={() => handleSaveChildernClick(2, currentJP)}>
                                        <span style={styles.ActionIcon} class="material-symbols-outlined" title='Save Skill'>save</span>
                                    </a> */}
                                    <Button onClick={() => handleSaveChildernClick(2, currentJP)} disabled={loading} style={styles.CDPillButtonSMGrey}>Save</Button>
                                </div>
                            </td>
                        </tr>                
                    </tbody>
                </table>                                   
            );
        }
    }

    function displaySkillRequirements(currentJP) {
        const jpSkills = JobPositionSkill?.filter((jobP) => (jobP.JobPositionID === currentJP.JobPositionID));

        return (
            (jpSkills?.length === 0) ? <>{displayNewSkillRequirement(currentJP)}</> : 
            <>
            {displayNewSkillRequirement(currentJP)}
            <table class="table table-sm caption-top text-left">  
                <thead>
                    <tr>
                        <th style={styles.tableText}>#</th>
                        <th style={styles.tableTextLeft}>Name</th>
                        <th style={styles.tableText}>Level</th>
                        <th style={styles.tableText}>Action</th>
                    </tr>
                </thead>                        
                <tbody> 
                {jpSkills.map((skill, supIndex) => (
                    <tr> 
                        <td style={styles.tableText}>{supIndex + 1}</td> 
                        <td style={styles.tableTextLeft}>{skill?.SkillName}</td> 
                        <td style={styles.tableText}>{skill?.SkillLevelName}</td> 
                        <td style={styles.tableText}>
                            <div>
                                <a onClick={() => handleDeleteChildernClick(2, currentJP, skill?.SkillID, skill?.SkillLevelID)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Skill'>delete</span>
                                </a>
                            </div>
                        </td>
                    </tr> 
                ))
                }                        
                </tbody>
            </table>                                   
            </>
        );
    }

    function displayNewEvalGroupRequirement(currentJP) {
        const jpRequestList = sliderValue.filter((jobP) => jobP.JobPositionID === currentJP.JobPositionID);
        if (jpRequestList[0].newEvalGroupClicked) {
            return (
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Name</label>
                                    <select id="evalGroup" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(14, currentJP.JobPositionID, e.target.value))}>
                                        {evalGroupData.map((evals, supIndex) => (
                                            <option value={evals.EvaluationQuestionGroupID}>{evals.EvaluationQuestionGroupName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="evalGroupRating" style={styles.LabelDataEntryNoPadding}>Ratings</label>
                                    <select id="evalGroupRatings" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(15, currentJP.JobPositionID, e.target.value))}>
                                        {ratingData.map((rating, supIndex) => (
                                            <option value={rating.EvaluationRatingTypeID}>{rating.EvaluationRatingTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'right'}}>
                                <div>
                                    {/* <a onClick={() => handleSaveChildernClick(3, currentJP)}>
                                        <span style={styles.ActionIcon} class="material-symbols-outlined" title='Save Performance'>save</span>
                                    </a> */}
                                    <Button onClick={() => handleSaveChildernClick(3, currentJP)} disabled={loading} style={styles.CDPillButtonSMGrey}>Save</Button>
                                </div>
                            </td>
                        </tr>                
                    </tbody>
                </table>                                   
            );
        }
    }

    function displayEvalGroupRequirements(currentJP) {
        const jpEvalGroups = JobPositionEvaluationQuestionGroup?.filter((jobP) => (jobP.JobPositionID === currentJP.JobPositionID));

        return (
            (jpEvalGroups?.length === 0) ? <>{displayNewEvalGroupRequirement(currentJP)}</> : 
            <>
            {displayNewEvalGroupRequirement(currentJP)}
            <table class="table table-sm caption-top text-left">  
                <thead>
                    <tr>
                        <th style={styles.tableText}>#</th>
                        <th style={styles.tableTextLeft}>Name</th>
                        <th style={styles.tableText}>Rating</th>
                        <th style={styles.tableText}>Action</th>
                    </tr>
                </thead>                        
                <tbody> 
                {jpEvalGroups.map((jpEvalGroup, supIndex) => (
                    <tr> 
                        <td style={styles.tableText}>{supIndex + 1}</td> 
                        <td style={styles.tableTextLeft}>{jpEvalGroup?.EvaluationQuestionGroupName}</td> 
                        <td style={styles.tableText}>{jpEvalGroup?.RequiredEvaluationRatingTypeName}</td> 
                        <td style={styles.tableText}>
                            <div>
                                <a onClick={() => handleDeleteChildernClick(3, currentJP, jpEvalGroup?.EvaluationQuestionGroupID, null)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Performance'>delete</span>
                                </a>
                            </div>
                        </td>
                    </tr> 
                ))
                }                        
                </tbody>
            </table>                                   
            </>
        );
    }

    function displaySuccessionRequirementFulfillDetails(currentJP, successor, requirement) {
        const userJPRequirements = JobPositionRequirementSuccession?.filter((jobP) => 
            ((jobP.JobPositionRequirementTypeID === requirement.JobPositionRequirementTypeID) && (jobP.UserID === successor.UserID))) 
            // && (jobP.EvaluationRatingTypeID === successor.EvaluationRatingTypeID) && 
            // (jobP.EntityRowTypeID === successor.EntityRowTypeID) && (jobP.EntityID == successor.EntityID)))
        if ((userJPRequirements) && (userJPRequirements.length > 0)) {
            return (
                <span style={styles.ActionInsideIconGrey} class="material-symbols-outlined">thumb_up</span>
            );
        } else {
            return (
                <span style={styles.ActionIcon} class="material-symbols-outlined">thumb_down</span>
            );
        };
    }

    function displaySuccessionRequirementDetails(currentJP, successor) {
        const jpRequirements = JobPositionRequirement?.filter((jobP) => (jobP.JobPositionID === currentJP.JobPositionID));

        return (
            (jpRequirements?.length === 0) ? <></> : 
            <>
            <Card style={{ width:'80%'}}>
                <CardBody>
            <table class="table table-sm caption-top text-center">  
                <thead>
                    <tr>
                        <th style={styles.tableText}>#</th>
                        <th style={styles.tableTextLeft}>Requirement</th>
                        <th style={styles.tableTextLeft}>Checked</th>
                        {/* <th style={styles.tableTextLeft}>Rating</th> */}
                    </tr>
                </thead>                        
                <tbody> 
                {jpRequirements.map((requirement, supIndex) => (
                    <tr> 
                        <td style={styles.tableText}>{supIndex + 1}</td> 
                        <td style={styles.tableTextLeft}>{requirement?.JobPositionRequirementTypeName}</td> 
                        <td style={styles.tableTextLeft}>{displaySuccessionRequirementFulfillDetails(currentJP, successor, requirement)}</td> 
                        {/* <td style={styles.tableTextLeft}>{displayJPRequirementName(requirement)}</td>  */}
                        {/* <td style={styles.tableTextLeft}>{requirement?.EvaluationRatingTypeName}</td>  */}
                    </tr> 
                ))
                }                        
                </tbody>
            </table>      
            </CardBody>                             
            </Card>            
            </>
        );
    }

    function displaySuccessionSkillFulfillDetails(currentJP, successor, requirement) {
        const userJPSkills = JobPositionSkillSuccession?.filter((jobP) => 
            ((jobP.SkillID === requirement.SkillID) && (jobP.SkillLevelID === requirement.SkillLevelID) && (parseInt(jobP.UserID) === parseInt(successor.UserID)))) 
            
        if ((userJPSkills) && (userJPSkills.length > 0)) {
            return (
                <span style={styles.ActionInsideIconGrey} class="material-symbols-outlined">thumb_up</span>
            );
        } else {
            return (
                <span style={styles.ActionIcon} class="material-symbols-outlined">thumb_down</span>
            );
        };
    }

    function displaySuccessionSkillDetails(currentJP, successor) {
        const jpSkills = JobPositionSkill?.filter((jobP) => (jobP.JobPositionID === currentJP.JobPositionID));

        return (
            (jpSkills?.length === 0) ? <></> : 
            <>
            <Card style={{ width:'80%'}}>
                <CardBody>
            <table class="table table-sm caption-top text-center">  
                <thead>
                    <tr>
                        <th style={styles.tableText}>#</th>
                        <th style={styles.tableTextLeft}>Skill</th>
                        <th style={styles.tableTextLeft}>Checked</th>
                    </tr>
                </thead>                        
                <tbody> 
                {jpSkills.map((requirement, supIndex) => (
                    <tr> 
                        <td style={styles.tableText}>{supIndex + 1}</td> 
                        <td style={styles.tableTextLeft}>{requirement?.SkillName}</td> 
                        <td style={styles.tableTextLeft}>{displaySuccessionSkillFulfillDetails(currentJP, successor, requirement)}</td> 
                    </tr> 
                ))
                }                        
                </tbody>
            </table>      
            </CardBody>                             
            </Card>            
            </>
        );
    }

    function displaySuccession(currentJP) {
        if ((!fromLookup) && (JobPositionRequirementSuccession)) {
            var uniqueSuccessors = JobPositionRequirementSuccession?.filter((v,i,a)=>a.findIndex(t=>(t.UserID === v.UserID))===i)
            if (uniqueSuccessors.length !== 0) {
                return (
                    <table class="table table-sm caption-top text-center">  
                        <thead>
                            <tr>
                                <th style={styles.tableText}>#</th>
                                <th style={styles.tableTextLeft}>Name</th>
                                <th style={styles.tableText}>ID</th>
                            </tr>
                        </thead>                        
                        <tbody> 
                        {uniqueSuccessors.map((successor, supIndex) => (
                            <>
                            <tr> 
                                <td style={styles.tableText}>{supIndex + 1}</td> 
                                <td style={styles.tableTextLeft}>
                                    {successor?.UserLastName},&nbsp;&nbsp;{successor?.UserFirstName}
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" role="switch" 
                                            checked={getSuccessorDetailSwitchValue(currentJP.JobPositionID, successor?.UserID)} id={`switch${'succdetail' + successor?.UserID}`} 
                                            style={(getSuccessorDetailSwitchValue(currentJP.JobPositionID, successor?.UserID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                            onChange={(e) => {handleSuccessorSwitchDetailChange(currentJP.JobPositionID, successor?.UserID)}}>
                                        </input>
                                        <label class="form-check-label" htmlFor={`switch${'succdetail' + successor?.UserID}`}>Details</label>
                                    </div>                    
                                </td> 
                                <td style={styles.tableText}>{successor?.VendorUserID}</td> 
                            </tr> 
                            {(getSuccessorDetailSwitchValue(currentJP.JobPositionID, successor.UserID)) ? 
                            <tr>
                                <td colSpan="3" style={{alignItems:'center'}}>
                                    {displaySuccessionRequirementDetails(currentJP, successor)}
                                    {displaySuccessionSkillDetails(currentJP, successor)}
                                </td>
                            </tr>
                            :
                            <></>
                            }
                            </>
                        ))
                        }                        
                        </tbody>
                    </table>                                       
                );
            } else {
                return (<></>);
            }
        } 
    }

    function getDetailSwitchTR(currentJP) {
        const jpRequestList = sliderValue.filter((jobP) => jobP.JobPositionID === currentJP.JobPositionID);

        if ((!fromLookup) && (jpRequestList[0]?.showDetails)) {
            return (
                <>
                <tr id={`tr${'reqdetail' + currentJP.JobPositionID}`}>
                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                        <table class="table table-sm caption-top">  
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Requirements</th>
                                    <th style={styles.tableTextRight}>{displayAddButtons(1, currentJP)}</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                                <tr>
                                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                                        {displayJPRequirements(currentJP)}
                                    </td> 
                                </tr>             
                            </tbody>
                        </table>    
                    </td> 
                </tr>                     
                <tr id={`tr${'skilldetail' + currentJP.JobPositionID}`}>
                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                        <table class="table table-sm caption-top">  
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Skills</th>
                                    <th style={styles.tableTextRight}>{displayAddButtons(2, currentJP)}</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                                <tr>
                                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                                        {displaySkillRequirements(currentJP)}
                                    </td> 
                                </tr>             
                            </tbody>
                        </table>    
                    </td> 
                </tr>                     
                <tr id={`tr${'prfdetail' + currentJP.JobPositionID}`}>
                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                        <table class="table table-sm caption-top">  
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Performance</th>
                                    <th style={styles.tableTextRight}>{displayAddButtons(3, currentJP)}</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                                <tr>
                                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                                        {displayEvalGroupRequirements(currentJP)}
                                    </td> 
                                </tr>             
                            </tbody>
                        </table>    
                    </td> 
                </tr>                     
                <tr id={`tr${'succdetail' + currentJP.JobPositionID}`}>
                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                        <table class="table table-sm caption-top">  
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Succession</th>
                                    {/* <th style={styles.tableTextRight}>{displayAddButtons(3, currentJP)}</th> */}
                                </tr>
                            </thead>                        
                            <tbody> 
                                <tr>
                                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                                        {displaySuccession(currentJP)}
                                    </td> 
                                </tr>             
                            </tbody>
                        </table>    
                    </td> 
                </tr>                                    
                </>
            );
        }
    }

    function displayJobPositionHeader() {
        if (fromLookup) {
            return (
                <tr>
                <th style={styles.tableText}>#</th>
                <th style={styles.tableTextLeft}>Job Position</th>
                <th style={styles.tableTextLeft}>Job Code</th>
                <th style={styles.tableTextLeft}>Job Class</th>
                <th style={styles.tableTextLeft}>Employees</th>
            </tr>
            );
        } else {
            return (
            <tr>
                <th style={styles.tableText}>#</th>
                <th style={styles.tableTextLeft}>Job Position</th>
                <th style={styles.tableTextLeft}>Job Code</th>
                <th style={styles.tableTextLeft}>Job Class</th>
                <th style={styles.tableTextLeft}>Employees</th>
                <th style={styles.tableTextLeft}>At Risk</th>
                <th style={styles.tableTextLeft}>Avg Salary</th>
            </tr>
            );
        }
    }

    function displayJobPositionName(jobPosition) {
        if (fromLookup) {
            return (
                <>
                <tr>
                    <td style={styles.tableText}>{getcurrentRecordIndex()}</td>
                    <td style={styles.tableTextMainColumn}>
                        {jobPosition?.JobPositionDescription}
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" 
                                checked={getDetailSwitchValue(jobPosition?.JobPositionID)} id={`switch${'detail' + jobPosition.JobPositionID}`} 
                                style={(getDetailSwitchValue(jobPosition?.JobPositionID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                onChange={(e) => {handleSwitchDetailChange(jobPosition?.JobPositionID)}}>
                            </input>
                            <label class="form-check-label" for={`switch${'detail' + jobPosition?.JobPositionID}`}>Select</label>
                        </div>
                    </td>
                    <td style={styles.tableTextLeft}>{jobPosition?.JobClassDescription}</td>
                    <td style={styles.tableTextLeft}>{jobPosition?.JobCodeDescription}</td>
                    <td style={styles.tableText}>{jobPosition?.UserCount?.toLocaleString()}</td>
                </tr>
                </>
            );
        } else {
            return (
                <>
                <tr>
                    <td style={styles.tableText}>{getcurrentRecordIndex()}</td>
                    <td style={styles.tableTextMainColumn}>
                        {jobPosition?.JobPositionDescription}
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" 
                                checked={getDetailSwitchValue(jobPosition?.JobPositionID)} id={`switch${'detail' + jobPosition.JobPositionID}`} 
                                style={(getDetailSwitchValue(jobPosition?.JobPositionID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                onChange={(e) => {handleSwitchDetailChange(jobPosition?.JobPositionID)}}>
                            </input>
                            <label class="form-check-label" for={`switch${'detail' + jobPosition?.JobPositionID}`}>Details</label>
                        </div>                    
                    </td>
                    <td style={styles.tableTextLeft}>{jobPosition?.JobClassDescription}</td>
                    <td style={styles.tableTextLeft}>{jobPosition?.JobCodeDescription}</td>
                    <td style={styles.tableText}>{jobPosition?.UserCount?.toLocaleString()}</td>
                    {jobPosition?.AtRiskCount === 0 ? <td style={styles.tableText}>No</td> : <td style={styles.tableTextAccent}>Yes({jobPosition?.AtRiskCount})</td>}                    
                    <td style={styles.tableText}>{jobPosition?.AverageSalary.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                    })}</td>
                </tr>
                </>
            );
        }
    }
    
    return ( 
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
            <Modal style={{background:'transparent', maxHeight:'100%'}} size='xl' aria-labelledby="contained-modal-title-vcenter" centered 
                show={showDeleteAlertModal} onHide={handleDeleteAlertModalClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton={false}>
            <Modal.Title>Do you want to delete this prediction?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>If you select 'Yes', it cannot be reverted. Please confirm.</p>                            
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" style={{backgroundColor:companyData?.AccentColor}} onClick={handleDeleteAlertModalYes}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleDeleteAlertModalNo}>
                No
            </Button>
            </Modal.Footer>
            </Modal> 

            <Table style={{background:'transparent'}} >
                <thead>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {(jobPositionList) ? 
                                (

                                    <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                                            {displayMyTeamHeader()}
                                        </Card.Header>
                                        {(loading) ? (            
                                            <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                                <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                                    <Spinner/>
                                                </div>
                                            </Card.Body>
                                        ) 
                                        : 
                                        (                                          
                                            <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                                    {(jobPositionList?.length === 0) ? (
                                                        <div style={styles.tableText}>
                                                            No job positions.
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <Table responsive="sm" striped bordered hover variant="light">
                                                                <thead>
                                                                    {displayJobPositionHeader()}
                                                                </thead>
                                                                <tbody>
                                                                    {jobPositionList && jobPositionList.map((jobPosition, index) => {
                                                                        return (
                                                                            <>
                                                                            {displayJobPositionName(jobPosition)}
                                                                            {getDetailSwitchTR(jobPosition)}
                                                                            </>
                                                                        )
                                                                    }
                                                                ) 
                                                                }    

                                                                </tbody>
                                                            </Table>        
                                                        </div>
                                                    )}
                                                </Card.Body>
                                        )}

                                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                                            <Table variant="light">
                                                <thead>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                                            <h5 style={styles.tableTextLeft}>{jobpositions?.jobpositions?.RecordFound.toLocaleString()}   Job Positions&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                                        </td>
                                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                                            {(loading) ? <></> : 
                                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                                <h5 style={styles.tableText}>
                                                                {(jobpositions?.jobpositions?.PagingMetaData?.PreviousPage) ? (
                                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                                            <span title='Previous Page'>Previous</span>
                                                                        </a>
                                                                ) 
                                                                : 
                                                                <></> }
                                                                {(jobpositions?.jobpositions?.PagingMetaData?.NextPage) ? (
                                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                                        </a>
                                                                ) 
                                                                : 
                                                                <></> }
                                                                </h5>
                                                                </div>
                                                            }
                                                        </td>
                                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                                            <h5 style={styles.tableTextRight}> Page   {jobpositions?.jobpositions?.PagingMetaData?.PageNumber}  of  {jobpositions?.jobpositions?.PagingMetaData?.TotalPage.toLocaleString()}</h5>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card.Footer>
                                    </Card>
                                )
                                :
                                (<></>)}
                        </td>
                    </tr>
                </tbody>
            </Table>

            </div>
        </div>
    )        

}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state?.auth?.user,
        companyData: state?.auth?.companyData,
        jobpositions: state?.entity?.jobpositions,
        userattributelookups: state?.entity?.userattributelookups,
        loadingMenu: state?.auth?.loadingMenu,
   }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
        getJobPositions: (jpParams) => dispatch(getJobPositions(jpParams)),
        insertJobPositionSkill: (oParams) => dispatch(insertJobPositionSkill(oParams)),
        deleteJobPositionSkill: (oParams) => dispatch(deleteJobPositionSkill(oParams)),
        insertJobPositionEvaluationQuestionGroup: (oParams) => dispatch(insertJobPositionEvaluationQuestionGroup(oParams)),
        deleteJobPositionEvaluationQuestionGroup: (oParams) => dispatch(deleteJobPositionEvaluationQuestionGroup(oParams)),
        insertJobPositionRequirement: (oParams) => dispatch(insertJobPositionRequirement(oParams)),
        deleteJobPositionRequirement: (oParams) => dispatch(deleteJobPositionRequirement(oParams)),
        getJobPositionRequirements: (oParams) => dispatch(getJobPositionRequirements(oParams)),
        getJobPositionSkills: (oParams) => dispatch(getJobPositionSkills(oParams)),
        getJobPositionEvaluationQuestionGroups: (oParams) => dispatch(getJobPositionEvaluationQuestionGroups(oParams)),
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(JobPositions)
