import React from 'react'
import AnalyticsDetails from './AnalyticsDetails';

const MyAnalytics = (props) => {
    const { myTeam } = props;

    return (    
        <AnalyticsDetails/>       
    );
}

export default MyAnalytics
