import React, { useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { styles } from '../layout/styles';
import { Button, Card, CardBody, Modal, Table } from 'react-bootstrap';
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import '../layout/survey.css';
import { updateEvaluationResponses, updateUserEvaluationSession, getUserEvaluationSessions } from '../../store/actions/entityActions'
import moment from 'moment';
import { RetentasSurveys } from '../../config/Constant';

function LaunchEvaluation (props) {
    const { id } = useParams();
    const { auth, companyData, evaluation, evaluationquestions, evaluationratings } = props;

    //const entity = props.entities.find(item => item.id === id);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [publishEvaluation, setpublishEvaluation] = useState(false);

    const [ showAlertModal, setshowAlertModal ] = useState(false);
    
    function handleAlertModalClose() { 
        setshowAlertModal(false);
    }
    
    function handleAlertModalShow() { setshowAlertModal(true);}
    
    async function handleAlertModalYes() { 
        setshowAlertModal(false);
        console.log('Call publish evaluation session.');
        const userEvalSessionParams = [{
            UserID:auth.UserID,
            UserEvaluationSessionID:id,
            CompletedDate:moment().toDate(),
            IsPublished:'true',
            Score:'0',
            Weight:'0',
            DataSyncOut:'',
            DataSyncMessageOut:''
        }];
        await props.updateUserEvaluationSession(userEvalSessionParams);
        await props.getUserEvaluationSessions(auth.UserID);
        setpublishEvaluation(true);
        navigate('/questionnaires', {state: {processleveltypeid:RetentasSurveys}});
        //navigate("/");
    }

    function handleAlertModalNo() { 
        setshowAlertModal(false);
        console.log('Do not publish evaluation session.');
        setpublishEvaluation(false);
        navigate('/questionnaires', {state: {processleveltypeid:RetentasSurveys}});
        //navigate("/");
    }

    function handleCancel() {
        navigate('/questionnaires', {state: {processleveltypeid:RetentasSurveys}});
    }

    function animate (isShowing) {
        const element = document.getElementById("root");
        if (!!element) {
            const classes = element.classList;
            if (!classes.contains("hidden")) {
                classes.add("hidden");
            }
            isShowing ? classes.add("visible") : classes.remove("visible");
        }
    }
    
    function showAnimated () { animate(true); }
    function hideAnimated () { animate(false); }
    
    var doAnimation = true;
    // var rtCss = {
    //   navigation: {
    //     complete: "sv-btn sv-footer__complete-btn my-button",
    //     prev: "sv-btn sv-footer__prev-btn  my-button",
    //     next: "sv-btn sv-footer__next-btn my-button",
    //     start: "sv-btn sv-footer__start-btn  my-button",
    //     preview: "sv-btn sv-footer__preview-btn  my-button",
    //     edit: "sv-btn sv-footer__edit-btn my-button",
    //   },    
    // };    

    // console.log(auth);
    //console.log(evaluation);
    //console.log(evaluationquestions);
    //console.log(evaluationratings);

    function generateEvaluation() {
        try {
            const surveyJson = {
                };        
            const survey = new Model(surveyJson);
            survey.showQuestionNumbers = 'off';
            //survey.css = rtCss;
            survey.applyTheme({
                "cssVariables" : {
                    "--sjs-primary-backcolor": companyData?.AccentColor,
                    "--sjs-primary-backcolor-light": companyData?.AccentColorLighter,                    
                }
            })
            const page = survey.addNewPage(evaluation[0].EvaluationName);
            evaluationquestions && evaluationquestions.map((question, index) => {
                // Add a panel to the page
                let panel = survey.getPanelByName(question.EvaluationQuestionGroupName);
                if (!panel) {
                    panel = page.addNewPanel(question.EvaluationQuestionGroupName);
                    //console.log(question.EvaluationQuestionGroupName);
                    panel.title = question.EvaluationQuestionGroupName;
                    panel.description = 'Please indicate if you agree or disagree with the following statements';
                    panel.state = "collapsed";                        
                }
                // Add a question to the panel
                const surveyquestion = panel.addNewQuestion("rating", question.EvaluationQuestion);
                surveyquestion.title = question.EvaluationQuestion;    
                surveyquestion.value = question.EvaluationQuestionID;
                surveyquestion.type = 'rating'; 
                surveyquestion.rateType = 'smileys';
                surveyquestion.scaleColorMode = 'colored';
                surveyquestion.rateColorMode = 'scale';
                surveyquestion.rateCount = 5;
                surveyquestion.rateMax = 5;
                surveyquestion.displayMode = 'buttons';          
            })
        
            return survey;
        } catch(error) {
            console.log(error);
        };
    }

    function generateQuestionsByGroup(groupId) {
        let matrixRows = [];
        evaluationquestions && evaluationquestions.map((question, index) => {
            if (question.EvaluationQuestionGroupID === groupId) {
                matrixRows.push({value: question.EvaluationQuestionID , text: question.EvaluationQuestion });
            }
        });
        //console.log(matrixRows);
        return matrixRows;
    }
    
    function generateRatingsByEvaluation(evalId) {
        let matrixColumns = [];
        evaluationratings && evaluationratings.map((ratings, ratingindex) => {
            if (ratings.EvaluationID === evalId) {
                matrixColumns.push({value: ratings.EvaluationRatingTypeID , text: ratings.EvaluationRatingTypeName });
            }
        });
        //console.log(matrixColumns);
        return matrixColumns;
    }
    

    function generateEvaluationMatrix() {
        try {
            const surveyJson = {};        
            const survey = new Model(surveyJson);
            survey.commpleteText = 'Submit'
            survey.showQuestionNumbers = 'off';
            survey.applyTheme({
                "cssVariables" : {
                    "--sjs-primary-backcolor": "rgba(231, 29, 55, 1)",
                    "--sjs-primary-backcolor-light": "rgba(231, 29, 55, 0.1)",                    
                }
            })
            //survey.css = rtCss;
            //const page = survey.addNewPage(evaluation[0].EvaluationName);
            evaluationquestions && evaluationquestions.map((question, index) => {
                // Add a page
                let page = survey.getPageByName(question.EvaluationQuestionGroupName);
                if (!page) {
                    page = survey.addNewPage(question.EvaluationQuestionGroupName);
                }             

                let surveyquestion = page.getQuestionByName(question.EvaluationQuestionGroupName);
                if (!surveyquestion) {
                    // Add a question to the page
                    surveyquestion = page.addNewQuestion("matrix", question.EvaluationQuestionGroupName);
                    surveyquestion.title = question.EvaluationQuestionGroupName;    
                    surveyquestion.type = 'matrix'; 
                    surveyquestion.description = 'Please indicate if you agree or disagree with the following statements';
                    surveyquestion.columns = generateRatingsByEvaluation(question.EvaluationID);
                    surveyquestion.rows = generateQuestionsByGroup(question.EvaluationQuestionGroupID);
                }

            })
        
            survey.onAfterRenderSurvey.add(showAnimated);
            survey.onCurrentPageChanging.add(function (_, options) {
                if (!doAnimation) return;
                options.allow = false;
                setTimeout(function () {
                    doAnimation = false;
                    survey.currentPage = options.newCurrentPage;
                    doAnimation = true;
                }, 1000);
                hideAnimated();
            });
            
            survey.onCurrentPageChanged.add(showAnimated);
            
            survey.onCompleting.add(function (_, options) {
                if (!doAnimation) return;
                options.allow = false;
                setTimeout(function () {
                    doAnimation = false;
                    survey.doComplete();
                    doAnimation = true;
                }, 1000);
                hideAnimated();
            });
            
            survey?.onComplete.add(showAnimated);
        
            return survey;
        } catch(error) {
            console.log(error);
        };
    }

    async function saveSurveyResults(surveyResponses) {
        let dbResponse = [];
        Object.entries(surveyResponses).map(([group, groupResponses]) => {
            console.log(group);
            console.log(groupResponses);

            Object.entries(groupResponses).map(([questionid, ratingid]) => {
                dbResponse.push({UserID: auth.UserID, UserEvaluationSessionID: id, EvaluationID:evaluation[0].EvaluationID, EvaluationQuestionID:questionid, EvaluationRatingTypeID:ratingid, DataSyncOut:'', DataSyncMessageOut: '' });                 
            });
    
        });
        console.log(dbResponse);
        // save DB
        await props.updateEvaluationResponses(dbResponse);
    }

    function handleSubmit(e) {
        e.preventDefault();
    }

    const survey = generateEvaluation(); // With smiley faces.
    //const survey = generateEvaluationMatrix(); // animation
    const surveyComplete = useCallback((sender) => {
        handleAlertModalShow();
        saveSurveyResults(sender.data);
    });
    survey?.onComplete.add(surveyComplete);

    return (
        <div style={styles.MainInnerDiv}>
            <Modal style={{background:'transparent'}} aria-labelledby="contained-modal-title-vcenter" centered 
                show={showAlertModal} onHide={handleAlertModalClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton={false}>
            <Modal.Title>Processing your request!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Do you want to submit the Questionnaire?</p>
                <p>If you select 'Yes' - your responses are saved and the questionnaire will marked as complete. You cannot change your responses once marked complete.</p>                            
                <p>If you select 'No' - your responses are saved and the questionnaire will NOT marked as complete. You can come again to change your responses.</p>                            
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" style={{backgroundColor:companyData?.AccentColor}} onClick={handleAlertModalYes}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleAlertModalNo}>
                No
            </Button>
            </Modal.Footer>
            </Modal>        

            <div style={styles.DashboardContainer}>
                <form onSubmit={handleSubmit}>
                    <Card style={{ alignContent:'center', alignItem:'center', justifyContent: "center", backgroundColor:companyData?.PagesBGColor}}>
                        <Card.Header className='text-center'> 
                            <Card.Title>
                                <div>
                                    <Table responsive="sm" style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <tbody>
                                        <tr>
                                            <td style={{textAlign:'left', width:'85%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Employee Engagement</h3></td>
                                            {/* <td style={{textAlign:'center', width:'15%'}}><Button disabled={loading} type='submit' style={styles.CDFullButton}>{loading ? 'Submiting...' : 'Submit'}</Button></td> */}
                                            <td style={{textAlign:'center', width:'15%', backgroundColor:companyData?.PagesBGColor}}><Button disabled={loading} style={styles.CDFullButton} onClick={() => handleCancel()}>Cancel</Button></td>
                                        </tr>
                                        </tbody>
                                    </Table>                            
                                </div>
                            </Card.Title>
                        </Card.Header>                    
                        <Card.Body>

                            <div >
                                {/* <Card style={{width:'100%', backgroundColor:'transparent'}}> */}
                                <Card className="text-left">
                                    <Card.Header className='text-center' style={{backgroundColor:companyData?.PagesBGColor}}> 
                                        <Card.Title className='text-center'>
                                            <Table responsive="sm" className='text-center' style={{backgroundColor:companyData?.PagesBGColor}}>
                                            <tbody>
                                                <tr>
                                                    <td style={{textAlign:'center', width:'70%', backgroundColor:companyData?.PagesBGColor}}><h5 style={styles.SubHeader}>{evaluation && evaluation[0].EvaluationName ? evaluation[0].EvaluationName : "Questionnaire"}</h5></td>
                                                </tr>
                                                <tr>
                                                    <td style={{textAlign:'center', width:'70%', backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>{evaluation && evaluation[0].EvaluationHeader ? evaluation[0].EvaluationHeader : ""}</h6></td>
                                                </tr>
                                            </tbody>
                                            </Table>                            
                                        </Card.Title>
                                    </Card.Header>                    
                                    <CardBody className='text-center' style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <Survey model={survey}/>
                                    </CardBody>
                                <Card.Footer className="text-right" style={{backgroundColor:companyData?.PagesBGColor}}>
                                </Card.Footer>
                            </Card>
                            </div>
                        </Card.Body>
                    </Card>


                </form>

            </div>

        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      evaluation: state.entity.evaluation,
      evaluationquestions: state.entity.evaluationquestions,
      evaluationratings: state.entity.evaluationratings,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateEvaluationResponses: (evaluationResponses) => dispatch(updateEvaluationResponses(evaluationResponses)),
        updateUserEvaluationSession: (evaluationSession) => dispatch(updateUserEvaluationSession(evaluationSession)),
        getUserEvaluationSessions: (id) => dispatch(getUserEvaluationSessions(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LaunchEvaluation)

