import React from 'react';
import { ClipLoader, RingLoader, CircleLoader, GridLoader, HashLoader, PropagateLoader, RiseLoader } from 'react-spinners';
import { connect } from 'react-redux';
import { store } from '../../store/store';

// import { accentColor, accentColorSecondary } from '../../config/Colors';

function Spinner(props) {
  const loggedInState = store.getState();
  const companyData = loggedInState?.auth?.companyData;

  const override = {
    display: "block",
    borderColor: companyData?.AccentColor,
    textAlign:'center',
    alignSelf:'center',
    justifyContent: 'center', 
  };    

  return (
    <div style={{ width: '100px', margin: 'auto', display: 'block' }}>
      <RingLoader 
        color={companyData?.AccentColor} 
        size={76}
        cssOverride={override}
        />
    </div>
  );
};


const mapsStateToProps = (state) => {
  return {
      companyData: state?.auth?.companyData,
  }
}

export default connect(mapsStateToProps, null)(Spinner);
