import React, { useRef, useState, useEffect } from 'react';
import { Button, Card, Modal, Table, Image } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment, { now } from 'moment';
import { getUserAttributesLookups, getJobPositions, 
        insertUserLicense, deleteUserLicense, 
        insertUserCredential, deleteUserCredential, 
        insertUserGoal, deleteUserGoal, 
        insertUserSkill, updateUserSkill, deleteUserSkill, 
        insertUserJobPositionRequirement, deleteUserJobPositionRequirement, 
        insertUserFutureJobPosition, deleteUserFutureJobPosition, 
    } from '../../store/actions/entityActions';
import { getUserLicenses, getUserCredentials, getUserSkills, getUserJobPositionRequirements, getUserFutureJobPositions, getUserGoals } from '../../store/actions/authActions';
import Spinner from '../layout/Spinner';
import JobPositions from '../auth/JobPositions'

const UpdateProfile = (props) => {
    const navigate = useNavigate();
    const { auth, companyData, fromUserList, userattributelookups, jobpositions, lookupselections, 
        profile, mygroups, mycredentials, mycustomfields, myfuturejobposition, myjobpositionrequirement, mylicenses, myskills, mysupervisors, 
        selectedProfile, loadingMenu, mygoals} = props;

    let entity = profile;
    let usergroups = mygroups;
    let usercredentials = mycredentials;
    let usercustomfields = mycustomfields;
    let userfuturejobposition = myfuturejobposition;
    let userjobpositionrequirement = myjobpositionrequirement;
    let userlicenses = mylicenses;
    let userskills = myskills;
    let usersupervisors = mysupervisors;
    let usergoals = mygoals;

    if ((fromUserList != null) && (fromUserList)) {
        entity = selectedProfile.Users[0];
        usergroups = selectedProfile.UserGroups;
        usercredentials = selectedProfile.UserCredential;
        usercustomfields = selectedProfile.UserField;
        userfuturejobposition = selectedProfile.UserFutureJobPosition;
        userjobpositionrequirement = selectedProfile.UserJobPositionRequirements;
        userlicenses = selectedProfile.UserLicense;
        userskills = selectedProfile.UserSkill;
        usersupervisors = selectedProfile.UserSupervisor;
        usergoals = selectedProfile.UserGoal;
    }   

    const licensesData = userattributelookups?.Licenses;
    const credentialData = userattributelookups?.Credentials;
    const credentialBodyTypeData = userattributelookups?.CredentialBodyTypes;
    const educationData = userattributelookups?.Educations;
    const experienceData = userattributelookups?.Experiences;
    const jpRequirementData = userattributelookups?.JobPositionRequirements;
    const lslData = userattributelookups?.LeadershipAssessmentLevels;
    const schoolData = userattributelookups?.Schools;
    const skillLevelData = userattributelookups?.SkillLevels;
    const skillData = userattributelookups?.Skills;
    const stateData = userattributelookups?.StateCodes;
    const certificationData = userattributelookups?.Certifications;
    const ratingData = userattributelookups?.Ratings;
    const evalGroupData = userattributelookups?.EvaluationQuestionGroups;
    const orgGoals =  userattributelookups?.OrgGoals;
    const orgGoalRatings =  userattributelookups?.OrgGoalRatings;
    const metricTypeData =  userattributelookups?.MetricTypes;

    const [licenseTypeId, setLicenseTypeId] = useState(-1);
    const [licenseNumber, setLicenseNumber] = useState('');
    const [licenseAcquiredDate, setLicenseAcquiredDate] = useState(moment());
    const [licenseExpiryDate, setLicenseExpiryDate] = useState(moment());
    const [licenseStateCode, setLicenseStateCode] = useState('');
    const [credentialBodyTypeId, setCredentialBodyTypeId] = useState(-1);
    const [credentialNumber, setCredentialNumber] = useState('');
    const [credentialAcquiredDate, setCredentialAcquiredDate] = useState(moment());
    const [credentialExpiryDate, setCredentialExpiryDate] = useState(moment());
    const [credentialStateCode, setCredentialStateCode] = useState('');
    const [userSkillId, setUserSkillId] = useState(-1);
    const [skillId, setSkillId] = useState(-1);
    const [skillLevelId, setSkillLevelId] = useState(-1);
    const [skillComments, setSkillComments] = useState('');
    const [skillIsComplete, setSkillIsComplete] = useState(0);
    const [skillPreceptorComments, setSkillPreceptorComments] = useState('');
    const [skillAssignedDate, setSkillAssignedDate] = useState();
    const [skillCompletedDate, setSkillCompletedDate] = useState();
    const [skillURL, setSkillURL] = useState('');
    const [skillMetricTypeId, setSkillMetricTypeId] = useState(1);
    const [skillScore, setSkillScore] = useState(-1);
    const [skillTestComplete, setSkillTestComplete] = useState(0);
    const [skillTestDateCompleted, setSkillTestDateCompleted] = useState();
    const [skillTestScore, setSkillTestScore] = useState(-1);
    const [requirementTypeClicked, setrequirementTypeClicked] = useState(-1);
    const [educationType, setEducationType] = useState(-1);
    const [educationMinYears, setEducationMinYears] = useState(-1);
    const [educationMaxYears, setEducationMaxYears] = useState(-1);
    const [experienceType, setExperienceType] = useState(-1);
    const [experienceMinYears, setExperienceMinYears] = useState(-1);
    const [experienceMaxYears, setExperienceMaxYears] = useState(-1);
    const [licenseType, setLicenseType] = useState(-1);
    const [certificationID, setCertificationID] = useState(-1);
    const [jpRequirementType, setJPRequirementType] = useState(-1);
    const [ratingType, setRatingType] = useState(-1);
    const [jpNotes, setJPNotes] = useState('');
    const [careerComments, setCareerComments] = useState('');
    const [careerStep, setCareerStep] = useState(-1);
    const [careerJobPositionID, setCareerJobPositionID] = useState(-1);
    const [careerJobPositionName, setCareerJobPositionName] = useState('');
    const [careerJobPositionSalary, setCareerJobPositionSalary] = useState('');
    const [goalType, setGoalType] = useState(5);
    const [goalID, setGoalID] = useState('');
    const [goalGroupID, setGoalGroupID] = useState(-1);
    const [goalMetric, setGoalMetric] = useState('');
    const [goalComments, setGoalComments] = useState('');
    const [goalComplete, setGoalComplete] = useState(0);
    const [goalAssignedDate, setGoalAssignedDate] = useState(moment());
    const [goalDueDate, setGoalDueDate] = useState(moment());
    const [goalCompletedDate, setGoalCompletedDate] = useState(moment());
    const [goalRatingTypeID, setGoalRatingTypeID] = useState(-1);

    // const [profileimage, setprofileimage] = useState(null);
    // const dobDefault = entity?.BirthDate?.toDate().getFullYear() + '-' + (entity?.BirthDate?.toDate().getMonth() + 1) + '-' + entity?.BirthDate?.toDate().getDate();  
    const hireDateDefault = moment(entity?.DateHired?.substr(0,10)).format("MM/DD/YYYY");
    // const initailemail = props.auth.email;
    const [loading, setLoading] = useState(false);
    const [imageURL, setimageURL] = useState("");
    // let profileimageurl = (props.profile.profileimageurl) ? props.profile.profileimageurl : "";

    const [showMsg, setshowMsg] = useState(false);
    const handleCloseMsg = () => {setshowMsg(false); navigate('/');}
    function handleShowMsg() {
        setshowMsg(true); 
    };

    const [showJPList, setShowJPList] = useState(false);
    const handleCloseJPList = () => {setShowJPList(false);updateJobPositionSelection();}
    async function handleShowJPList() {
        setShowJPList(true); 
        setLoading(true);
        // const jpParams = {
        //     RecordSize:'10',
        //     PageNumber:'1',
        // };            
        // console.log(jpParams);
        // await props.getJobPositions(jpParams);
        setLoading(false);
    };

    const [tabValue, setTabValue] = useState(initialTabValue());

    const [newUserLicenseClicked, setNewUserLicenseClicked] = useState(false);
    const [newUserCredentialClicked, setNewUserCredentialClicked] = useState(false);
    const [newUserRequirementClicked, setNewUserRequirementClicked] = useState(false);
    const [newUserCompetencyClicked, setNewUserCompetencyClicked] = useState(false);
    const [editUserCompetencyClicked, setEditUserCompetencyClicked] = useState(false);
    const [newUserCareerClicked, setNewUserCareerClicked] = useState(false);
    const [newUserGoalClicked, setNewUserGoalClicked] = useState(false);

    const radios = [
        { name: 'Attributes', value: 1 },
        { name: 'Groups', value: 2 },
        { name: 'Supervisors', value: 3 },
        { name: 'Licenses', value: 4 },
        { name: 'Credentials', value: 5 },
        { name: 'Requirements', value: 6 },
        { name: 'Competencies', value: 7 },
        { name: 'Career', value: 8 },
        { name: 'Goals', value: 9 },
    ];
   
    function initialTabValue() {
        return 1;
    };

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    function updateJobPositionSelection() {
        const sSelections = lookupselections.JobPosition;
        if (sSelections !== null) {
            setCareerJobPositionID(sSelections.split('&')[0].split('=')[0]);
            setCareerJobPositionName(sSelections.split('&')[0].split('=')[1].split('|')[0]);
            setCareerJobPositionSalary(sSelections.split('&')[0].split('=')[1].split('|')[1]);
        }
    }

    function displayTabButtons() {
        return (
            radios.map((radio, idx) => (
                <li style={(tabValue === radio.value) ? styles.TabLinkActive : styles.TabLink}>
                    <span style={styles.SpanContainer} onClick={(e) => {handleTabClick(radio.value)}}>{radio.name}</span>
                </li>
            ))
        );
    }

    function displayAddButtons() {
        if (tabValue === 4 ) {
            if (newUserLicenseClicked) {
                return (
                    <a onClick={() => handleAddChildernClick(4)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New License'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(4)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New License'>note_add</span>
                    </a>

                );
            }
        } else if (tabValue === 5 ) {
            if (newUserCredentialClicked) {
                return (
                    <a onClick={() => handleAddChildernClick(5)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Credential'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(5)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Credential'>note_add</span>
                    </a>

                );
            }
        } else if (tabValue === 6 ) {        
            if (newUserRequirementClicked) {
                return (
                    <a onClick={() => handleAddChildernClick(6)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Job Requirement'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(6)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Job Requirement'>note_add</span>
                    </a>

                );
            }
        } else if (tabValue === 7 ) {        
            if ((newUserCompetencyClicked) || (editUserCompetencyClicked)) {
                return (
                    <a onClick={() => handleAddChildernClick(7)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Competency/Skill'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(7)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Competency/Skill'>note_add</span>
                    </a>

                );
            }
        } else if (tabValue === 8 ) {
            if (newUserCareerClicked) {
                return (
                    <a onClick={() => handleAddChildernClick(8)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Career Path'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(8)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Career Path'>note_add</span>
                    </a>

                );
            }
        } else if (tabValue === 9 ) {
            if (newUserGoalClicked) {
                return (
                    <a onClick={() => handleAddChildernClick(9)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Goal'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(9)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Goal'>note_add</span>
                    </a>

                );
            }
        }

    }

    function handleTabClick(tab) {
        setTabValue(tab);
        displayTabContent();
    };

    async function handleAddChildernClick(tab) {
        setLoading(true);
        // if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        // }

        if (tab === 4) {
            (newUserLicenseClicked) ? setNewUserLicenseClicked(false) : setNewUserLicenseClicked(true);
        } else if (tab === 5) {
            (newUserCredentialClicked) ? setNewUserCredentialClicked(false) : setNewUserCredentialClicked(true);
        } else if (tab === 6) {
            (newUserRequirementClicked) ? setNewUserRequirementClicked(false) : setNewUserRequirementClicked(true);
        } else if (tab === 7) {
            (newUserCompetencyClicked) ? setNewUserCompetencyClicked(false) : setNewUserCompetencyClicked(true);
        } else if (tab === 8) {
            if (!jobpositions) {
                await props.getJobPositions();
            }
            (newUserCareerClicked) ? setNewUserCareerClicked(false) : setNewUserCareerClicked(true);
        } else if (tab === 9) {
            (newUserGoalClicked) ? setNewUserGoalClicked(false) : setNewUserGoalClicked(true);
        }
        setLoading(false);
    }

    async function handleEditChildernClick(tab, id) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 4) {
            // (newUserLicenseClicked) ? setNewUserLicenseClicked(false) : setNewUserLicenseClicked(true);
        } else if (tab === 5) {
            // (newUserCredentialClicked) ? setNewUserCredentialClicked(false) : setNewUserCredentialClicked(true);
        } else if (tab === 6) {
            // (newUserRequirementClicked) ? setNewUserRequirementClicked(false) : setNewUserRequirementClicked(true);
        } else if (tab === 7) {
            (editUserCompetencyClicked) ? setEditUserCompetencyClicked(false) : setEditUserCompetencyClicked(true);
            const oEdited = userskills?.filter((evalRec) => (evalRec.UserSkillID === id));
            if (oEdited.length > 0) {
                setUserSkillId(id); 
                setSkillId(oEdited[0]?.SkillID);
                setSkillLevelId(oEdited[0]?.SkillLevelID);
                setSkillComments(oEdited[0]?.Comments);
                setSkillIsComplete(oEdited[0]?.IsComplete);
                setSkillPreceptorComments(oEdited[0]?.PreceptorComments);
                setSkillAssignedDate(oEdited[0]?.AssignedDate);
                setSkillCompletedDate(oEdited[0]?.CompletedDate);
                setSkillURL(oEdited[0]?.SkillURL);
                setSkillMetricTypeId(oEdited[0]?.MetricTypeID);
                setSkillScore(oEdited[0]?.Score);
                setSkillTestComplete(oEdited[0]?.TestComplete);
                setSkillTestDateCompleted(oEdited[0]?.TestDateCompleted);
                setSkillTestScore(oEdited[0]?.TestScore);    
            }
            // initialize all the field with edited values. 
        } else if (tab === 8) {
            // if (!jobpositions) {
            //     await props.getJobPositions();
            // }
            // (newUserCareerClicked) ? setNewUserCareerClicked(false) : setNewUserCareerClicked(true);
        } else if (tab === 9) {
            // (newUserGoalClicked) ? setNewUserGoalClicked(false) : setNewUserGoalClicked(true);
        }
        setLoading(false);
    }

    async function handleUserRequirementType(e) {
        const reqType = jpRequirementData[e.target.selectedIndex].JobPositionRequirementTypeID.split('|')[1];
        setrequirementTypeClicked(reqType);
        setJPRequirementType(jpRequirementData[e.target.selectedIndex].JobPositionRequirementTypeID.split('|')[0]);
    }

    async function handleSaveChildernClick(tab) {
        setLoading(true);
        if (tab === 4) {
            const licParams = {
                "UserLicenseTypeID": -1,
                "UserID": entity?.UserID,
                "LicenseTypeID":licenseTypeId,
                "LicenseNumber":licenseNumber,
                "AcquiredDate": licenseAcquiredDate, 
                "ExpiryDate": licenseExpiryDate, 
                "IsDeleted": false,
                "StateCode": licenseStateCode,
                "CreatedByUserID":auth?.UserID
            }
            await props.insertUserLicense(licParams);
            await props.getUserLicenses(entity?.UserID, fromUserList);
        } else if (tab === 5) {
            const credParams = {
                "UserCredentialBodyID": -1,
                "UserID": entity?.UserID,
                "CredentialBodyTypeID":credentialBodyTypeId,
                "LicenseNumber":credentialNumber,
                "AcquiredDate": credentialAcquiredDate, 
                "ExpiryDate": credentialExpiryDate, 
                "IsDeleted": false,
                "StateCode": credentialStateCode,
                "CreatedByUserID":auth?.UserID
            }
            await props.insertUserCredential(credParams);
            await props.getUserCredentials(entity?.UserID, fromUserList);
        } else if (tab === 6) {
            let iEntityID = -1;
            let iMinYears = -1;
            let iMaxYears = -1;
            if (requirementTypeClicked === '11') {
                iEntityID = educationType;
                iMinYears = educationMinYears;
                iMaxYears = educationMaxYears;
            } else if (requirementTypeClicked === '12') {
                iEntityID = experienceType;
                iMinYears = experienceMinYears;
                iMaxYears = experienceMaxYears;
            } else if (requirementTypeClicked === '13') {
                iEntityID = licenseType;
            } else if (requirementTypeClicked === '14') {
                iEntityID = certificationID;
            } 

            const oParams = {
                "UserLicenseTypeID": -1,
                "UserID": entity?.UserID,
                "JobPositionRequirementTypeID":jpRequirementType,
                "EvaluationRatingTypeID":ratingType,
                "EntityRowTypeID":requirementTypeClicked,
                "EntityID":iEntityID,
                "MinimumYears":iMinYears,
                "MaximumYears":iMaxYears,
                "Comments":jpNotes,
                "IsDeleted": false,
                "CreatedByUserID":auth?.UserID
            }
            console.log(oParams);
            await props.insertUserJobPositionRequirement(oParams);
            await props.getUserJobPositionRequirements(entity?.UserID, fromUserList);
        } else if (tab === 7) {
            if (editUserCompetencyClicked) {
                let skillParams = {
                    "UserSkillID": userSkillId,
                    "UserID": entity?.UserID,
                    "SkillID":skillId,
                    "SkillLevelID":skillLevelId,
                    "Comments": skillComments, 
                    "IsComplete": skillIsComplete,
                    "PreceptorComments":skillPreceptorComments,
                    "AssignedDate": skillAssignedDate,
                    "CompletedDate": skillCompletedDate,
                    "SkillURL":skillURL,
                    "MetricTypeID":skillMetricTypeId,
                    "Score":skillScore,
                    "TestComplete":skillTestComplete,
                    "TestDateCompleted":skillTestDateCompleted,
                    "TestScore":skillTestScore,    
                    "UpdatedByUserID":auth?.UserID
                }    
                console.log(skillParams);
                await props.updateUserSkill(skillParams);
            } else {
                let skillParams = {
                    "UserSkillID": -1,
                    "UserID": entity?.UserID,
                    "SkillID":skillId,
                    "SkillLevelID":skillLevelId,
                    "Comments": skillComments, 
                    "IsComplete": skillIsComplete,
                    "PreceptorComments":skillPreceptorComments,
                    "AssignedDate": skillAssignedDate,
                    "CompletedDate": skillCompletedDate,
                    "SkillURL":skillURL,
                    "MetricTypeID":skillMetricTypeId,
                    "Score":skillScore,
                    "TestComplete":skillTestComplete,
                    "TestDateCompleted":skillTestDateCompleted,
                    "TestScore":skillTestScore,    
                    "CreatedByUserID":auth?.UserID
                }
                    await props.insertUserSkill(skillParams);
            }
            await props.getUserSkills(auth?.UserID, fromUserList);
            setNewUserCompetencyClicked(false);
            setEditUserCompetencyClicked(false);
            handleCancelChildernClick(7, null);
        } else if (tab === 8) {
            const oParams = {
                "UserFutureJobPositionID": -1,
                "UserID": entity?.UserID,
                "JobPositionID":careerJobPositionID,
                "CareerStep":careerStep,
                "UserFutureJobPositionDescription": careerComments, 
                "CreatedByUserID":auth?.UserID
            }
            console.log(oParams);
            await props.insertUserFutureJobPosition(oParams);
            await props.getUserFutureJobPositions(entity?.UserID, fromUserList);
        } else if (tab === 9) {
            const sGoalID = goalID.split('|')[0];
            const sSubGoalID = goalID.split('|')[1];
            const oParams = {
                "UserID": entity?.UserID,
                "UserEvaluationSessionID": -1,
                "EvaluationID": sGoalID,
                "EvaluationQuestionID": sSubGoalID,
                "EvaluationQuestionGroupID": goalGroupID,
                "EvaluationEntityTypeID": goalType,
                "EvaluationRatingTypeID": goalRatingTypeID,
                "Metric":goalMetric,
                "Comments":goalComments,
                "AssignedDate": goalAssignedDate, 
                "DueDate": goalDueDate, 
                "CompletedDate": goalCompletedDate, 
                "IsPublished": goalComplete, 
                "CreatedByUserID":auth?.UserID
            }
            console.log(oParams);
            await props.insertUserGoal(oParams);
            await props.getUserGoals(entity?.UserID, fromUserList);
        }
        setLoading(false);
    }

    async function handleCancelChildernClick(tab, currentEval) {
        if (tab === 7) {
            setUserSkillId(-1); 
            setSkillId(-1);
            setSkillLevelId(-1);
            setSkillComments('');
            setSkillIsComplete(-1);
            setSkillPreceptorComments('');
            setSkillAssignedDate(moment());
            setSkillCompletedDate(moment());
            setSkillURL('');
            setSkillMetricTypeId(-1);
            setSkillScore(-1);
            setSkillTestComplete(moment());
            setSkillTestDateCompleted(moment());
            setSkillTestScore(-1);    
            setEditUserCompetencyClicked(false);
            setNewUserCompetencyClicked(false);
        } 
    }
    

    async function handleDeleteChildernClick(tab, id) {
        setLoading(true);
        if (tab === 4) {
            const licParams = {
                "UserLicenseTypeID": id,
                "UserID": entity?.UserID,
            }
            await props.deleteUserLicense(licParams);
            await props.getUserLicenses(auth?.UserID, fromUserList);
        } else if (tab === 5) {
            const credParams = {
                "UserCredentialBodyID": id,
                "UserID": entity?.UserID,
            }
            await props.deleteUserCredential(credParams);
            await props.getUserCredentials(auth?.UserID, fromUserList);
        } else if (tab === 6) {
            const oParams = {
                "UserID": entity?.UserID,
                "UserJobPositionRequirementID": id,
            }
            console.log(oParams);
            await props.deleteUserJobPositionRequirement(oParams);
            await props.getUserJobPositionRequirements(auth?.UserID, fromUserList);
        } else if (tab === 7) {
            const skillParams = {
                "UserSkillID": id,
                "UserID": entity?.UserID,
            }
            await props.deleteUserSkill(skillParams);
            await props.getUserSkills(auth?.UserID, fromUserList);
        } else if (tab === 8) {
            const oParams = {
                "UserFutureJobPositionID": id,
                "UserID": entity?.UserID,
            }
            console.log(oParams);
            await props.deleteUserFutureJobPosition(oParams);
            await props.getUserFutureJobPositions(auth?.UserID, fromUserList);
        } else if (tab === 9) {
            const sSessionID = id.split('|')[0];
            const sQuestionID = id.split('|')[1];
            const sRatingID = id.split('|')[2];

            const oParams = {
                "UserID": entity?.UserID,
                "UserEvaluationSessionID": sSessionID,
                "EvaluationQuestionID": sQuestionID,
                "EvaluationRatingTypeID": sRatingID,
            }
            console.log(oParams);
            await props.deleteUserGoal(oParams);
            await props.getUserGoals(auth?.UserID, fromUserList);
        }

        setLoading(false);
    }

    function displayNewUserLicense() {
        if (newUserLicenseClicked) {
            return (
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>License</label>
                                    <select id="license" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (setLicenseTypeId(e.target.value))}>
                                        {licensesData.map((license, supIndex) => (
                                            <option value={license.LicenseTypeID}>{license.LicenseTypeName}</option>        
                                        ))}
                                    </select> 
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="licenseAcquired" style={styles.LabelDataEntryNoPadding}>Acquired</label>
                                    <input id="licenseAcquired" type="date" style={styles.TextDataEntryNoPadding} onChange={(e) => (setLicenseAcquiredDate(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="licenseNumber" style={styles.LabelDataEntryNoPadding}>Number</label>
                                    <input id="licenseNumber" type="text" style={styles.TextDataEntryNoPadding} onChange={(e) => (setLicenseNumber(e.target.value))}/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>State</label>
                                    <select id="licenseState" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (setLicenseStateCode(e.target.value))}>
                                        {stateData.map((state, supIndex) => (
                                            <option value={state.State}>{state.StateName}</option>        
                                        ))}
                                    </select>
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="licenseExpires" style={styles.LabelDataEntryNoPadding}>Expires</label>
                                    <input id="licenseExpires" type="date" style={styles.TextDataEntryNoPadding} onChange={(e) => (setLicenseExpiryDate(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'center'}}>
                                <div>
                                    {/* <a onClick={() => handleSaveChildernClick(4)}>
                                        <span style={styles.ActionIcon} class="material-symbols-outlined" title='Save License'>save</span>
                                    </a> */}
                                    <Button onClick={() => handleSaveChildernClick(4)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save License'>Save</Button>
                                </div>
                            </td>
                        </tr>                
                    </tbody>
                </table>                                   
            );
        }
    }

    function displayNewUserCredential() {
        if (newUserCredentialClicked) {
            return (
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Credential</label>
                                    <select id="credential" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (setCredentialBodyTypeId(e.target.value))}>
                                        {credentialBodyTypeData.map((credential, supIndex) => (
                                            <option value={credential.CredentialBodyTypeID}>{credential.CredentialBodyTypeName}</option>        
                                        ))}
                                    </select> 
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="credAcquired" style={styles.LabelDataEntryNoPadding}>Acquired</label>
                                    <input id="credAcquired" type="date" style={styles.TextDataEntryNoPadding} onChange={(e) => (setCredentialAcquiredDate(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="credlicenseNumber" style={styles.LabelDataEntryNoPadding}>Number</label>
                                    <input id="credLicenseNumber" type="text" style={styles.TextDataEntryNoPadding} onChange={(e) => (setCredentialNumber(e.target.value))}/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>State</label>
                                    <select id="credState" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (setCredentialStateCode(e.target.value))}>
                                        {stateData.map((state, supIndex) => (
                                            <option value={state.State}>{state.StateName}</option>        
                                        ))}
                                    </select>
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="credExpires" style={styles.LabelDataEntryNoPadding}>Expires</label>
                                    <input id="credExpires" type="date" style={styles.TextDataEntryNoPadding} onChange={(e) => (setCredentialExpiryDate(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'center'}}>
                                <div>
                                    {/* <a onClick={() => handleSaveChildernClick(5)}>
                                        <span style={styles.ActionIcon} class="material-symbols-outlined" title='Save Credential'>save</span>
                                    </a> */}
                                    <Button onClick={() => handleSaveChildernClick(5)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Credential'>Save</Button>
                                </div>
                            </td>
                        </tr>                
                    </tbody>
                </table>                                   
            );
        }
    }

    function displayNewUserSkill() {
        if ((newUserCompetencyClicked) || (editUserCompetencyClicked)){
            return (
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td colSpan='5' style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Competency/Skill</label>
                                    <select id="skill" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={skillId} onChange={(e) => (setSkillId(e.target.value))}>
                                        {skillData.map((skill, supIndex) => (
                                            <option value={skill.SkillID}>{skill.SkillName}</option>        
                                        ))}
                                    </select> 
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'15%'}}>
                                <div>
                                    <label htmlFor="assigneddate" style={styles.LabelDataEntryNoPadding}>Assigned Date</label>
                                    <input id="assigneddate" type="date" style={styles.TextDataEntryNoPadding} value={((skillAssignedDate != null) && (skillAssignedDate != undefined)) ? skillAssignedDate?.substr(0,10) : ''} onChange={(e) => (setSkillAssignedDate(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='3' style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Metric Type</label>
                                    <select id="metrictype" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={skillMetricTypeId} onChange={(e) => (setSkillMetricTypeId(e.target.value))}>
                                        {metricTypeData?.map((metric, supIndex) => (
                                            <option value={metric.MetricTypeID}>{metric.MetricTypeName}</option>        
                                        ))}
                                    </select> 
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'10%'}}>
                                <div>
                                    <label htmlFor="skillcomplete" style={styles.LabelDataEntryNoPadding}>Complete</label>
                                    <select id="skillcomplete" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={skillIsComplete} onChange={(e) => (setSkillIsComplete(e.target.value))}>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="skillscore" style={styles.LabelDataEntryNoPadding}>Score</label>
                                    <input id="skillscore" type="number" min='1' step='1' style={styles.TextDataEntryNoPadding} defaultValue={skillScore} onChange={(e) => (setSkillScore(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'15%'}}>
                                <div>
                                    <label htmlFor="completeddate" style={styles.LabelDataEntryNoPadding}>Completed Date</label>
                                    <input id="completeddate" type="date" style={styles.TextDataEntryNoPadding} value={(skillCompletedDate) ? skillCompletedDate?.substr(0,10) : ''} onChange={(e) => (setSkillCompletedDate(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='3' style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="skillComments" style={styles.LabelDataEntryNoPadding}>User Comments</label>
                                    <input id="skillComments" type="text" style={styles.TextDataEntryNoPadding} defaultValue={skillComments} onChange={(e) => (setSkillComments(e.target.value))}/>
                                </div>
                            </td>
                            <td colSpan='3' style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="skillPreceptorComments" style={styles.LabelDataEntryNoPadding}>Preceptor Comments</label>
                                    <input id="skillPreceptorComments" type="text" style={styles.TextDataEntryNoPadding} defaultValue={skillPreceptorComments} onChange={(e) => (setSkillPreceptorComments(e.target.value))}/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', width:'15%'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Level</label>
                                    <select id="skillLevel" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={skillLevelId} onChange={(e) => (setSkillLevelId(e.target.value))}>
                                        {skillLevelData.map((skillLevel, supIndex) => (
                                            <option value={skillLevel.SkillLevelID}>{skillLevel.SkillLevelName}</option>        
                                        ))}
                                    </select> 
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'10%'}}>
                                <div>
                                    <label htmlFor="skilltestcomplete" style={styles.LabelDataEntryNoPadding}>Test Complete</label>
                                    <select id="skilltestcomplete" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={skillTestComplete} onChange={(e) => (setSkillTestComplete(e.target.value))}>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="testscore" style={styles.LabelDataEntryNoPadding}>Test Score</label>
                                    <input id="testscore" type="number" min='1' step='1' style={styles.TextDataEntryNoPadding} defaultValue={skillTestScore} onChange={(e) => (setSkillTestScore(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'15%'}}>
                                <div>
                                    <label htmlFor="testcompleteddate" style={styles.LabelDataEntryNoPadding}>Test Completed Date</label>
                                    <input id="testcompleteddate" type="date" style={styles.TextDataEntryNoPadding} value={(skillTestDateCompleted) ? skillTestDateCompleted?.substr(0,10) : ''} onChange={(e) => (setSkillTestDateCompleted(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'right'}}>
                                <div>
                                    <Button onClick={() => handleSaveChildernClick(7)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Competency/Skill'>Save</Button>
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <Button onClick={() => handleCancelChildernClick(7, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Competency/Skill'>Cancel</Button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>                                   
            );
        }
    }

    function displayNewUserCareerPath() {
        if (newUserCareerClicked) {
            return (
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <a onClick={() => setShowJPList(true)}>
                                        <label style={styles.LabelDataEntryNoPadding}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Job Position List'>mystery</span>                                    
                                            Job Position
                                        </label>
                                    </a>
                                    <input id="jpid" type="text" disabled style={styles.TextDataEntryNoPadding} value={careerJobPositionName}/>                                    
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="careersteps" style={styles.LabelDataEntryNoPadding}>Step</label>
                                    <input id="careersteps" type="number" style={styles.TextDataEntryNoPadding} onChange={(e) => (setCareerStep(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="careerComments" style={styles.LabelDataEntryNoPadding}>Comments</label>
                                    <input id="careerComments" type="text" style={styles.TextDataEntryNoPadding} onChange={(e) => (setCareerComments(e.target.value))}/>
                                </div>
                            </td>
                            <td style={{textAlign:'center'}}>
                                <div>
                                    {/* <a onClick={() => handleSaveChildernClick(7)}>
                                        <span style={styles.ActionIcon} class="material-symbols-outlined" title='Save Competency/Skill'>save</span>
                                    </a> */}
                                    <Button onClick={() => handleSaveChildernClick(8)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Career Path'>Save</Button>
                                </div>
                            </td>
                        </tr>                
                    </tbody>
                </table>                                   
            );
        }
    }

    function displayNewJPReqEducation() {
        if (requirementTypeClicked === '11') {
            return (
            <tr>
                <td style={{textAlign:'left'}}>
                    <div>
                        <label htmlFor="educationtype" style={styles.LabelDataEntryNoPadding}>Education</label>
                        <select id="educationtype" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (setEducationType(e.target.value))}>
                            {educationData.map((education, supIndex) => (
                                <option value={education.EducationTypeID}>{education.EducationTypeName}</option>        
                            ))}
                        </select>                                     
                    </div>                    
                </td>
                <td style={{textAlign:'left'}}>
                    <div>
                        <label htmlFor="eduMinYear" style={styles.LabelDataEntryNoPadding}>Min. Years</label>
                        <input id="eduMinYear" type="number" style={styles.TextDataEntryNoPadding} onChange={(e) => (setEducationMinYears(e.target.value))}/>
                    </div>                    
                </td>
                <td style={{textAlign:'left'}}>
                    <div>
                        <label htmlFor="licenseExpires" style={styles.LabelDataEntryNoPadding}>Max. Years</label>
                        <input id="eduMaxYear" type="number" style={styles.TextDataEntryNoPadding} onChange={(e) => (setEducationMaxYears(e.target.value))}/>
                    </div>                    
                </td>
            </tr>
        )
        } else {
            return (<></>)
        }
    }

    function displayNewJPReqExperience() {
        if (requirementTypeClicked === '12') {
            return (
                <tr>
                    <td style={{textAlign:'left'}}>
                        <div>
                            <label htmlFor="experiencetype" style={styles.LabelDataEntryNoPadding}>Experience</label>
                            <select id="experiencetype" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (setExperienceType(e.target.value))}>
                                {experienceData.map((experience, supIndex) => (
                                    <option value={experience.ExperienceTypeID}>{experience.ExperienceTypeName}</option>        
                                ))}
                            </select>                                     
                        </div>                    
                    </td>
                    <td style={{textAlign:'left'}}>
                        <div>
                            <label htmlFor="expMinYear" style={styles.LabelDataEntryNoPadding}>Min. Years</label>
                            <input id="expMinYear" type="number" style={styles.TextDataEntryNoPadding} onChange={(e) => (setExperienceMinYears(e.target.value))}/>
                        </div>                    
                    </td>
                    <td style={{textAlign:'left'}}>
                        <div>
                            <label htmlFor="expMaxYear" style={styles.LabelDataEntryNoPadding}>Max. Years</label>
                            <input id="expMaxYear" type="number" style={styles.TextDataEntryNoPadding} onChange={(e) => (setExperienceMaxYears(e.target.value))}/>
                        </div>                    
                    </td>
                </tr>
        )
        } else {
            return (<></>)
        }
    }

    function displayNewJPReqLicense() {
        if (requirementTypeClicked === '13') {
            return (
                <tr>    
                    <td style={{textAlign:'left'}}>
                        <div>
                            <label htmlFor="licenseExpires" style={styles.LabelDataEntryNoPadding}>License</label>
                            <select id="licensetype" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (setLicenseType(e.target.value))}>
                                {licensesData.map((license, supIndex) => (
                                    <option value={license.LicenseTypeID}>{license.LicenseTypeName}</option>        
                                ))}
                            </select>                                     
                        </div>                    
                    </td>
                    <td style={{textAlign:'left'}}>
                        <div>
                        </div>
                    </td>
                    <td style={{textAlign:'left'}}>
                        <div>
                        </div>
                    </td>
                </tr>    
            )
        } else {
            return (<></>)
        }
    }

    function displayNewJPReqCertification() {
        if (requirementTypeClicked === '14') {
            return (
                <tr>    
                    <td style={{textAlign:'left'}}>
                        <div>
                            <label htmlFor="certification" style={styles.LabelDataEntryNoPadding}>Certification</label>
                            <select id="certification" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (setCertificationID(e.target.value))}>
                                {certificationData.map((certification, supIndex) => (
                                    <option value={certification.CertificationID}>{certification.CertificationName}</option>        
                                ))}
                            </select>                                     
                        </div>                    
                    </td>
                    <td style={{textAlign:'left'}}>
                        <div>
                        </div>
                    </td>
                    <td style={{textAlign:'left'}}>
                        <div>
                        </div>
                    </td>
                </tr>
            )
        } else {
            return (<></>)
        }
    }

    function displayNewJPRequirement() {
        if (newUserRequirementClicked) {
            return (
                <table class="table caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Type</label>
                                    <select id="requirementtype" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleUserRequirementType(e))}>
                                        {jpRequirementData.map((requirement, supIndex) => (
                                            <option value={requirement.JobPositionRequirementID}>{requirement.JobPositionRequirementTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="reqRatings" style={styles.LabelDataEntryNoPadding}>Ratings</label>
                                    <select id="reqRatings" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (setRatingType(e.target.value))}>
                                        {ratingData.map((rating, supIndex) => (
                                            <option value={rating.EvaluationRatingTypeID}>{rating.EvaluationRatingTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                </div>
                            </td>
                        </tr>
                        {displayNewJPReqEducation()}
                        {displayNewJPReqExperience()}
                        {displayNewJPReqLicense()}
                        {displayNewJPReqCertification()}
                        <tr>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="reqNotes" style={styles.LabelDataEntryNoPadding}>Notes</label>
                                    <input id="reqNotes" type="text" style={styles.TextDataEntryNoPadding} onChange={(e) => (setJPNotes(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'center'}}>
                                <div>
                                    <Button onClick={() => handleSaveChildernClick(6)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Requirement'>Save</Button>
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>                                   
            );
        }
    }

    function displayNewUserGoal() {
        if (newUserGoalClicked) {
            return (
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                           <td style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Type</label>
                                    <select id="asswhen" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (setGoalType(e.target.value))}>
                                        <option value='5'>Organization</option>        
                                        <option value='6'>Direct Impact</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Group</label>
                                    <select id="skill" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (setGoalGroupID(e.target.value))}>
                                        {evalGroupData && evalGroupData.map((group, supIndex) => (
                                            <option value={group.EvaluationQuestionGroupID}>{group.EvaluationQuestionGroupName}</option>        
                                        ))}
                                    </select> 
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Goal</label>
                                    <select id="skill" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (setGoalID(e.target.value))}>
                                        {orgGoals && orgGoals.map((goal, supIndex) => (
                                            <option value={goal.EvaluationID + '|' + goal.EvaluationQuestionID}>{goal.EvaluationName + ' - ' + goal.EvaluationQuestion}</option>        
                                        ))}
                                    </select> 
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Ratings</label>
                                    <select id="skill" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (setGoalRatingTypeID(e.target.value))}>
                                        {orgGoalRatings && orgGoalRatings.map((rating, supIndex) => (
                                            <option value={rating.EvaluationRatingTypeID}>{rating.EvaluationRatingTypeName}</option>        
                                        ))}
                                    </select> 
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Assign Date</label>
                                    <input id="goalassigneddate" type="date" style={styles.TextDataEntryNoPadding}  onChange={(e) => (setGoalAssignedDate(e.target.value))}/>
                                </div>
                            </td>
                            <td colSpan='2' style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="metric" style={styles.LabelDataEntryNoPadding}>Metric</label>
                                    <input id="metric" type="text" style={styles.TextDataEntryNoPadding} onChange={(e) => (setGoalMetric(e.target.value))}/>
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Complete</label>
                                    <select id="goalcomplete" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (setGoalComplete(e.target.value))}>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Due Date</label>
                                    <input id="goalduedate" type="date" style={styles.TextDataEntryNoPadding}  onChange={(e) => (setGoalDueDate(e.target.value))}/>
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Completed Date</label>
                                    <input id="goalcompleteddate" type="date" style={styles.TextDataEntryNoPadding}  onChange={(e) => (setGoalCompletedDate(e.target.value))}/>
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="skillComments" style={styles.LabelDataEntryNoPadding}>Comments</label>
                                    <input id="skillComments" type="text" style={styles.TextDataEntryNoPadding} onChange={(e) => (setGoalComments(e.target.value))}/>
                                </div>
                            </td>
                            <td style={{textAlign:'center'}}>
                                <div>
                                    <Button onClick={() => handleSaveChildernClick(9)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Goal'>Save</Button>
                                </div>
                            </td>
                        </tr>                
                    </tbody>
                </table>                                   
            );
        }
    }

    function displayTabContent() {
        if (tabValue === 1 ) {
            return (
                <div>
                    {getAttributes()}
                </div>
            );
        }
        else if (tabValue === 2 ) {
            return (
                (usergroups?.length === 0) ? <></> : 
                <table class="table table-sm caption-top text-left">  
                    <thead>
                        <tr>
                            <th style={styles.tableText}>#</th>
                            <th style={styles.tableTextMain}>Name</th>
                            <th style={styles.tableText}>Region</th>
                            <th style={styles.tableText}>Facility</th>
                            <th style={styles.tableText}>Division</th>
                            <th style={styles.tableText}>Position</th>
                            <th style={styles.tableText}>Is Primary</th>
                            <th style={styles.tableText}>Active</th>
                        </tr>
                    </thead>                        
                    <tbody> 
                        {getMyGroups()}  
                    </tbody>
                </table>                                   
            );
        } else if (tabValue === 3 ) {
            return (
                (usersupervisors?.length === 0) ? <></> : 
                <table class="table table-sm caption-top text-left">  
                    <thead>
                        <tr>
                            <th style={styles.tableText}>#</th>
                            <th style={styles.tableTextLeft}>Name</th>
                            <th style={styles.tableText}>Employee ID</th>
                            <th style={styles.tableText}>Email</th>
                            <th style={styles.tableText}>Phone</th>
                            <th style={styles.tableText}>Direct Level</th>
                        </tr>
                    </thead>                        
                    <tbody> 
                        {getMySupervisors()}  
                    </tbody>
                </table>                                   
            );
        } else if (tabValue === 4 ) {
            return (
                (userlicenses?.length === 0) ? <>{displayNewUserLicense()}</> : 
                <>
                {displayNewUserLicense()}
                <table class="table table-sm caption-top text-left">  
                    <thead>
                        <tr>
                            <th style={styles.tableText}>#</th>
                            <th style={styles.tableTextLeft}>Name</th>
                            <th style={styles.tableText}>Number</th>
                            <th style={styles.tableText}>Acquired</th>
                            <th style={styles.tableText}>Expires</th>
                            <th style={styles.tableText}>State</th>
                            <th style={styles.tableText}>Delete</th>
                        </tr>
                    </thead>                        
                    <tbody> 
                        {getMyLicenses()}  
                    </tbody>
                </table>                                   
                </>
            );
        } else if (tabValue === 5 ) {
            return (
                (usercredentials?.length === 0) ? <>{displayNewUserCredential()}</> : 
                <>
                {displayNewUserCredential()}
                <table class="table table-sm caption-top text-left">  
                    <thead>
                        <tr>
                            <th style={styles.tableText}>#</th>
                            <th style={styles.tableTextLeft}>Name</th>
                            <th style={styles.tableText}>Number</th>
                            <th style={styles.tableText}>Acquired</th>
                            <th style={styles.tableText}>Expires</th>
                            <th style={styles.tableText}>State</th>
                            <th style={styles.tableText}>Delete</th>
                        </tr>
                    </thead>                        
                    <tbody> 
                        {getMyCredentials()}  
                    </tbody>
                </table>                                   
                </>
            )
        } else if (tabValue === 6 ) {        
            return (           
                (userjobpositionrequirement?.length === 0) ? <>{displayNewJPRequirement()}</> : 
                <>
                {displayNewJPRequirement()}
                <table class="table table-sm caption-top text-left">  
                    <thead>
                        <tr>
                            <th style={styles.tableText}>#</th>
                            <th style={styles.tableTextLeft}>Requirement</th>
                            <th style={styles.tableTextLeft}>Name</th>
                            <th style={styles.tableText}>Rating</th>
                            <th style={styles.tableText}>Details</th>
                            <th style={styles.tableText}>Delete</th>
                        </tr>
                    </thead>                        
                    <tbody> 
                        {getMyRequirements()}  
                    </tbody>
                </table>
                </>
            );                                   
        } else if (tabValue === 7 ) {        
            return (
                (userskills?.length === 0) ? <>{displayNewUserSkill()}</> : 
                <>
                {displayNewUserSkill()}
                <table class="table table-sm caption-top text-left">  
                    <thead>
                        <tr>
                            <th style={styles.tableText}>#</th>
                            <th style={styles.tableTextLeft}>Name</th>
                            <th style={styles.tableText}>Metric</th>
                            <th style={styles.tableText}>Level</th>
                            <th style={styles.tableText}>Comments</th>
                            <th style={styles.tableText}>Completed</th>
                            <th style={styles.tableText}>Action</th>
                        </tr>
                    </thead>                        
                    <tbody> 
                        {getMySkills()}  
                    </tbody>
                </table>
                </>
            )                                   
        } else if (tabValue === 8 ) {
            return (                
                (userfuturejobposition?.length === 0) ? <>{displayNewUserCareerPath()}</> : 
                <>
                {displayNewUserCareerPath()}
                <table class="table table-sm caption-top text-left">  
                    <thead>
                        <tr>
                            <th style={styles.tableText}>#</th>
                            <th style={styles.tableTextLeft}>Step</th>
                            <th style={styles.tableTextLeft}>Job Position</th>
                            <th style={styles.tableText}>Name</th>
                            <th style={styles.tableText}>Notes</th>
                            <th style={styles.tableText}>Delete</th>
                        </tr>
                    </thead>                        
                    <tbody> 
                        {getMyCareerGrowth()}  
                    </tbody>
                </table>
                </>
            )                                   
        } else if (tabValue === 9 ) {
            return (                
                (usergoals?.length === 0) ? <>{displayNewUserGoal()}</> : 
                <>
                {displayNewUserGoal()}
                <table class="table table-sm caption-top text-left">  
                    <thead>
                        <tr>
                            <th style={styles.tableText}>#</th>
                            <th style={styles.tableTextLeft}>Type</th>
                            <th style={styles.tableTextLeft}>Group</th>
                            <th style={styles.tableTextLeft}>Goal</th>
                            <th style={styles.tableTextLeft}>Metric</th>
                            <th style={styles.tableText}>Assign</th>
                            <th style={styles.tableText}>Due</th>
                            <th style={styles.tableText}>Completed</th>
                            <th style={styles.tableText}>Rating</th>
                            <th style={styles.tableText}>Action</th>
                        </tr>
                    </thead>                        
                    <tbody> 
                        {getMyGoals()}  
                    </tbody>
                </table>
                </>
            )                                   
        }
    }

//     function handleProfileImage(e) {
//         if (e.target.files[0]) {
//             setprofileimage(e.target.files[0]);
//             setimageURL(props.auth.uid + "/ProfileImage." + e.target.files[0].name.split('.').pop());
//         }
//     }

//     function UploadProfileImage() {
//         let stacklen = 1;
//         setLoading(true);
//         try {
//             const pState = {
//                 id:-1,
//                 firstname: firstnameRef.current.value,
//                 lastname: lastnameRef.current.value,
//                 gender: genderRef.current.value,
//                 dateofbirth: firebase.firestore.Timestamp.fromDate(new Date(dateofbirthRef.current.value)),
//                 profileimageurl:profileimageurl,
//             }

//             if (imageURL === '') {
//                 props.updateprofile(props.auth, pState, props.auth.uid)
//                 handleShowMsg();
//                 setLoading(false);
//                 return '';
//             } else {
//                 const storage = getStorage();
//                 stacklen = 2;
//                 const storageRef = ref(storage, imageURL);            
//                 // save to firebase Storage
//                 uploadBytes(storageRef, profileimage).then((snapshot) => {
//                     stacklen = 8;
//                     getDownloadURL(snapshot.ref).then((pdownloadURL) => {
//                         stacklen = 9;
//                         profileimageurl = pdownloadURL;
//                         if ((profileimageurl === '') || (profileimageurl === undefined)) {
//                             console.log('Profile Image Report Error - Upload file missing. ');
//                             return; 
//                         }            
//                         pState.profileimageurl = profileimageurl;
//                         props.updateprofile(props.auth, pState, props.auth.uid)
//                         handleShowMsg();
//                     });
//                     setLoading(false);
//                     return profileimageurl;
//                 })
//             }
//         } catch (error) {
//             console.log('Profile Image Error - ' + stacklen + ' - ' + error);
//             setLoading(false);
//             return profileimageurl;
//         }
//     }    


    function handleSubmit(e) {
        e.preventDefault();
        // if (dateofbirthRef.current.value === '') {
        //     var defaultDate = new Date().toISOString().split("T")[0];
        //     dateofbirthRef.current.value = defaultDate;
        // }

        // UploadProfileImage();
    }

    function getAttributes() {
        return (
                <Table style={styles.tableBG}>
                    <tbody>
                    <tr>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="firstname" style={styles.LabelDataEntryNoPadding}>First Name</label>
                                <input id="firstname" type="text" style={styles.TextDataEntryNoPadding} disabled defaultValue={entity?.FirstName}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="lastname" style={styles.LabelDataEntryNoPadding}>Last Name</label>
                                <input id="lastname" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.LastName}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                        <div className='imput-field'>
                            <label style={styles.LabelDataEntryNoPadding}>Gender</label>
                                <select id="gender" class="browser-default" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.Gender}>
                                    <option value="" disabled >Not Assigned</option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                    <option value="N">Choose not to share</option>
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="firstname" style={styles.LabelDataEntryNoPadding}>Email</label>
                                <input id="firstname" type="email" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.Email}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="employeeid" style={styles.LabelDataEntryNoPadding}>Employee ID</label>
                                <input id="employeeid" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.VendorUserID}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="username" style={styles.LabelDataEntryNoPadding}>User Name</label>
                                <input id="username" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.UserName}/>
                            </div>
                        </td>
                        {/* <td style={{textAlign:'left', width:'20%'}}>
                            <div className='imput-field'>
                                <label htmlFor="profileImage">Profile Image</label>
                                <input id="profileImage" accept=".jpg, .png, .gif" type="file" onChange={handleProfileImage}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'20%'}}>
                            <div className='imput-field'>
                                {((profileimageurl === undefined) || (profileimageurl === "")) ? 
                                    <span class="material-symbols-outlined" style={{color:grey, fontSize:'60px'}}>account_circle</span>
                                    :
                                    <Image style={styles.Image100} src={profileimageurl} border roundedCircle />
                                }                                                
                            </div>
                        </td> */}
                    </tr>
                    <tr>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="datehired" style={styles.LabelDataEntryNoPadding}>Date Hired</label>
                                <input id="datehired" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={moment(entity?.DateHired?.substr(0,10)).format("MM/DD/YYYY")}/>
                            </div>                    
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="daterehired" style={styles.LabelDataEntryNoPadding}>Date Rehired</label>
                                <input id="daterehired" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={(entity?.DateRehired) ? moment(entity?.DateRehired?.substr(0,10)).format("MM/DD/YYYY") : ''}/>
                            </div>                    
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="promotionDate" style={styles.LabelDataEntryNoPadding}>Promotion Date</label>
                                <input id="promotionDate" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={(entity?.PromotionDate) ? moment(entity?.PromotionDate?.substr(0,10)).format("MM/DD/YYYY") : ''}/>
                            </div>                    
                        </td>
                    </tr>
                    <tr>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="dateofbirth" style={styles.LabelDataEntryNoPadding}>Date of Birth</label>
                                <input id="dateofbirth" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={(entity?.DateOfBirth) ? moment(entity?.DateOfBirth?.substr(0,10)).format("MM/DD/YYYY") : ''}/>
                            </div>                    
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="dateoftermination" style={styles.LabelDataEntryNoPadding}>Date of Termination</label>
                                <input id="dateoftermination" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={(entity?.TerminationDate) ? moment(entity?.TerminationDate?.substr(0,10)).format("MM/DD/YYYY") : ''}/>
                            </div>                    
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="updateddate" style={styles.LabelDataEntryNoPadding}>Updated Date</label>
                                <input id="updateddate" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={(entity?.UpdatedDate) ? moment(entity?.UpdatedDate?.substr(0,10)).format("MM/DD/YYYY") : ''}/>
                            </div>                    
                        </td>
                    </tr>
                    <tr>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="employmenttype" style={styles.LabelDataEntryNoPadding}>Employment Type</label>
                                <input id="employeetype" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.EmploymentTypeID}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="badgeid" style={styles.LabelDataEntryNoPadding}>Badge ID</label>
                                <input id="badgeid" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.BadgeID}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="ethnicitytype" style={styles.LabelDataEntryNoPadding}>Ethnicity Type</label>
                                <input id="ethnicitytype" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.EthnicityTypeID}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="region" style={styles.LabelDataEntryNoPadding}>Region</label>
                                <input id="region" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.RegionName}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="facility" style={styles.LabelDataEntryNoPadding}>Facility</label>
                                <input id="facility" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.FacilityName}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="department" style={styles.LabelDataEntryNoPadding}>Department</label>
                                <input id="department" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.DivisionName}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="jobcode" style={styles.LabelDataEntryNoPadding}>Job Code</label>
                                <input id="jobcode" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.JobCodeDescription + '(' + entity?.JobCode + ')'}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="jobclass" style={styles.LabelDataEntryNoPadding}>Job Class</label>
                                <input id="jobclass" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.JobClassDescription + '(' + entity?.JobClass + ')'}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="jobposition" style={styles.LabelDataEntryNoPadding}>Job Position</label>
                                <input id="jobposition" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.JobPositionDescription + '(' + entity?.JobPosition + ')'}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="supervisorlevel" style={styles.LabelDataEntryNoPadding}>Supervisor Level</label>
                                <input id="supervisorlevel" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.SupervisorLevelTypeName}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="leadership" style={styles.LabelDataEntryNoPadding}>Leadership Assessment Level</label>
                                <input id="leadership" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.LeadershipAssessmentLevelID}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="credential" style={styles.LabelDataEntryNoPadding}>Credential</label>
                                <input id="credential" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.CredentialName}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="salarygrade" style={styles.LabelDataEntryNoPadding}>Salary Grade</label>
                                <input id="salarygrade" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.SalaryGradeTypeID}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="speciality" style={styles.LabelDataEntryNoPadding}>Speciality</label>
                                <input id="speciality" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.SpecialityName}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="safetysensitive" style={styles.LabelDataEntryNoPadding}>Safety Sensitive</label>
                                <input id="safetysensitive" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.SafetySensitive}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                    </tr>
                    <tr>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="substatus" style={styles.LabelDataEntryNoPadding}>Sub Status</label>
                                <input id="substatus" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.SubStatus}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="cdlclassfication" style={styles.LabelDataEntryNoPadding}>CDL Classfication</label>
                                <input id="cdlclassfication" type="text" disabled style={styles.TextDataEntryNoPadding} defaultValue={entity?.CDLClassficationTypeID}/>
                            </div>
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div className='imput-field'>
                                <label htmlFor="cdlexpirationdate" style={styles.LabelDataEntryNoPadding}>CDL Expiration Date</label>
                                <input id="cdlexpirationdate" type="date" disabled style={styles.TextDataEntryNoPadding} defaultValue={(entity?.CDLExpirationDate) ? moment(entity?.CDLExpirationDate?.substr(0,10)).format("MM/DD/YYYY") : ''}/>
                            </div>                    
                        </td>
                    </tr>
                    </tbody>
                </Table>                            
        );
    }

    function getMyGroups() {
        if (usergroups.length === 0) {
            return (
                <></>
            );
        } else {
            return usergroups.map((group, groupIndex) => (
                <tr> 
                    <td style={styles.tableTextLeft}>{groupIndex + 1}</td> 
                    <td style={styles.tableTextLeft}>{group?.GroupName}</td> 
                    <td style={styles.tableTextLeft}>{group?.RegionName}</td> 
                    <td style={styles.tableTextLeft}>{group?.FacilityName}</td> 
                    <td style={styles.tableTextLeft}>{group?.DivisionName}</td> 
                    <td style={styles.tableTextLeft}>{group?.JobPositionName}</td> 
                    <td style={styles.tableTextLeft}>{(group?.IsPrimaryDivision === true) ? 'Yes' : 'No'}</td> 
                    <td style={styles.tableTextLeft}>{(group?.Active === true) ? 'Yes' : 'No'}</td> 
                </tr> 
            ));        
        }
    }

    function getMySupervisors() {
        if (usersupervisors.length === 0) {
            return (
                <></>
            );
        } else {
            return usersupervisors.map((supervisor, supIndex) => (
                <tr> 
                    <td style={styles.tableText}>{supIndex + 1}</td> 
                    <td style={styles.tableTextLeft}>{supervisor?.SupervisorFullName}</td> 
                    <td style={styles.tableText}>{supervisor?.SupervisorVendorUserID}</td> 
                    <td style={styles.tableText}>{supervisor?.Email}</td> 
                    <td style={styles.tableText}>{supervisor?.SupervisorWorkPhone}</td> 
                    <td style={styles.tableText}>{supervisor?.DirectLevel}</td> 
                </tr> 
            ));        
        }
    }

    function getMyLicenses() {
        if (userlicenses.length === 0) {
            return (
                <></>
            );
        } else {
            return userlicenses.map((license, supIndex) => (
                <tr> 
                    <td style={styles.tableText}>{supIndex + 1}</td> 
                    <td style={styles.tableTextLeft}>{license?.LicenseTypeName}</td> 
                    <td style={styles.tableText}>{license?.LicenseNumber}</td> 
                    <td style={styles.tableText}>{moment(license?.AcquiredDate?.substr(0,10)).format("MM/DD/YYYY")}</td> 
                    <td style={styles.tableText}>{moment(license?.ExpiryDate?.substr(0,10)).format("MM/DD/YYYY")}</td> 
                    <td style={styles.tableText}>{license?.StateCode}</td> 
                    <td style={styles.tableText}>
                        <div>
                            <a onClick={() => handleDeleteChildernClick(4, (license?.UserLicenseTypeID))}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete License'>delete</span>
                            </a>
                        </div>
                    </td>
                </tr> 
            ));        
        }
    }

    function getMyCredentials() {
        if (usercredentials?.length === 0) {
            return (
                <></>
            );
        } else {
            return usercredentials?.map((license, supIndex) => (
                <tr> 
                    <td style={styles.tableText}>{supIndex + 1}</td> 
                    <td style={styles.tableTextLeft}>{license?.CredentialBodyTypeName}</td> 
                    <td style={styles.tableText}>{license?.LicenseNumber}</td> 
                    <td style={styles.tableText}>{moment(license?.AcquiredDate?.substr(0,10)).format("MM/DD/YYYY")}</td> 
                    <td style={styles.tableText}>{moment(license?.ExpiryDate?.substr(0,10)).format("MM/DD/YYYY")}</td> 
                    <td style={styles.tableText}>{license?.StateCode}</td> 
                    <td style={styles.tableText}>
                        <div>
                            <a onClick={() => handleDeleteChildernClick(5, (license?.UserCredentialBodyID))}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Credential'>delete</span>
                            </a>
                        </div>
                    </td>
                </tr> 
            ));        
        }
    }

    function getMySkills() {
        if (userskills?.length === 0) {
            return (
                <></>
            );
        } else {
            return userskills?.map((skill, supIndex) => (
                <tr> 
                    <td style={styles.tableText}>{supIndex + 1}</td> 
                    <td style={styles.tableTextLeft}>{skill?.SkillName}</td> 
                    <td style={styles.tableText}>{skill?.MetricTypeName}</td> 
                    <td style={styles.tableText}>{skill?.SkillLevelName}</td> 
                    <td style={styles.tableText}>{skill?.Comments}</td> 
                    <td style={styles.tableText}>{(skill?.IsComplete) ? 'Yes' : 'No'}</td> 
                    <td style={styles.tableText}>                    
                        <div>
                            <a onClick={() => handleEditChildernClick(7, (skill?.UserSkillID))}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Skill'>edit</span>
                            </a>
                            <a onClick={() => handleDeleteChildernClick(7, (skill?.UserSkillID))}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Skill'>delete</span>
                            </a>
                        </div>
                    </td>
                </tr> 
            ));        
        }
    }

    function getMyGoals() {
        if (usergoals?.length === 0) {
            return (
                <></>
            );
        } else {
            return usergoals?.map((goal, supIndex) => (
                <tr> 
                    <td style={styles.tableText}>{supIndex + 1}</td> 
                    <td style={styles.tableTextLeft}>{goal?.EvaluationEntityTypeName}</td> 
                    <td style={styles.tableTextLeft}>{goal?.EvaluationQuestionGroupName}</td> 
                    <td style={styles.tableTextLeft}>{goal?.EvaluationQuestion}</td> 
                    <td style={styles.tableTextLeft}>{goal?.Metric}</td> 
                    <td style={styles.tableText}>{moment(goal?.AssignedDate?.substr(0,10)).format("MM/DD/YYYY")}</td> 
                    <td style={styles.tableText}>{moment(goal?.DueDate?.substr(0,10)).format("MM/DD/YYYY")}</td> 
                    <td style={styles.tableText}>{goal?.IsPublished ? 'Yes' : 'No'}</td> 
                    <td style={styles.tableText}>{goal?.EvaluationRatingTypeName}</td> 
                    <td style={styles.tableText}>
                        <div>
                            <a onClick={() => handleDeleteChildernClick(9, (goal?.UserEvaluationSessionID + '|' + goal?.EvaluationQuestionID + '|' + goal?.EvaluationRatingTypeID))}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Goal'>delete</span>
                            </a>
                        </div>
                    </td>
                </tr> 
            ));        
        }
    }

    function getRequirementName(requirement) {
        let details = '';
        if (requirement?.LicenseTypeName) {
            details = requirement?.LicenseTypeName;
        } else if (requirement?.CertificationName) {
            details = requirement?.CertificationName;
        } else if (requirement?.EducationTypeName) {
            details = requirement?.EducationTypeName;
        } else if (requirement?.ExperienceTypeName) {
            details = requirement?.ExperienceTypeName;
        }

        return details;
    }

    function getRequirementDetail(requirement) {
        let details = '';
        if (requirement?.EducationTypeName) {
            details = (requirement?.MinimumYears) ? 'Min. Years - ' + requirement?.MinimumYears : '';
            details += (requirement?.MaximumYears) ? ' Max. Years - ' + requirement?.MaximumYears : '';
        } else if (requirement?.ExperienceTypeName) {
            details = (requirement?.MinimumYears) ? 'Min. Years - ' + requirement?.MinimumYears : '';
            details += (requirement?.MaximumYears) ? ' Max. Years - ' + requirement?.MaximumYears : '';
        }

        return details;
    }

    function getMyRequirements() {
        if (userjobpositionrequirement.length === 0) {
            return (
                <></>
            );
        } else {
            return userjobpositionrequirement.map((requirement, supIndex) => (
                <tr> 
                    <td style={styles.tableText}>{supIndex + 1}</td> 
                    <td style={styles.tableTextLeft}>{requirement?.JobPositionRequirementTypeName}</td> 
                    <td style={styles.tableTextLeft}>{getRequirementName(requirement)}</td> 
                    <td style={styles.tableTextLeft}>{requirement?.EvaluationRatingTypeName}</td> 
                    <td style={styles.tableTextLeft}>{getRequirementDetail(requirement)}</td> 
                    <td style={styles.tableText}>
                        <div>
                            <a onClick={() => handleDeleteChildernClick(6, (requirement?.UserJobPositionRequirementID))}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Job Position Requirement'>delete</span>
                            </a>
                        </div>
                    </td>
                </tr> 
            ));        
        }
    }

    function getMyCareerGrowth() {
        if (userfuturejobposition.length === 0) {
            return (
                <></>
            );
        } else {
            return userfuturejobposition.map((jobPosition, supIndex) => (
                <tr> 
                    <td style={styles.tableText}>{supIndex + 1}</td> 
                    <td style={styles.tableTextLeft}>{jobPosition?.CareerStep}</td> 
                    <td style={styles.tableTextLeft}>{jobPosition?.JobPosition}</td> 
                    <td style={styles.tableText}>{jobPosition?.JobPositionDescription}</td> 
                    <td style={styles.tableText}>{jobPosition?.UserFutureJobPositionDescription}</td> 
                    <td style={styles.tableText}>
                        <div>
                            <a onClick={() => handleDeleteChildernClick(8, (jobPosition?.UserFutureJobPositionID))}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Career Step'>delete</span>
                            </a>
                        </div>
                    </td>
                </tr> 
            ));        
        }
    }

    function displayMainProfileContainer() {
        return (
            <>
            <Modal style={{background:'transparent'}} show={showMsg} backdrop="static" size="sm" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseMsg}>
                <Modal.Header closeButton>
                <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>Profile saved successfully!</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleCloseMsg}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal style={{background:'transparent', maxHeight:'100%'}} show={showJPList} onShow={handleShowJPList} fullscreen backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseJPList}>
                <Modal.Header closeButton>
                <Modal.Title>Lookup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <JobPositions fromLookupParam={true}/>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleCloseJPList}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                        <Card.Header className='text-center'> 
                            <Card.Title>
                                <div>
                                    <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <tbody>
                                        <tr>
                                            <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>{entity?.LastName}, {entity?.FirstName}'s Profile</h3></td>
                                            {/* <td style={{textAlign:'right', width:'15%'}}><Button disabled={loading} type='submit' style={styles.CDButton50}>Save</Button></td> */}
                                            {/* <td style={{textAlign:'center', width:'15%'}}><Button style={styles.CDButton50} onClick={() => navigate("/")}>Cancel</Button></td> */}
                                        </tr>
                                        </tbody>
                                    </Table>                            
                                </div>
                            </Card.Title>
                        </Card.Header>         
                        {(loading) ? (            
                            <Card.Body>
                                <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                    <Spinner/>
                                </div>
                            </Card.Body>
                        ) 
                        : 
                        (                                          
                            <Card.Body>
                                <Table responsive="sm" style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                    <tbody>
                                    <tr>
                                        <td style={{textAlign:'left', width:'80%', backgroundColor:companyData?.PagesBGColor}}>
                                            <div style={{display:'flex', flexWrap:'wrap'}}>
                                                {displayTabButtons()}
                                            </div>
                                        </td>
                                        <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                                            {displayAddButtons()}
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>                            
                                {displayTabContent()}
                            </Card.Body>
                        )}
                        <Card.Footer className="text-left">
                        </Card.Footer>
                </Card>
            </form>
            </>                                
        );
    }


    if (fromUserList) {
        return (
            <>
                {displayMainProfileContainer()}
            </>
        )
    } else {
        return (
            <div style={styles.MainInnerDiv}>
                <div style={styles.DashboardContainer}>
                    {displayMainProfileContainer()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      mygroups: state.auth.userDetail.UserGroups,
      mycredentials: state.auth.userDetail.UserCredential, 
      mycustomfields: state.auth.userDetail.UserFields, 
      myfuturejobposition: state.auth.userDetail.UserFutureJobPosition, 
      myjobpositionrequirement: state.auth.userDetail.UserJobPositionRequirements, 
      mylicenses: state.auth.userDetail.UserLicense, 
      myskills: state.auth.userDetail.UserSkill, 
      mysupervisors: state.auth.userDetail.UserSupervisor, 
      mygoals: state.auth.userDetail.UserGoal, 
      userattributelookups: state.entity.userattributelookups,
      jobpositions: state.entity.jobpositions,
      lookupselections: state.entity.lookupselections,
      selectedProfile: state.auth.selectedProfile,
      loadingMenu: state.auth.loadingMenu,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertUserLicense: (licParams) => dispatch(insertUserLicense(licParams)),
        deleteUserLicense: (licParams) => dispatch(deleteUserLicense(licParams)),
        getUserLicenses: (id, selectedUser) => dispatch(getUserLicenses(id, selectedUser)),
        insertUserCredential: (credParams) => dispatch(insertUserCredential(credParams)),
        deleteUserCredential: (credParams) => dispatch(deleteUserCredential(credParams)),
        getUserCredentials: (id, selectedUser) => dispatch(getUserCredentials(id, selectedUser)),
        insertUserGoal: (oParams) => dispatch(insertUserGoal(oParams)),
        deleteUserGoal: (oParams) => dispatch(deleteUserGoal(oParams)),
        getUserGoals: (id, selectedUser) => dispatch(getUserGoals(id, selectedUser)),
        insertUserSkill: (skillParams) => dispatch(insertUserSkill(skillParams)),
        updateUserSkill: (skillParams) => dispatch(updateUserSkill(skillParams)),
        deleteUserSkill: (skillParams) => dispatch(deleteUserSkill(skillParams)),
        getUserSkills: (id, selectedUser) => dispatch(getUserSkills(id, selectedUser)),
        insertUserJobPositionRequirement: (oParams) => dispatch(insertUserJobPositionRequirement(oParams)),
        deleteUserJobPositionRequirement: (oParams) => dispatch(deleteUserJobPositionRequirement(oParams)),
        getUserJobPositionRequirements: (id, selectedUser) => dispatch(getUserJobPositionRequirements(id, selectedUser)),
        insertUserFutureJobPosition: (oParams) => dispatch(insertUserFutureJobPosition(oParams)),
        deleteUserFutureJobPosition: (oParams) => dispatch(deleteUserFutureJobPosition(oParams)),
        getUserFutureJobPositions: (id, selectedUser) => dispatch(getUserFutureJobPositions(id, selectedUser)),
        getJobPositions: () => dispatch(getJobPositions()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile)