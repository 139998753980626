import React, { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import { connect } from 'react-redux';
import { styles } from '../layout/styles';
import { Image, Dropdown, NavItem, NavDropdown } from 'react-bootstrap';
import { assignActiveMenu, loadingMenuSelection, signOut, getOrgDivisions, getReports, getMyTeamFull, getDivisionManagers, getOrgJobClasses } from '../../store/actions/authActions';
import { getEvaluations, getAssignments, getJobPositions, clearEntityState, getGoals, getCompetencies, getSkills, getSkillChecklist } from '../../store/actions/entityActions';
import { ThirdPartyLMSEvaluations, RetentasSurveys, MenuItemHome, MenuItemQuestionnaire, 
        MenuItemPerformance, MenuItemMyTeam, MenuItemPillars, MenuItemAssignments, 
        MenuItemReports, MenuItemProfile, MenuItemLogout, MenuItemRatings, MenuItemDivisions, MenuItemJobPositions, MenuItemGoals, MenuItemCompetencies, MenuItemCompetencyChecklist,
        MenuItemJobClassTree, 
        CheckListGroupByAll } from '../../config/Constant';
import Spinner from '../layout/Spinner';
import { CompanyData } from '../../config/Colors';
// import { companyLogo, companyNameLogo, companySignLogo } from '../../config/Colors';


const Navbar = (props) => {
    const { auth, profile, currentActiveMenuItem, companyID, companyData } = props;
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    async function handleLogout(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuItemLogout);
        await props.clearEntityState();
        await props.signOut();
        if ((companyData?.CompanyADIntegrated) && (companyData?.IDPService != '') && (companyData?.CompanyLogoutPage != '')) {
            props.loadingMenuSelection(false);
            // organization home/login/logout page
            window.location.href = companyData?.CompanyLogoutPage;        
        } else {
            navigate('/Login/' + companyID);
            props.loadingMenuSelection(false);
        }
        setLoading(false);
    }  

    async function handleDashboard(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuItemHome);
        navigate('/');
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleQuestionnaires(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuItemQuestionnaire);
        navigate('/questionnaires', {state: {processleveltypeid:RetentasSurveys}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handlePerformance(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuItemPerformance);
        navigate('/performance', {state: {processleveltypeid:ThirdPartyLMSEvaluations}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleTeam(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuItemMyTeam);
        const oParams = {
            UserID:auth?.UserID,
            ScoredProbabilities:'AtRisk',
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getMyTeamFull(oParams);
        navigate('/myTeam');
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handlePillars(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        let companyPillars = '';
        const companyParameters = auth.UserCompanyParameters?.split('&');
        companyParameters.map((parameter) => {
            const parameterDetails = parameter?.split('=');
            if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
                companyPillars = parameterDetails[1];
            }
        });
        props.assignActiveMenu(MenuItemPillars);
        const evalParams  = {
            UserID:auth.UserID,
            EvaluationIDs:companyPillars,
            SearchFilter:'',
        };        
        console.log(evalParams);
        await props.getEvaluations(evalParams);
        navigate('/evaluations');
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleGoals(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuItemGoals);
        const evalParams  = {
            UserID:auth.UserID,
            SearchFilter:'',
        };        
        await props.getGoals(evalParams);
        navigate('/goals');
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleCompetencies(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuItemCompetencies);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getSkills(oParams);
        navigate('/competencies', {state: {fromLookup:false}});
        
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleCompetencyChecklist(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuItemCompetencyChecklist);
        const oParams = {
            CheckListGroupBy:CheckListGroupByAll,
            UserID:auth.UserID,
            IsComplete:'false',
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getSkillChecklist(oParams);
        navigate('/competencychecklist', {state: {listby:CheckListGroupByAll}});
        
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleRatings(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuItemRatings);
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleAssignments(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        let companyPillars = '';
        const companyParameters = auth.UserCompanyParameters?.split('&');
        companyParameters.map((parameter) => {
            const parameterDetails = parameter?.split('=');
            if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
                companyPillars = parameterDetails[1];
            }
        });
        props.assignActiveMenu(MenuItemAssignments);
        const evalParams  = {
            UserID:auth.UserID,
            EvaluationIDs:companyPillars,
        };        
        await props.getOrgDivisions(auth?.UserID);
        await props.getAssignments(evalParams);
        const goalParams  = {
            UserID:auth.UserID,
            SearchFilter:'',
        };        
        await props.getGoals(goalParams);
        navigate('/assignments');
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleDivisions(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuItemDivisions);
        await props.getOrgDivisions(auth?.UserID);
        // await props.getDivisionManagers(31449);
        navigate('/divisions');
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleJobPositions(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuItemJobPositions);
        const jpParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getJobPositions(jpParams);
        await props.getOrgJobClasses(auth?.UserID);
        navigate('/jobpositions', {state: {fromLookup:false}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleJobClassTree(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuItemJobClassTree);
        await props.getOrgJobClasses(auth?.UserID);
        navigate('/jobclasstree', {state: {fromLookup:false}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleReports(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuItemReports);
        console.log(auth?.UserID);
        await props.getReports(auth?.UserID);
        navigate('/reports');
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleProfile(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuItemProfile);
        navigate('/updateProfile');
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    function displayPillarsSubMenu(){
        return (
            <>
                {(currentActiveMenuItem === MenuItemPillars) ? 
                    <NavDropdown.Item eventKey="4.1" disabled='true' style={styles.NavDropdownItemSelected}><label>Pillars</label></NavDropdown.Item>
                :
                    <NavDropdown.Item eventKey="4.1"><a style={styles.NavDropdownItemUnSelected} onClick={handlePillars}>Pillars</a></NavDropdown.Item>
                }
            </>
        );    
    }

    function displayGoalsSubMenu(){
        return (
            <>
                {(currentActiveMenuItem === MenuItemGoals) ? 
                    <NavDropdown.Item eventKey="4.3" disabled='true' style={styles.NavDropdownItemSelected}><label>Goals</label></NavDropdown.Item>
                :
                    <NavDropdown.Item eventKey="4.3"><a style={styles.NavDropdownItemUnSelected} onClick={handleGoals}>Goals</a></NavDropdown.Item>
                }
            </>
        );    
    }

    function displayCompetenciesSubMenu(){
        return (
            <>
                {(currentActiveMenuItem === MenuItemCompetencies) ? 
                    <NavDropdown.Item eventKey="4.2" disabled='true' style={styles.NavDropdownItemSelected}><label>Competencies</label></NavDropdown.Item>
                :
                    <NavDropdown.Item eventKey="4.2"><a style={styles.NavDropdownItemUnSelected} onClick={handleCompetencies}>Competencies</a></NavDropdown.Item>
                }
            </>
        );    
    }

    function displayAssignmentsSubMenu(){
        return (
            <>
                {(currentActiveMenuItem === MenuItemAssignments) ? 
                    <NavDropdown.Item eventKey="4.2" disabled='true' style={styles.NavDropdownItemSelected}><label>Assignments</label></NavDropdown.Item>
                :
                    <NavDropdown.Item eventKey="4.2"><a style={styles.NavDropdownItemUnSelected} onClick={handleAssignments}>Assignments</a></NavDropdown.Item>
                }
            </>
        );    
    }

    function displayCompetencyChecklistSubMenu(){
        return (
            <>
            {(currentActiveMenuItem === MenuItemCompetencyChecklist) ? 
                <NavDropdown.Item eventKey="4.11" disabled='true' style={styles.NavDropdownItemSelected}><label>Checklist</label></NavDropdown.Item>
            :
                <NavDropdown.Item eventKey="4.11"><a style={styles.NavDropdownItemUnSelected} onClick={handleCompetencyChecklist}>Checklist</a></NavDropdown.Item>
            }
            </>
        );    
    }

    function displayElementsMenu() {
        var mainSelectedClassName = '';
        if ((currentActiveMenuItem === MenuItemPillars) || (currentActiveMenuItem === MenuItemGoals) ||
            (currentActiveMenuItem === MenuItemCompetencies) || (currentActiveMenuItem === MenuItemCompetencyChecklist) ||
            (currentActiveMenuItem === MenuItemAssignments)) {
            mainSelectedClassName = 'mainnav-primary-item-selected';
        }
        if ((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) {
            return (
                <NavDropdown title='Elements' className={mainSelectedClassName} id="mainnav-dropdown">
                {displayPillarsSubMenu()}
                {displayGoalsSubMenu()}
                {displayCompetenciesSubMenu()}
                {displayAssignmentsSubMenu()}
                <NavDropdown.Divider />
                {displayCompetencyChecklistSubMenu()}
                </NavDropdown>                                    
            );    
        } else {
            return (<></>);
        }
    }

    function displayOrganizationMenu() {
        var mainSelectedClassName = '';
        if ((currentActiveMenuItem === MenuItemDivisions) || (currentActiveMenuItem === MenuItemJobPositions)) {
            mainSelectedClassName = 'mainnav-primary-item-selected';
        }
        if ((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) {
            return (
                <NavDropdown title='Organization' className={mainSelectedClassName} id="mainnav-dropdown">
                    {(currentActiveMenuItem === MenuItemDivisions) ? 
                        <NavDropdown.Item eventKey="6.1" disabled='true' style={styles.NavDropdownItemSelected}><label>Divisions</label></NavDropdown.Item>
                    :
                        <NavDropdown.Item eventKey="6.1"><a style={styles.NavDropdownItemUnSelected} onClick={handleDivisions}>Divisions</a></NavDropdown.Item>
                    }
                    {(currentActiveMenuItem === MenuItemJobPositions) ? 
                        <NavDropdown.Item eventKey="6.2" disabled='true' style={styles.NavDropdownItemSelected}><label>Job Positions</label></NavDropdown.Item>
                    :
                        <NavDropdown.Item eventKey="6.2"><a style={styles.NavDropdownItemUnSelected} onClick={handleJobPositions}>Job Positions</a></NavDropdown.Item>
                    }
                    {(currentActiveMenuItem === MenuItemJobClassTree) ? 
                        <NavDropdown.Item eventKey="6.2" disabled='true' style={styles.NavDropdownItemSelected}><label>Job Class Tree</label></NavDropdown.Item>
                    :
                        <NavDropdown.Item eventKey="6.2"><a style={styles.NavDropdownItemUnSelected} onClick={handleJobClassTree}>Job Class Tree</a></NavDropdown.Item>
                    }
                </NavDropdown>                                    
            );    
        } else {
            return (<></>);
        }
    }

    if (!auth?.UserID) {
        return (
            <>
            </>
        )
    } else {
        const links = auth.UserID ? <SignedInLinks profile={profile}/> : <SignedOutLinks/>;
        return (
            <>
                <div style={styles.NavContainer}>
                    <div style={styles.NavInsideDiv}>
                        {/* <h2 style={styles.NavHeader}>Welcome {profile?.FirstName} {profile?.LastName}</h2> */}
                    </div>
                    <div style={styles.NavInsideDiv}>
                        {/* <h2 style={styles.ProductNameHeader}>OBSERVATIS</h2> */}
                        {/* <Image style={{width:'31px', height:'35px'}} src={companySignLogo}/> */}
                            <span>
                                <h1 style={styles.Header}><Image style={{width:'226px', height:'38px'}} src={companyData?.CompanyNameLogo}/></h1>
                            </span>
                    </div>
                    <div style={styles.NavInsideDiv}>
                        {/* { links } */}
                    </div>
                </div>

                <nav> 
                    <div class="nav-content">
                        <span>{profile?.FirstName} {profile?.LastName}</span>
                        
                        {/* <div class="nav-icon">
                            <div class="bar one">One</div>
                            <div class="bar two">Two</div>
                        </div> */}

                        <div class="nav-links">
                            {(currentActiveMenuItem === MenuItemProfile) ? 
                                <label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                    </svg> 
                                </label>                           
                                : 
                                <a onClick={handleProfile}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                    </svg>                            
                                </a>
                            }

                            {((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) ?
                                (currentActiveMenuItem === MenuItemHome) ? <label>Home</label> : <a onClick={handleDashboard}>Home</a>
                                :
                                <></>
                            }
                            {(currentActiveMenuItem === MenuItemQuestionnaire) ? <label>Questionnaires</label> : <a onClick={handleQuestionnaires}>Questionnaires</a>}
                            {(currentActiveMenuItem === MenuItemPerformance) ? <label>Performance</label> : <a onClick={handlePerformance}>Performance</a>}
                            {((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) ?
                                (currentActiveMenuItem === MenuItemMyTeam) ? <label>Team</label> : <a onClick={handleTeam}>Team</a>
                                :
                                <></>
                            }
                            {displayElementsMenu()}
                            {/* {((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) ?
                                (currentActiveMenuItem === MenuItemPillars) ? <label>Pillars</label> : <a onClick={handlePillars}>Pillars</a>
                                :
                                <></>
                            }
                            {((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) ?
                                (currentActiveMenuItem === MenuItemGoals) ? <label>Goals</label> : <a onClick={handleGoals}>Goals</a>
                                :
                                <></>
                            }
                            {((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) ?
                                (currentActiveMenuItem === MenuItemAssignments) ? <label>Assignments</label> : <a onClick={handleAssignments}>Assignments</a>
                                :
                                <></>
                            } */}
                            {displayOrganizationMenu()}
                            {/* {((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) ?
                                (currentActiveMenuItem === MenuItemDivisions) ? <label>Divisions</label> : <a onClick={handleDivisions}>Divisions</a>                            
                                :
                                <></>
                            }
                            {((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) ?
                                (currentActiveMenuItem === MenuItemJobPositions) ? <label>Job Positions</label> : <a onClick={handleJobPositions}>Job Positions</a>
                                :
                                <></>
                            } */}
                            {/* {displayCompetenciesMenu()} */}
                            {/* {((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) ?
                                (currentActiveMenuItem === MenuItemCompetencies) ? 
                                    <label>Competencies</label> 
                                    : 
                                    <>
                                    <a onClick={handleCompetencies}>Competencies</a>
                                    </>
                                :
                                <></>
                            } */}
                            {((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) ?
                                (currentActiveMenuItem === MenuItemReports) ? <label>Reports</label> : <a onClick={handleReports}>Reports</a>
                                :
                                <></>
                            }
                            {/* {(currentActiveMenuItem === MenuItemRatings) ? <label>Ratings</label> : <a onClick={handleRatings}>Ratings</a>}                             */}

                            {(currentActiveMenuItem === MenuItemLogout) ? 
                                <label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                                        <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                                    </svg> 
                                </label>                           
                                : 
                                <a onClick={handleLogout}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                                            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                                    </svg>                            
                                </a>
                            }
                        </div>

                    </div>
                </nav>                
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state?.auth?.user,
        profile: state?.auth?.profile,
        currentActiveMenuItem: state?.auth?.activeMenuItem,
        companyID: state?.auth?.companyID,
        companyData: state?.auth?.companyData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        clearEntityState: () => dispatch(clearEntityState()),
        assignActiveMenu: (menuId) => dispatch(assignActiveMenu(menuId)),
        loadingMenuSelection: (isLoading) => dispatch(loadingMenuSelection(isLoading)),
        getOrgDivisions: (id) => dispatch(getOrgDivisions(id)),
        getEvaluations: (evalParams) => dispatch(getEvaluations(evalParams)),
        getAssignments: (evalParams) => dispatch(getAssignments(evalParams)),
        getReports: (id) => dispatch(getReports(id)),
        getJobPositions: (jpParams) => dispatch(getJobPositions(jpParams)),
        getMyTeamFull: (oParams) => dispatch(getMyTeamFull(oParams)),
        getDivisionManagers: (id) => dispatch(getDivisionManagers(id)),
        getGoals: (evalParams) => dispatch(getGoals(evalParams)),
        getCompetencies: (oParams) => dispatch(getCompetencies(oParams)),
        getSkills: (oParams) => dispatch(getSkills(oParams)),
        getSkillChecklist: (oParams) => dispatch(getSkillChecklist(oParams)),
        getOrgJobClasses: (id) => dispatch(getOrgJobClasses(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
