let sCompanyID=-1;
let sBlack='#000000'
let sWhite='#ffffff'
let sDarkgrey='#484848'
let sGrey='#888888'
let sLightgrey='#f2f3f4'
let sRed='#D32528'
let sBlue='#1E90FF'
let sLogin_bg_color='#F3F8FF'
let sHeader_color='#4172B6'
let sSubscription_bg_color='#F3F8FF'
let sHeader_title_color='#4F79B4'
let sCDfontFamily='Lato, sans-serif'
let sStronglyDisagreeColor='#ff4545'
let sDisagreeColor='#ffa534'
let sNeutralColor='#ffe234'
let sAgreeColor='	#b7dd29'
let sStronglyAgreeColor='#57e32c'
let sSmileyNotGood='/images/not-good.svg'
let sAccentColor='#E71D37'
let sAccentColorLighter='#fa5570'
let sAccentColorSecondary='#7498fc'
let sLoginBGImage='/images/LAObservatory.jpg'
let sCDLabelColor='#ffffff'
let sCDTextColor='#ffffff'
let sNavbarcolor='#FE6236'
let sNavbartextcolor='#484848'
let sPagesbgcolor='#e6eefa'
let sCompanyLogo='/ObservatisWLogo.png'
let sCompanyNameLogo='/ObservatisWName.png'
let sCompanySignLogo='/ObservatisWSign.png'

const Colors = (companyData) => {
        try {
            // console.log(companyData);
            if (companyData) {
                sCompanyID=companyData?.CompanyID;
                sBlack=companyData?.Black;
                sWhite=companyData?.White;
                sDarkgrey=companyData?.Darkgrey;
                sGrey=companyData?.Grey;
                sLightgrey=companyData?.Lightgrey;
                sRed=companyData?.Red;
                sBlue=companyData?.Blue;
                sLogin_bg_color=companyData?.Login_bg_color;
                sHeader_color=companyData?.Header_color;
                sSubscription_bg_color=companyData?.Subscription_bg_color;
                sHeader_title_color=companyData?.Header_title_color;
                sCDfontFamily=companyData?.CDfontFamily;
                sStronglyDisagreeColor=companyData?.StronglyDisagreeColor;
                sDisagreeColor=companyData?.DisagreeColor;
                sNeutralColor=companyData?.NeutralColor;
                sAgreeColor=companyData?.AgreeColor;
                sStronglyAgreeColor=companyData?.StronglyAgreeColor;
                sSmileyNotGood=companyData?.SmileyNotGood;
                sAccentColor=companyData?.AccentColor;
                sAccentColorLighter=companyData?.AccentColorLighter;
                sAccentColorSecondary=companyData?.AccentColorSecondary;
                sLoginBGImage=companyData?.LoginBGImage;
                sCDLabelColor=companyData?.CDLabelColor;
                sCDTextColor=companyData?.CDTextColor;
                sNavbarcolor=companyData?.NavBarColor;
                sNavbartextcolor=companyData?.NavBarTextColor;
                sPagesbgcolor=companyData?.PagesBGColor;
                sCompanyLogo=companyData?.CompanyLogo;        
                sCompanyNameLogo=companyData?.CompanyNameLogo;        
                sCompanySignLogo=companyData?.CompanySignLogo;        
            }

            // Option 1 Healthcare - white - Very Good
            // sLoginBGImage='/images/Healthcare13.jpg';
            // sCDLabelColor='#404040';
            // sCDTextColor='#303030';
            // sNavbarcolor='#505050';
            // sNavbartextcolor='#404040';
        
            document.documentElement.style.setProperty('--content-color', sNavbartextcolor);
            document.documentElement.style.setProperty('--accent-color', sAccentColor);
            document.documentElement.style.setProperty('--login-bg-image', `url(${process.env.PUBLIC_URL + sLoginBGImage})`);
            document.documentElement.style.setProperty('--black', sBlack);
            document.documentElement.style.setProperty('--white', sWhite);
            document.documentElement.style.setProperty('--darkgrey', sDarkgrey);
            document.documentElement.style.setProperty('--grey', sGrey);
            document.documentElement.style.setProperty('--lightgrey', sLightgrey);
            document.documentElement.style.setProperty('--red', sRed);
            document.documentElement.style.setProperty('--blue', sBlue);
            document.documentElement.style.setProperty('--login_bg_color', sLogin_bg_color);
            document.documentElement.style.setProperty('--header_color', sHeader_color);
            document.documentElement.style.setProperty('--subscription_bg_color', sSubscription_bg_color);
            document.documentElement.style.setProperty('--header_title_color', sHeader_title_color);
            document.documentElement.style.setProperty('--cd-font-Family', sCDfontFamily);
            document.documentElement.style.setProperty('--strongly-disagree-color', sStronglyDisagreeColor);
            document.documentElement.style.setProperty('--disagree-color', sDisagreeColor);
            document.documentElement.style.setProperty('--neutral-color', sNeutralColor);
            document.documentElement.style.setProperty('--agree-color', sAgreeColor);
            document.documentElement.style.setProperty('--strongly-agree-color', sStronglyAgreeColor);
            document.documentElement.style.setProperty('--smiley-not-good', sSmileyNotGood);
            document.documentElement.style.setProperty('--accent-color', sAccentColor);
            document.documentElement.style.setProperty('--accent-color-lighter', sAccentColorLighter);
            document.documentElement.style.setProperty('--accent-color-secondary', sAccentColorSecondary);
            document.documentElement.style.setProperty('--login-bg-image', `url(${process.env.PUBLIC_URL + sLoginBGImage})`);
            document.documentElement.style.setProperty('--cd-label-color', sCDLabelColor);
            document.documentElement.style.setProperty('--cd-text-color', sCDTextColor);
            document.documentElement.style.setProperty('--navbar-color', sNavbarcolor);
            document.documentElement.style.setProperty('--navbar-text-color', sNavbartextcolor);
            document.documentElement.style.setProperty('--pages-bg-color', sPagesbgcolor);
            document.documentElement.style.setProperty('--company-logo', sCompanyLogo);
            document.documentElement.style.setProperty('--company-name-logo', sCompanyNameLogo);
            document.documentElement.style.setProperty('--company-sign-logo', sCompanySignLogo);        
        }
        catch (error) {
            console.log('Error in setting company parameters - ' + error);
        }
}

export default Colors;

export const CompanyData=Colors?.companyData;
export const CompanyID=sCompanyID;
export const black=Colors?.Black;
export const white=Colors?.White;
export const darkgrey=Colors?.Darkgrey;
export const grey=Colors?.Grey;
export const lightgrey=Colors?.Lightgrey;
export const red=Colors?.Red;
export const blue=Colors?.Blue;
export const login_bg_color=Colors?.Login_bg_color;
export const header_color=Colors?.Header_color;
export const subscription_bg_color=Colors?.Subscription_bg_color;
export const header_title_color=Colors?.Header_title_color;
export const cdfontFamily=Colors?.CDfontFamily;
export const StronglyDisagreeColor=Colors?.StronglyDisagreeColor;
export const DisagreeColor=Colors?.DisagreeColor;
export const NeutralColor=Colors?.NeutralColor;
export const AgreeColor=Colors?.AgreeColor;
export const StronglyAgreeColor=Colors?.StronglyAgreeColor;
export const smileyNotGood=Colors?.SmileyNotGood;

// Option 1 LA Observatory - Blue Sky, green and white - Very Good
export const accentColor=Colors?.AccentColor;
export const accentColorLighter=Colors?.AccentColorLighter;
export const accentColorSecondary=Colors?.AccentColorSecondary;
export const loginBGImage=sLoginBGImage;
export const cdLabelColor=Colors?.CDLabelColor;
export const cdtextcolor=Colors?.CDTextColor;
export const navbarcolor=Colors?.NavBarColor;
export const navbartextcolor=Colors?.NavBarTextColor;
export const pagesbgcolor=Colors?.PagesBGColor;
// export const companyLogo=sCompanyLogo;
export const companyLogo=sCompanyLogo;
export const companyNameLogo=Colors?.CompanyNameLogo;
export const companySignLogo=Colors?.CompanySignLogo;

// Option 2 TelescopeBW 
// export const accentColor='#E71D37'
// export const accentColorLighter='#fa5570'
// export const accentColorSecondary='#7498fc'
// export const loginBGImage='/images/TelescopeCity1.jpg'
// export const cdLabelColor='#000000'
// export const cdtextcolor='#000000'
// export const navbarcolor='#FE6236'
// export const navbartextcolor='#ffffff'
// export const pagesbgcolor='#EFF5F8'

// Option 3 Observatory 2
// export const accentColor='#E71D37'
// export const accentColorLighter='#fa5570'
// export const accentColorSecondary='#7498fc'
// export const loginBGImage='/images/Observatory2.jpg'
// export const cdLabelColor='#ffffff'
// export const cdtextcolor='#ffffff'
// export const navbarcolor='#FE6236'
// export const navbartextcolor='#ffffff'
// export const pagesbgcolor='#EFF5F8'

// Option 3 Observatory 3 - Good
// export const accentColor='#E71D37'
// export const accentColorLighter='#fa5570'
// export const accentColorSecondary='#7498fc'
// export const loginBGImage='/images/Observatory3.jpg'
// export const cdLabelColor='#ffffff'
// export const cdtextcolor='#ffffff'
// export const navbarcolor='#FE6236'
// export const navbartextcolor='#ffffff'
// export const pagesbgcolor='#EFF5F8'

// Option 3 Observatory 6 
// export const accentColor='#E71D37'
// export const accentColorLighter='#fa5570'
// export const accentColorSecondary='#7498fc'
// export const loginBGImage='/images/Observatory6.jpg'
// export const cdLabelColor='#000000'
// export const cdtextcolor='#000000'
// export const navbarcolor='#FE6236'
// export const navbartextcolor='#ffffff'
// export const pagesbgcolor='#EFF5F8'

// Option 3 TelescopeParis1 
// export const accentColor='#E71D37'
// export const accentColorLighter='#fa5570'
// export const accentColorSecondary='#7498fc'
// export const loginBGImage='/images/TelescopeParis1.jpg'
// export const cdLabelColor='#000000'
// export const cdtextcolor='#000000'
// export const navbarcolor='#FE6236'
// export const navbartextcolor='#ffffff'
// export const pagesbgcolor='#EFF5F8'

// Option 3 UtahGovt 
// export const accentColor='#E71D37'
// export const accentColorLighter='#fa5570'
// export const accentColorSecondary='#7498fc'
// export const loginBGImage='/images/CapitalBldg.jpg'
// export const cdLabelColor='#ffffff'
// export const cdtextcolor='#ffffff'
// export const navbarcolor='#FE6236'
// export const navbartextcolor='#ffffff'
// export const pagesbgcolor='#EFF5F8'


// Option 4 Kids play 
// export const accentColor='#E71D37'
// export const accentColorLighter='#fa5570'
// export const accentColorSecondary='#7498fc'
// export const loginBGImage='/images/CDKidsPlayBG.png'
// export const cdtextcolor='#ffffff'
// export const navbarcolor='#FE6236'
// export const navbartextcolor='#ffffff'
// export const pagesbgcolor='#EFF5F8'

// Option 5 Parent 

// Option 6 Mountains 

// Option 7 Leaf 1 

// Option 8 Leaf 2 
