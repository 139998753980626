import * as actionTypes from "./actionTypes"; 
import RetentasAPI from "../../api/RetentasAPI";
import RetentasMLAPI from "../../api/RetentasMLAPI";

export const getUserEvaluationSessions = (id) => {
    return async (dispatch) => {

        try {
            // console.log(process.env.REACT_APP_RETENTASAPI_GETUSEREVALUATIONSESSIONS + "?id=" + id);
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETUSEREVALUATIONSESSIONS + "?id=" + id)
            // console.log(resp.data);
            const userevaluationsessions = resp.data;
            dispatch( {type: actionTypes.GETUSEREVALUATIONSESSION_SUCCESS, payload: {userevaluationsessions} } );
        } catch (error) {
            console.log('Error in getUserEvaluationSession', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSEREVALUATIONSESSION_ERROR, payload: error } );
        }        
    }
}

export const getUserEvaluationSessionResponses = (id) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETUSEREVALUATIONSESIONRESPONSES + "?id=" + id)
            // console.log(resp.data);
            const userevaluationsessionresponses = resp.data;
            dispatch( {type: actionTypes.GETUSEREVALUATIONSESSIONRESPONSES_SUCCESS, payload: {userevaluationsessionresponses} } );
        } catch (error) {
            console.log('Error in getUserEvaluationSession', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSEREVALUATIONSESSIONRESPONSES_ERROR, payload: error } );
        }        
    }
}

export const getEvaluation = (id) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETEVALUATION + "?id=" + id)
            // console.log(resp.data);
            const evaluation = resp.data;
            dispatch( {type: actionTypes.GETEVALUATION_SUCCESS, payload: {evaluation} } );
        } catch (error) {
            console.log('Error in getEvaluation', error);
            console.log(error);
            dispatch( {type: actionTypes.GETEVALUATION_ERROR, payload: error } );
        }        
    }
}

export const getEvaluationQuestions = (id) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETEVALUATIONQUESTIONS + "?id=" + id)
            // console.log(resp.data);
            const evaluationquestions = resp.data;
            dispatch( {type: actionTypes.GETEVALUATIONQUESTIONS_SUCCESS, payload: {evaluationquestions} } );
        } catch (error) {
            console.log('Error in getEvaluation', error);
            console.log(error);
            dispatch( {type: actionTypes.GETEVALUATIONQUESTIONS_ERROR, payload: error } );
        }        
    }
}

export const getEvaluationRatings = (id) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETEVALUATIONRATINGS + "?id=" + id)
            // console.log(resp.data);
            const evaluationratings = resp.data;
            dispatch( {type: actionTypes.GETEVALUATIONRATINGS_SUCCESS, payload: {evaluationratings} } );
        } catch (error) {
            console.log('Error in getEvaluationRatings', error);
            console.log(error);
            dispatch( {type: actionTypes.GETEVALUATIONRATINGS_ERROR, payload: error } );
        }        
    }
}

export const updateEvaluationResponses = (evaluationResponses) => {
    return async (dispatch) => {

        try {
            //console.log(evaluationResponses);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEUSEREVALUATIONSESSIONRESPONSES, evaluationResponses)
            //console.log(resp.data);
            const evaluationratings = resp.data;
            dispatch( {type: actionTypes.UPDATEEVALUATIONRESPONSES_SUCCESS, payload: {evaluationratings} } );
        } catch (error) {
            console.log('Error in updateEvaluationResponses', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEEVALUATIONRESPONSES_ERROR, payload: error } );
        }        
    }
}

export const updateUserEvaluationSession = (evaluationSession) => {
    return async (dispatch) => {

        try {
            // console.log(evaluationSession);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEUSEREVALUATIONSESSION, evaluationSession)
            // console.log(resp.data);
            const evaluationsession = resp.data;
            dispatch( {type: actionTypes.UPDATEEVALUATIONSESSION_SUCCESS, payload: {evaluationsession} } );
        } catch (error) {
            console.log('Error in updateEvaluationSession', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEEVALUATIONSESSION_ERROR, payload: error } );
        }        
    }
}

export const getRetentionPrediction = (retentionSessions) => {
    return async (dispatch) => {

        try {
            // console.log(retentionSessions);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETRETENTIONPREDICTION, retentionSessions)
            // console.log(resp.data);
            const retentionSessionResults = resp.data;
            dispatch( {type: actionTypes.GETRETENTIONPREDICTION_SUCCESS, payload: {retentionSessionResults} } );
        } catch (error) {
            console.log('Error in getRetentionPrediction', error);
            console.log(error);
            dispatch( {type: actionTypes.GETRETENTIONPREDICTION_ERROR, payload: error } );
        }        
    }
}

export const updateUserSuggestion = (suggestions) => {
    return async (dispatch) => {

        try {
            // console.log(suggestions);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEUSERSUGGESTION, suggestions)
            // console.log(resp.data);
            const evaluationsession = resp.data;
            dispatch( {type: actionTypes.UPDATEUSERSUGGESTION_SUCCESS, payload: {} } );
        } catch (error) {
            console.log('Error in updateUserSuggestion', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEUSERSUGGESTION_ERROR, payload: error } );
        }        
    }
}

export const getEvaluations = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETEVALUATIONS, evalParams)
            // console.log(resp.data);
            const evaluations = resp.data;
            dispatch( {type: actionTypes.GETEVALUATIONS_SUCCESS, payload: {evaluations} } );
        } catch (error) {
            console.log('Error in getEvaluations', error);
            console.log(error);
            dispatch( {type: actionTypes.GETEVALUATIONS_ERROR, payload: error } );
        }        
    }
}

export const insertEvaluation = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTEVALUATION, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTEVALUATION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertEvaluation', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTEVALUATION_ERROR, payload: error } );
        }        
    }
}

export const deleteEvaluation = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEEVALUATION, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEEVALUATION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteEvaluation', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEEVALUATION_ERROR, payload: error } );
        }        
    }
}

export const insertEvaluationQuestion = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTEVALUATIONQUESTION, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTEVALUATIONQUESTION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertEvaluationQuestion', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTEVALUATIONQUESTION_ERROR, payload: error } );
        }        
    }
}

export const updateEvaluationQuestion = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEEVALUATIONQUESTION, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEEVALUATIONQUESTION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateEvaluationQuestion', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEEVALUATIONQUESTION_ERROR, payload: error } );
        }        
    }
}

export const updateEvaluation = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEEVALUATION, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEEVALUATION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateEvaluation', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEEVALUATION_ERROR, payload: error } );
        }        
    }
}

export const deleteEvaluationQuestion = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEEVALUATIONQUESTION, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEEVALUATIONQUESTION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteEvaluationQuestion', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEEVALUATIONQUESTION_ERROR, payload: error } );
        }        
    }
}

export const insertEvaluationRating = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTEVALUATIONRATING, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTEVALUATIONRATING_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertEvaluationQuestion', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTEVALUATIONRATING_ERROR, payload: error } );
        }        
    }
}

export const deleteEvaluationRating = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEEVALUATIONRATING, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEEVALUATIONRATING_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteEvaluationQuestion', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEEVALUATIONRATING_ERROR, payload: error } );
        }        
    }
}

export const getAssignments = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETASSIGNMENTS, evalParams)
            // console.log(resp.data);
            const assignments = resp.data;
            dispatch( {type: actionTypes.GETASSIGNMENTS_SUCCESS, payload: {assignments} } );
        } catch (error) {
            console.log('Error in getEvaluations', error);
            console.log(error);
            dispatch( {type: actionTypes.GETASSIGNMENTS_ERROR, payload: error } );
        }        
    }
}

export const getGoals = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETGOALS, evalParams)
            // console.log(resp.data);
            const goals = resp.data;
            dispatch( {type: actionTypes.GETGOALS_SUCCESS, payload: {goals} } );
        } catch (error) {
            console.log('Error in getGoals', error);
            console.log(error);
            dispatch( {type: actionTypes.GETGOALS_ERROR, payload: error } );
        }        
    }
}

export const getCompetencies = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETCOMPETENCIES, oParams)
            // console.log(resp.data);
            const Skills = resp.data;
            dispatch( {type: actionTypes.GETCOMPETENCIES_SUCCESS, payload: {Skills} } );
        } catch (error) {
            console.log('Error in getCompetencies', error);
            console.log(error);
            dispatch( {type: actionTypes.GETCOMPETENCIES_ERROR, payload: error } );
        }        
    }
}

export const getSkills = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETSKILLS, oParams)
            // console.log(resp.data);
            const skills = resp.data;
            dispatch( {type: actionTypes.GETSKILLS_SUCCESS, payload: {skills} } );
        } catch (error) {
            console.log('Error in getskills', error);
            console.log(error);
            dispatch( {type: actionTypes.GETSKILLS_ERROR, payload: error } );
        }        
    }
}

export const insertSkill = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTSKILL, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTSKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTSKILL_ERROR, payload: error } );
        }        
    }
}

export const updateSkill = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATESKILL, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATESKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATESKILL_ERROR, payload: error } );
        }        
    }
}

export const deleteSkill = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETESKILL, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETESKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETESKILL_ERROR, payload: error } );
        }        
    }
}

export const getUserAttributesLookups = (id) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETUSERATTRIBUTELOOKUPS + "?id=" + id)
            // console.log(resp.data);
            const userattributeslookups = resp.data;
            dispatch( {type: actionTypes.GETUSERATTRIBUTELOOKUPS_SUCCESS, payload: {userattributeslookups} } );
        } catch (error) {
            console.log('Error in getUserAttributesLookups', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSERATTRIBUTELOOKUPS_ERROR, payload: error } );
        }        
    }
}

export const insertUserLicense = (licParams) => {
    return async (dispatch) => {

        try {
            // console.log(licParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTUSERLICENSE, licParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTUSERLICENSE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserLicense', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTUSERLICENSE_ERROR, payload: error } );
        }        
    }
}

export const deleteUserLicense = (licParams) => {
    return async (dispatch) => {

        try {
            // console.log(licParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEUSERLICENSE, licParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEUSERLICENSE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserLicense', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEUSERLICENSE_ERROR, payload: error } );
        }        
    }
}

export const insertUserCredential = (credParams) => {
    return async (dispatch) => {

        try {
            // console.log(credParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTUSERCREDENTIAL, credParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTUSERCREDENTIAL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserCredential', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTUSERCREDENTIAL_ERROR, payload: error } );
        }        
    }
}

export const deleteUserCredential = (credParams) => {
    return async (dispatch) => {

        try {
            // console.log(credParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEUSERCREDENTIAL, credParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEUSERCREDENTIAL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserCredential', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEUSERCREDENTIAL_ERROR, payload: error } );
        }        
    }
}

export const insertUserGoal = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTUSERGOAL, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTUSERGOAL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserGoal', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTUSERGOAL_ERROR, payload: error } );
        }        
    }
}

export const deleteUserGoal = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEUSERGOAL, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEUSERGOAL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserGoal', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEUSERCREDENTIAL_ERROR, payload: error } );
        }        
    }
}

export const insertUserSkill = (skillParams) => {
    return async (dispatch) => {

        try {
            // console.log(skillParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTUSERSKILL, skillParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTUSERSKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTUSERSKILL_ERROR, payload: error } );
        }        
    }
}

export const updateUserSkill = (skillParams) => {
    return async (dispatch) => {

        try {
            // console.log(skillParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEUSERSKILL, skillParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEUSERSKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEUSERSKILL_ERROR, payload: error } );
        }        
    }
}

export const deleteUserSkill = (skillParams) => {
    return async (dispatch) => {

        try {
            // console.log(skillParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEUSERSKILL, skillParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEUSERSKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEUSERSKILL_ERROR, payload: error } );
        }        
    }
}

export const insertUserJobPositionRequirement = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTUSERJOBPOSITIONREQUIREMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTUSERJOBPOSITIONREQUIREMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserJPR', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTUSERJOBPOSITIONREQUIREMENT_ERROR, payload: error } );
        }        
    }
}

export const deleteUserJobPositionRequirement = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEUSERJOBPOSITIONREQUIREMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEUSERJOBPOSITIONREQUIREMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserJPR', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEUSERJOBPOSITIONREQUIREMENT_ERROR, payload: error } );
        }        
    }
}

export const insertUserFutureJobPosition = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTUSERFUTUREJOBPOSITION, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTUSERFUTUREJOBPOSITION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserFuture JP', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTUSERFUTUREJOBPOSITION_ERROR, payload: error } );
        }        
    }
}

export const deleteUserFutureJobPosition = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEUSERFUTUREJOBPOSITION, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEUSERFUTUREJOBPOSITION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUser Future JP', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEUSERFUTUREJOBPOSITION_ERROR, payload: error } );
        }        
    }
}

export const getJobPositions = (jpParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETJOBPOSITIONS, jpParams)
            // console.log(resp.data);
            const jobpositions = resp.data;
            dispatch( {type: actionTypes.GETJOBPOSITIONS_SUCCESS, payload: {jobpositions} } );
        } catch (error) {
            console.log('Error in getjobpositions', error);
            console.log(error);
            dispatch( {type: actionTypes.GETJOBPOSITIONS_ERROR, payload: error } );
        }        
    }
}

export const insertJobPositionSkill = (skillParams) => {
    return async (dispatch) => {

        try {
            // console.log(skillParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTJOBPOSITIONSKILL, skillParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTJOBPOSITIONSKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTJOBPOSITIONSKILL_ERROR, payload: error } );
        }        
    }
}

export const deleteJobPositionSkill = (skillParams) => {
    return async (dispatch) => {

        try {
            // console.log(skillParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEJOBPSOITIONSKILL, skillParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEJOBPOSITIONSKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEJOBPOSITIONSKILL_ERROR, payload: error } );
        }        
    }
}

export const insertJobPositionEvaluationQuestionGroup = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTJOBPOSITIONEVALUATIONQUESTIONGROUP, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTJOBPOSITIONEQG_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTJOBPOSITIONEQG_ERROR, payload: error } );
        }        
    }
}

export const deleteJobPositionEvaluationQuestionGroup = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEJOBPSOITIONEVALUATIONQUESTIONGROUP, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEJOBPOSITIONEQG_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEJOBPOSITIONEQG_ERROR, payload: error } );
        }        
    }
}

export const insertJobPositionRequirement = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTJOBPOSITIONREQUIREMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTJOBPOSITIONREQUIREMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTJOBPOSITIONREQUIREMENT_ERROR, payload: error } );
        }        
    }
}

export const deleteJobPositionRequirement = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEJOBPSOITIONREQUIREMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEJOBPOSITIONREQUIREMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEJOBPOSITIONREQUIREMENT_ERROR, payload: error } );
        }        
    }
}

export const getJobPositionRequirements = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETJOBPOSITIONREQUIREMENTS, oParams)
            // console.log(resp.data);
            const JobPositionRequirement = resp.data;
            dispatch( {type: actionTypes.GETJOBPOSITIONREQUIREMENTS_SUCCESS, payload: {JobPositionRequirement} } );
        } catch (error) {
            console.log('Error in getJobPositionRequirements', error);
            console.log(error);
            dispatch( {type: actionTypes.GETJOBPOSITIONREQUIREMENTS_ERROR, payload: error } );
        }        
    }
}

export const getJobPositionSkills = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETJOBPOSITIONSKILLS, oParams)
            // console.log(resp.data);
            const JobPositionSkill = resp.data;
            dispatch( {type: actionTypes.GETJOBPOSITIONSKILLS_SUCCESS, payload: {JobPositionSkill} } );
        } catch (error) {
            console.log('Error in getJobPositionSkills', error);
            console.log(error);
            dispatch( {type: actionTypes.GETJOBPOSITIONSKILLS_ERROR, payload: error } );
        }        
    }
}

export const getJobPositionEvaluationQuestionGroups = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETJOBPOSITIONEVALUATIONQUESTIONGROUP, oParams)
            // console.log(resp.data);
            const JobPositionEvaluationQuestionGroup = resp.data;
            dispatch( {type: actionTypes.GETJOBPOSITIONEVALUATIONQUESTIONGROUPS_SUCCESS, payload: {JobPositionEvaluationQuestionGroup} } );
        } catch (error) {
            console.log('Error in getJobPositionEvaluationQuestionGroups', error);
            console.log(error);
            dispatch( {type: actionTypes.GETJOBPOSITIONEVALUATIONQUESTIONGROUPS_ERROR, payload: error } );
        }        
    }
}

export const assignLookupSelection = (id, value) => {
    return async (dispatch) => {

        try {
            // console.log(id);
            if (id === 13) {
                const JobPosition = value;
                dispatch( {type: actionTypes.UPDATEJPLOOKUPSELECTION_SUCCESS, payload: {JobPosition} } );    
            } else if (id === 14) {
                const Goal = value;
                dispatch( {type: actionTypes.UPDATEGOALLOOKUPSELECTION_SUCCESS, payload: {Goal} } );    
            } else if (id === 15) {
                const Skill = value;
                dispatch( {type: actionTypes.UPDATESKILLLOOKUPSELECTION_SUCCESS, payload: {Skill} } );    
            } else if (id === 5) {
            const User = value;
            dispatch( {type: actionTypes.UPDATEUSERLOOKUPSELECTION_SUCCESS, payload: {User} } );    
            } 
        } catch (error) {
            console.log('Error in assignLookupSelection', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEJPLOOKUPSELECTION_ERROR, payload: error } );
        }        
    }
}

export const getJobPositionSuccession = (id) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETJOBPOSITIONSUCCESSION + "?id=" + id)
            const jobpositions = resp.data;
            dispatch( {type: actionTypes.GETJOBPOSITIONS_SUCCESS, payload: {jobpositions} } );
        } catch (error) {
            console.log('Error in getjobpositionsuccession', error);
            console.log(error);
            dispatch( {type: actionTypes.GETJOBPOSITIONS_ERROR, payload: error } );
        }        
    }
}

export const insertAssignment = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTASSIGNMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTASSIGNMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertAssignment', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTASSIGNMENT_ERROR, payload: error } );
        }        
    }
}

export const deleteAssignment = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEASSIGNMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEASSIGNMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteAssignment', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEASSIGNMENT_ERROR, payload: error } );
        }        
    }
}

export const updateAssignment = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEASSIGNMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEASSIGNMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateAssignment', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEASSIGNMENT_ERROR, payload: error } );
        }        
    }
}

export const getSkillChecklist = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETUSERSKILLCHECKLIST, oParams)
            // console.log(resp.data);
            const skillChecklist = resp.data;
            dispatch( {type: actionTypes.GETSKILLCHECKLIST_SUCCESS, payload: {skillChecklist} } );
        } catch (error) {
            console.log('Error in getSkillChecklist', error);
            console.log(error);
            dispatch( {type: actionTypes.GETSKILLCHECKLIST_ERROR, payload: error } );
        }        
    }
}

export const getSkillChecklistGroupBy = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETUSERSKILLCHECKLISTGROUPBY, oParams)
            const skillChecklistGroupBy = resp.data;
            dispatch( {type: actionTypes.GETSKILLCHECKLISTGROUPBY_SUCCESS, payload: {skillChecklistGroupBy} } );
        } catch (error) {
            console.log('Error in getSkillChecklistGroupBy', error);
            console.log(error);
            dispatch( {type: actionTypes.GETSKILLCHECKLISTGROUPBY_ERROR, payload: error } );
        }        
    }
}

export const clearEntityState = () => {
    return async (dispatch) => {
        dispatch({type: actionTypes.CLEARENTITYSTATE_SUCCESS});
    }
}

