import React, { useRef, useContext, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
// import { AuthContext } from '../../contexts/AuthContext'
import { Link } from "react-router-dom"
import { Container } from "react-bootstrap"
import { styles } from '../layout/styles'

export default function ForgotPassword() {
  const emailRef = useRef()
  // const { resetPassword,  } = useContext(AuthContext)
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage("")
      setError("")
      setLoading(true)
      // await resetPassword(emailRef.current.value)
      setMessage("Check your inbox for further instructions")
    } catch {
      setError("Failed to reset password")
    }

    setLoading(false)
  }

  return (
    <>
      <div style={styles.LoginOutDiv}>
      <Container style={styles.Container} >
        <div style={{width:'200px', height:'200px'}}></div>
        <div style={{width:'150px', height:'150px'}}></div>        
        <div className="w-100" style={{ maxWidth:"400px"}}>
          <Card style={{width:'100%', backgroundColor:'transparent'}}>
            <Card.Body>
              <h2 style={styles.Header}>Password Reset</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              {message && <Alert variant="success">{message}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group id="email">
                  <Form.Label style={styles.LabelNoPadding}>Email</Form.Label>
                  <Form.Control type="email" ref={emailRef} required />
                </Form.Group>
                <div className="w-100 text-center mt-3">
                  <Button disabled={loading} style={styles.CDButton} type="submit">
                    Reset
                  </Button>
                </div>
              </Form>
              <div className="w-100 text-center mt-3">
                <Link style={styles.Link} to="/login">Back to login</Link>
              </div>
              <div style={styles.Label}>
                Need an account? <Link style={styles.Link} to="/signup">Sign Up</Link>
              </div>
            </Card.Body>
          </Card>

        </div>
      </Container>
      </div>
    </>
  )
}