import React, { useEffect, useState } from "react"
import { connect } from 'react-redux'
import { compose } from 'redux'
import { styles } from '../layout/styles'
import MyAnalytics from "../userentities/MyAnalytics"
import Spinner from '../layout/Spinner';

function Dashboard(props) {
    const user = props.auth;
    const userevaluationsessions = props.entity;
    const myTeam = props.myTeam;
    const loadingMenu = props.loadingMenu;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(loadingMenu);
    }, [loadingMenu])
  
    if (!user) {
      return (
        <div>Please login again!</div>
      )
    } 
    else {
      return (
        <>
          {(!loading) ? 
            <div style={styles.MainInnerDiv}>
              <div style={styles.DashboardContainer}>
                <MyAnalytics myTeam={myTeam}  />
              </div>
            </div>
            :
            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
              <Spinner/>
            </div>
          }
        </>
      )
    }
} 

const mapStateToProps = (state) => {
  return {
    auth: state.auth.user,
    entity: state.entity.userevaluationsessions,
    myTeam: state.auth.myTeam,
    loadingMenu: state.auth.loadingMenu,
  }
}

export default compose(
  connect(mapStateToProps),
)(Dashboard)


