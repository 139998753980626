import React, { useContext, useRef, useState } from 'react'
import { Form, Button, Card, CardBody, Alert} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { Container } from "react-bootstrap"
import { signUp } from '../../store/actions/authActions'
import { connect } from 'react-redux'
import { styles } from '../layout/styles'

function Signup(props) {
    const firstnameRef = useRef()
    const lastnameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    // const { signup } = useContext(AuthContext)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()

        if (passwordRef.current.value !== passwordConfirmRef.current.value){
            return setError('Password do not match')
        }

        try {
            setError('')
            setLoading(true)
            //await signup(emailRef.current.value, passwordRef.current.value)
            const creds = {
                email:emailRef.current.value, 
                password:passwordRef.current.value,
                firstname:firstnameRef.current.value,
                lastname:lastnameRef.current.value
            }
            // props.signUp(creds);
            const loginError = props.authError;
            if (loginError) {
                console.log(loginError);
                setError(loginError.err.message)
            }
            else {
                console.log('Navigate to Dashboard');
                navigate('/')
            }
            navigate("/")
        } catch {
            setError('Failed to create an account')
        }
        setLoading(false)
    }

  return (
    <>
      <div style={styles.LoginOutDiv}>
      <Container style={styles.Container} >
        <div style={{width:'150px', height:'150px'}}></div>
        <div style={{width:'50%'}}>    
        <Card style={{width:'100%', backgroundColor:'transparent'}}> 
            <CardBody>
                <h2 style={styles.Header}>ChildrenDoc Registration</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id='firstname'>
                        <Form.Label style={styles.LabelNoPadding}>First Name</Form.Label>
                        <Form.Control type='text' ref={firstnameRef} required></Form.Control>
                    </Form.Group>
                    <Form.Group id='lastname'>
                        <Form.Label style={styles.LabelNoPadding}>Last Name</Form.Label>
                        <Form.Control type='text' ref={lastnameRef} required></Form.Control>
                    </Form.Group>
                    <Form.Group id='email'>
                        <Form.Label style={styles.LabelNoPadding}>Email</Form.Label>
                        <Form.Control type='email' ref={emailRef} required></Form.Control>
                    </Form.Group>
                    <Form.Group id='password'>
                        <Form.Label style={styles.LabelNoPadding}>Password</Form.Label>
                        <Form.Control type='password' ref={passwordRef} required></Form.Control>
                    </Form.Group>
                    <Form.Group id='password-confirm'>
                        <Form.Label style={styles.LabelNoPadding}>Confirm Password</Form.Label>
                        <Form.Control type='password' ref={passwordConfirmRef} required></Form.Control>
                    </Form.Group>
                    <div className="w-100 text-center mt-3">
                        <Button disabled={loading} style={styles.CDButton} type="submit">
                            Sign Up
                        </Button>
                    </div>
                    <div style={styles.Label}>
                        Already have an account? <Link style={styles.Link} to="/login">Login</Link>
                    </div>
                </Form>
            </CardBody>
        </Card>


        </div>
        </Container>
        </div>    
    </>
  )
}

const mapsStateToProps = (state) => {
    return {
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (newUser) => dispatch(signUp(newUser))
    }
}

export default connect(mapsStateToProps, mapDispatchToProps)(Signup)