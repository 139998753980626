import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { getGoals, getUserAttributesLookups, insertEvaluation, deleteEvaluation, updateEvaluation, 
    insertEvaluationQuestion, updateEvaluationQuestion, deleteEvaluationQuestion,
    insertEvaluationRating, deleteEvaluationRating, assignLookupSelection } from '../../store/actions/entityActions';
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';


const GoalList = (props) => {
    const { auth, companyData, profile, goals, userattributelookups, loadingMenu, fromLookupParam } = props;    
    const [loading, setLoading] = useState(false);
    const [nodeName, setnodeName] = useState('');
    const [sliderValue, setSliderValue] = useState([]);
    const [sliderChildrenValue, setSliderChildrenValue] = useState([]);
    const [addNewPillar, setAddNewPillar] = useState(false);
    const [editPillar, setEditPillar] = useState(false);
    const [pillarID, setPillarID] = useState(-1);
    const [pillarName, setPillarName] = useState('');
    const [pillarDescription, setPillarDescription] = useState('');
    const [pillarWeight, setPillarWeight] = useState(100);
    const [pillarAnonymous, setPillarAnonymous] = useState('1');
    const [pillarRatingTypes, setPillarRatingTypes] = useState('1');
    const [pillarQuestionRatingTypes, setPillarQuestionRatingTypes] = useState('1');
    const [selectedValue, setSelectedValue] = useState('');

    const evaluation = goals?.goals?.Evaluations;
    const evaluationquestions = goals?.goals?.EvaluationQuestions;
    const evaluationratings = goals?.goals?.EvaluationRatings;
    const evaluationquestionratings = goals?.goals?.EvaluationQuestionRatings;
    const ratingData = userattributelookups?.Ratings;
    const evalGroupData = userattributelookups?.EvaluationQuestionGroups;
    const orgGoalRatings =  userattributelookups?.OrgGoalRatings;

    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    async function searchPillars() {
        setLoading(true);
        const evalParams1  = {
            UserID:auth.UserID,
            SearchFilter:nodeName,
        };        
        await props.getGoals(evalParams1);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (evaluation) => {
        return evaluation?.map((evalRec) => ({
                            EvaluationID:evalRec.EvaluationID, 
                            EvaluationQuestionGroupID:-1,
                            DisplayOrder:-1,                    
                            Weight:-1,
                            Optional:false,
                            EvaluationQuestion:'',
                            EvaluationRatingTypeID:'',        
                            EditedEvaluationQuestionID:-1,
                            takeAction:false,
                            showDetails:false,
                            disableNewEvaluation:false,
                            newEvaluationClicked:false,
                            editEvaluationClicked:false,
                            disableNewQuestion:false,
                            newQuestionClicked:false,
                            editQuestionClicked:false,
                            newRatingClicked:false,
        }))
    }
        
    const addInitialSliderChildrenArray = (evaluationQuestion) => {
        return evaluationQuestion?.map((evalQuestionRec) => ({
                            EvaluationID:evalQuestionRec.EvaluationID, 
                            EvaluationQuestionID:evalQuestionRec.EvaluationQuestionID,
                            EvaluationQuestionGroupID:evalQuestionRec.EvaluationQuestionGroupID,
                            DisplayOrder:-1,                    
                            Weight:-1,
                            Optional:false,
                            EvaluationName:evalQuestionRec.EvaluationName,
                            EvaluationQuestion:evalQuestionRec.EvaluationQuestion,
                            EvaluationQuestionGroupName:evalQuestionRec.EvaluationQuestionGroupName,
                            EvaluationRatingTypeID:'',        
                            selectedClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(evaluation);
        setSliderValue(initialScoreValues);
        const initialScoreChildrenValues = addInitialSliderChildrenArray(evaluationquestions);
        setSliderChildrenValue(initialScoreChildrenValues);
    }, [evaluation])
    
    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    function handleRatingsClick(e) {
        let selectedValues = '';
        [...e.target.selectedOptions].map((sSelOption) => {
            if (selectedValues.trim() !== '') {
                selectedValues += ',';
            }
            selectedValues += sSelOption.value;
        });
        setPillarRatingTypes(selectedValues);
    }

    function handleQuestionRatingsClick(e) {
        let selectedValues = '';
        [...e.target.selectedOptions].map((sSelOption) => {
            if (selectedValues.trim() !== '') {
                selectedValues += ',';
            }
            selectedValues += sSelOption.value;
        });
        setPillarQuestionRatingTypes(selectedValues);
    }

    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewPillar) ? setAddNewPillar(false) : setAddNewPillar(true);
            setPillarID(-1);
            setPillarName('');
            setPillarDescription('');
            setPillarWeight(0);
            setPillarRatingTypes('');
        } else {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.EvaluationID === rowIndex) {
                    if (tab === 2) {
                        (row.newQuestionClicked) ? row.newQuestionClicked = false : row.newQuestionClicked = true;                    
                    } else if (tab === 3) {
                        (row.newRatingClicked) ? row.newRatingClicked = false : row.newRatingClicked = true;                    
                    } 
                }
            });
            setSliderValue(updatedSliderValues);
        }

        setLoading(false);
    }

    async function handleCancelChildernClick(tab, currentEval) {
        if (tab === 1) {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.EvaluationID === currentEval?.EvaluationID) {
                    if (tab === 2) {
                        row.disableNewEvaluation = false;                    
                        row.newEvaluationClicked = false;
                        row.editEvaluationClicked = false;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
            setPillarID(-1);
            setEditPillar(false);
            setAddNewPillar(false);
        } else if (tab === 2) {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.EvaluationID === currentEval.EvaluationID) {
                    if (tab === 2) {
                        row.editQuestionClicked = false;                    
                        row.disableNewQuestion = false;
                        row.newQuestionClicked = false;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
        }
    }
    
    async function handleSaveChildernClick(tab, currentEval) {
        setLoading(true);
        if (tab === 1) {
            const evalParams  = {
                EvaluationEntityTypeID:5,
                EvaluationName: pillarName,
                EvaluationHeader:pillarDescription,
                IsAnonymous:(pillarAnonymous === '1') ? true : false,
                UserEvaluationWeight:parseFloat(pillarWeight),
                EvaluationRatingTypes:pillarRatingTypes,
            };        
            console.log(evalParams);
            await props.insertEvaluation(evalParams);    
        } else if (tab === 2) {
            const jpRequestList = sliderValue.filter((jobP) => jobP.EvaluationID === currentEval.EvaluationID);
            const evalParams  = {
                EvaluationEntityTypeID:5,
                EvaluationID: currentEval.EvaluationID,
                EvaluationQuestionGroupID:jpRequestList[0]?.EvaluationQuestionGroupID,
                DisplayOrder:jpRequestList[0]?.DisplayOrder,
                Weight:jpRequestList[0]?.Weight,
                Optional:jpRequestList[0]?.Optional,
                EvaluationQuestion:jpRequestList[0]?.EvaluationQuestion,
                EvaluationQuestionRatingTypes:pillarQuestionRatingTypes,
            };        
            console.log(evalParams);
            await props.insertEvaluationQuestion(evalParams);    
        } if (tab === 3) {
            const jpRequestList = sliderValue.filter((jobP) => jobP.EvaluationID === currentEval.EvaluationID);
            const evalParams  = {
                EvaluationEntityTypeID:5,
                EvaluationID: currentEval.EvaluationID,
                EvaluationRatingTypeID:jpRequestList[0]?.EvaluationRatingTypeID,
           };        
            console.log(evalParams);
            await props.insertEvaluationRating(evalParams);    
        }

        const evalParams1  = {
            UserID:auth.UserID,
            SearchFilter:nodeName,
        };        
        await props.getGoals(evalParams1);        
        setPillarName('');
        setPillarDescription('');
        setPillarWeight(0);
        setPillarRatingTypes('');
        setAddNewPillar(false);
        setEditPillar(false);
        setLoading(false);
    }

    async function handleEditedSaveChildernClick(tab, currentEval) {
        setLoading(true);
        if (tab === 1) {
            const evalParams  = {
                EvaluationEntityTypeID:5,
                EvaluationID: pillarID,
                EvaluationName: pillarName,
                EvaluationHeader:pillarDescription,
                IsAnonymous:(pillarAnonymous === '1') ? true : false,
                UserEvaluationWeight:parseFloat(pillarWeight),
                EvaluationRatingTypes:pillarRatingTypes,
            };        
            console.log(evalParams);
            await props.updateEvaluation(evalParams);    
            setPillarName('');
            setPillarDescription('');
            setPillarWeight(0);
            setPillarRatingTypes('');
            setEditPillar(false);    
        } else if (tab === 2) {
            const jpRequestList = sliderValue.filter((jobP) => jobP.EvaluationID === currentEval.EvaluationID);
            const evalParams  = {
                EvaluationEntityTypeID:5,
                EvaluationID: currentEval.EvaluationID,
                EvaluationQuestionID: jpRequestList[0]?.EditedEvaluationQuestionID,
                EvaluationQuestionGroupID:jpRequestList[0]?.EvaluationQuestionGroupID,
                DisplayOrder:jpRequestList[0]?.DisplayOrder,
                Weight:jpRequestList[0]?.Weight,
                Optional:jpRequestList[0]?.Optional,
                EvaluationQuestion:jpRequestList[0]?.EvaluationQuestion,
                EvaluationQuestionRatingTypes:pillarQuestionRatingTypes,
            };        
            console.log(evalParams);
            await props.updateEvaluationQuestion(evalParams);    
        } 

        const evalParams1  = {
            UserID:auth.UserID,
            SearchFilter:nodeName,
        };        
        await props.getGoals(evalParams1);        
        setLoading(false);
    }

    async function handleUpdateChildernClick(tab, id, id1) {
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            const evalEdited = evaluation?.filter((evalRec) => (evalRec.EvaluationID === id));

            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.EvaluationID === id) {
                        row.editEvaluationClicked = true;                    
                        row.disableNewEvaluation = true;
                        row.newEvaluationClicked = false;
                        setPillarID(id);
                        setPillarName(evalEdited[0]?.EvaluationName);
                        setPillarDescription(evalEdited[0]?.EvaluationHeader);
                        setPillarWeight(evalEdited[0]?.Weight);
                        setPillarRatingTypes(evalEdited[0]?.EvaluationRatingTypes);
                }
            });
            setEditPillar(true);
            setSliderValue(updatedSliderValues);    
        } else if (tab === 2) {
            const evalEditedQuestions = evaluationquestions?.filter((evalRec) => (evalRec.EvaluationQuestionID === id1));

            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.EvaluationID === id) {
                    if (tab === 2) {
                        row.editQuestionClicked = true;                    
                        row.disableNewQuestion = true;
                        row.newQuestionClicked = false;
                        row.EditedEvaluationQuestionID = id1;
                        row.EvaluationQuestionGroupID = evalEditedQuestions[0]?.EvaluationQuestionGroupID;
                        row.DisplayOrder = evalEditedQuestions[0]?.DisplayOrder;
                        row.Weight = evalEditedQuestions[0]?.Weight;
                        row.Optional = evalEditedQuestions[0]?.Optional;
                        row.EvaluationQuestion = evalEditedQuestions[0]?.EvaluationQuestion;
                } 
                }
            });
            setSliderValue(updatedSliderValues);    
        }
    }

    function checkedDisableNewQuestion(tab, id, id1) {
        let bDisabled = false;
        if (tab === 2) {
            sliderValue.map((row, index) => {
                if (row.EvaluationID === id) {
                    if (tab === 2) {
                        bDisabled = row.disableNewQuestion;
                    } 
                }
            });
        }
        return bDisabled;
    }

    async function handleDeleteChildernClick(tab, id, id1) {
        setLoading(true);

        if (tab === 1) {
            const evalParams  = {
                EvaluationID: id,
            };        
            console.log(evalParams);
            await props.deleteEvaluation(evalParams);    
        } else if (tab === 2) {
            const evalParams  = {
                EvaluationQuestionID: id1,
            };        
            console.log(evalParams);
            await props.deleteEvaluationQuestion(evalParams);    
        } else if (tab === 3) {
            const evalParams  = {
                EvaluationRatingID: id1,
            };        
            console.log(evalParams);
            await props.deleteEvaluationRating(evalParams);    
        }
        
        const evalParams1  = {
            UserID:auth.UserID,
            SearchFilter:nodeName,
        };        
        await props.getGoals(evalParams1);            

        setLoading(false);
    }

    async function handleAddChildernValues(tab, rowIndex, value) {
        setLoading(true);

        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.EvaluationID === rowIndex) {
                if (tab === 1) {
                    row.EvaluationQuestionGroupID = evalGroupData[value].EvaluationQuestionGroupID;
                } else if (tab === 2) {
                    row.DisplayOrder = value;                    
                } else if (tab === 3) {
                    row.Weight = value;
                } else if (tab === 4) {
                    row.Optional = (value === '1') ? true : false;                    
                } else if (tab === 5) {
                    row.EvaluationQuestion = value;                    
                } else if (tab === 6) {
                    row.EvaluationRatingTypeID = value;                    
                } 
            }        
        });
        setSliderValue(updatedSliderValues);

        setLoading(false);
    }

    function checkMainPillar(tab, id, id1) {
        let companyPillars = '';
        let isMainPillar = true;
        const companyParameters = auth.UserCompanyParameters?.split('&');
        companyParameters.map((parameter) => {
            const parameterDetails = parameter?.split('=');
            if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
                companyPillars = parameterDetails[1];
                isMainPillar = ("," + companyPillars + ",").includes("," + id.toString() + ",");
            }
        });

        if ((!fromLookup) && (!isMainPillar)) {
            if (tab === 2) {
                return (
                    <div>
                        <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Question'>edit</span>
                        </a>
                        <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Question'>delete</span>
                        </a>
                    </div>
                );                
            } else {
            return (
                <div>
                    <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Goal'>edit</span>
                    </a>
                    <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Goal'>delete</span>
                    </a>
                </div>
            );}            
        } else if ((fromLookup) && (tab === 2)) {
            return (
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" 
                        checked={getSubGoalDetailSwitchValue(id, id1)} id={`switch${'detail' + id1}`} 
                        style={(getSubGoalDetailSwitchValue(id, id1)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                        onChange={(e) => {handleSubGoalSwitchDetailChange(id, id1)}}>
                    </input>
                    <label class="form-check-label" for={`switch${'detail' + id1}`}>Select</label>
                </div>
            );
        } else {
            return (<></>)
        }
    }

    function displayNewPillar() {
        if ((!fromLookup) && (addNewPillar)) {
            return (
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td colSpan='3' style={{textAlign:'left', width:'95%'}}>
                                <div>
                                    <label htmlFor="pillarName" style={styles.LabelDataEntryNoPadding}>Name</label>
                                    <input id="pillarName" type="text" style={styles.TextDataEntryNoPadding} onChange={(e) => (setPillarName(e.target.value))}/>
                                </div>                    
                            </td>
                            {/* <td style={{textAlign:'left', width:'25%'}}>
                                <div>
                                    <label htmlFor="anonymous" style={styles.LabelDataEntryNoPadding}>Anonymous</label>
                                    <select id="anonymous" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={pillarAnonymous} onChange={(e) => (setPillarAnonymous(e.target.value))}>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'5%'}}>
                                <div>
                                    <label htmlFor="weight" style={styles.LabelDataEntryNoPadding}>Weight</label>
                                    <input id="weight" type="number" max='100' min='0' style={styles.TextDataEntryNoPadding} defaultValue={pillarWeight} onChange={(e) => (setPillarWeight(e.target.value))}/>
                                </div>                    
                            </td> */}
                        </tr>
                        <tr>
                            <td colSpan='3' style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="description" style={styles.LabelDataEntryNoPadding}>Description</label>
                                    <input id="description" type="text" style={styles.TextDataEntryNoPadding} onChange={(e) => (setPillarDescription(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>              
                        <tr>
                            <td style={{textAlign:'left', width:'25%', height:'200px'}}>
                                <div>
                                    <label htmlFor="reqRatings" style={styles.LabelDataEntryNoPadding}>Ratings</label>
                                    <select id="reqRatings" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100} onChange={(e) => (handleRatingsClick(e))}>
                                        {orgGoalRatings && orgGoalRatings.map((rating, supIndex) => (
                                            <option value={rating.EvaluationRatingTypeID}>{rating.EvaluationRatingTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'right', width:'5%'}}>
                                <div>
                                    <Button onClick={() => handleCancelChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey}>Cancel</Button>
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'5%'}}>
                                <div>
                                    <Button onClick={() => handleSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Pillar'>Save</Button>
                                </div>
                            </td>
                        </tr>  
                    </tbody>
                </table>                                   
            );
        } else if ((!fromLookup) && (editPillar)) {
            return (
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td colSpan='3' style={{textAlign:'left', width:'95%'}}>
                                <div>
                                    <label htmlFor="pillarName" style={styles.LabelDataEntryNoPadding}>Name</label>
                                    <input id="pillarName" type="text" style={styles.TextDataEntryNoPadding} defaultValue={pillarName} onChange={(e) => (setPillarName(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='3' style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="description" style={styles.LabelDataEntryNoPadding}>Description</label>
                                    <input id="description" type="text" style={styles.TextDataEntryNoPadding} defaultValue={pillarDescription} onChange={(e) => (setPillarDescription(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>              
                        <tr>
                            <td style={{textAlign:'left', width:'25%', height:'200px'}}>
                                <div>
                                    <label htmlFor="reqRatings" style={styles.LabelDataEntryNoPadding}>Ratings</label>
                                    <select id="reqRatings" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100} defaultValue={pillarRatingTypes?.split(',')} onChange={(e) => (handleRatingsClick(e))}>
                                        {orgGoalRatings && orgGoalRatings.map((rating, supIndex) => (
                                            <option value={rating.EvaluationRatingTypeID}>{rating.EvaluationRatingTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'right', width:'5%'}}>
                                <div>
                                    <Button onClick={() => handleCancelChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey}>Cancel</Button>
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'5%'}}>
                                <div>
                                    <Button onClick={() => handleEditedSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Pillar'>Save</Button>
                                </div>
                            </td>
                        </tr>  
                    </tbody>
                </table>                                   
            );
        }
    }

    function displayNewQuestion(currentJP) {
        if (!fromLookup) {
            const jpRequestList = sliderValue.filter((jobP) => jobP.EvaluationID === currentJP.EvaluationID);
            const evalEditedQuestions = evaluationquestions?.filter((evalRec) => (evalRec.EvaluationQuestionID === jpRequestList[0]?.EditedEvaluationQuestionID));

            if ((jpRequestList[0].newQuestionClicked) || (jpRequestList[0].editQuestionClicked)) {
                return (
                    <table class="table caption-top text-left">  
                        <thead>
                        </thead>                        
                            {(jpRequestList[0].editQuestionClicked) ? 
                            <tbody> 
                            <tr>
                                <td style={{textAlign:'left'}}>
                                    <div>
                                        <label style={styles.LabelDataEntryNoPadding}>Group</label>
                                        <select id="requirementtype" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={evalEditedQuestions[0]?.EvaluationQuestionGroupID} onChange={(e) => (handleAddChildernValues(1, currentJP.EvaluationID, e.target.selectedIndex))}>
                                            {evalGroupData.map((requirement, supIndex) => (
                                                <option value={requirement.EvaluationQuestionGroupID}>{requirement.EvaluationQuestionGroupName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                                <td style={{textAlign:'left', height:'200px'}}>
                                    <div>
                                        <label htmlFor="reqQRatings" style={styles.LabelDataEntryNoPadding}>Ratings</label>
                                        <select id="reqQRatings" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100} defaultValue={evalEditedQuestions[0]?.EvaluationQuestionRatingTypes.split(',')} onChange={(e) => (handleQuestionRatingsClick(e))}>
                                            {orgGoalRatings.map((rating, supIndex) => (
                                                <option value={rating.EvaluationRatingTypeID}>{rating.EvaluationRatingTypeName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                                <td style={{textAlign:'left', width:'15%'}}>
                                    <div>
                                        <label htmlFor="displayorder" style={styles.LabelDataEntryNoPadding}>Display Order</label>
                                        <input id="displayorder" type="number" min='1' defaultValue={evalEditedQuestions[0]?.DisplayOrder} style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(2, currentJP.EvaluationID, e.target.value))}/>
                                    </div>                    
                                </td>
                                {/* <td style={{textAlign:'left'}}>
                                    <div>
                                        <label htmlFor="qweight" style={styles.LabelDataEntryNoPadding}>Weight</label>
                                        <input id="qweight" type="number" min='1' max='100' step='1' defaultValue={evalEditedQuestions[0]?.Weight} style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(3, currentJP.EvaluationID, e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'left'}}>
                                    <div>
                                        <label htmlFor="optional" style={styles.LabelDataEntryNoPadding}>Optional</label>
                                        <select id="optional" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={evalEditedQuestions[0]?.Optional ? '1' :'0'}  onChange={(e) => (handleAddChildernValues(4, currentJP.EvaluationID, e.target.value))}>
                                                <option value='1'>Yes</option>        
                                                <option value='0'>No</option>        
                                        </select>                                     
                                    </div>
                                </td> */}
                            </tr>
                            <tr>                          
                                <td colSpan='2' style={{textAlign:'left'}}>
                                    <div>
                                        <label htmlFor="reqNotes" style={styles.LabelDataEntryNoPadding}>Question</label>
                                        <input id="reqNotes" type="text" style={styles.TextDataEntryNoPadding} defaultValue={evalEditedQuestions[0]?.EvaluationQuestion} onChange={(e) => (handleAddChildernValues(5, currentJP.EvaluationID, e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'right', width:'15%'}}>
                                    <div>
                                        <Button onClick={() => handleEditedSaveChildernClick(2, currentJP)} disabled={loading} style={styles.CDPillButtonSMGrey}>Save</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => handleCancelChildernClick(2, currentJP)} disabled={loading} style={styles.CDPillButtonSMGrey}>Cancel</Button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                            :
                            <tbody> 
                            <tr>
                                <td style={{textAlign:'left'}}>
                                    <div>
                                        <label style={styles.LabelDataEntryNoPadding}>Group</label>
                                        <select id="requirementtype" class="browser-default" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(1, currentJP.EvaluationID, e.target.selectedIndex))}>
                                            {evalGroupData.map((requirement, supIndex) => (
                                                <option value={requirement.EvaluationQuestionGroupID}>{requirement.EvaluationQuestionGroupName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                                <td style={{textAlign:'left', height:'200px'}}>
                                    <div>
                                        <label htmlFor="reqQRatings" style={styles.LabelDataEntryNoPadding}>Ratings</label>
                                        <select id="reqQRatings" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100} onChange={(e) => (handleQuestionRatingsClick(e))}>
                                            {orgGoalRatings.map((rating, supIndex) => (
                                                <option value={rating.EvaluationRatingTypeID}>{rating.EvaluationRatingTypeName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                                <td style={{textAlign:'left', width:'15%'}}>
                                    <div>
                                        <label htmlFor="displayorder" style={styles.LabelDataEntryNoPadding}>Display Order</label>
                                        <input id="displayorder" type="number" min='1' style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(2, currentJP.EvaluationID, e.target.value))}/>
                                    </div>                    
                                </td>
                                {/* <td style={{textAlign:'left'}}>
                                    <div>
                                        <label htmlFor="qweight" style={styles.LabelDataEntryNoPadding}>Weight</label>
                                        <input id="qweight" type="number" min='1' max='100' step='1' style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(3, currentJP.EvaluationID, e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'left'}}>
                                    <div>
                                        <label htmlFor="optional" style={styles.LabelDataEntryNoPadding}>Optional</label>
                                        <select id="optional" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={pillarAnonymous} onChange={(e) => (handleAddChildernValues(4, currentJP.EvaluationID, e.target.value))}>
                                                <option value='1'>Yes</option>        
                                                <option value='0'>No</option>        
                                        </select>                                     
                                    </div>
                                </td> */}
                            </tr>
                            <tr>                          
                                <td colSpan='2' style={{textAlign:'left'}}>
                                    <div>
                                        <label htmlFor="reqNotes" style={styles.LabelDataEntryNoPadding}>Question</label>
                                        <input id="reqNotes" type="text" style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(5, currentJP.EvaluationID, e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'center'}}>
                                    <div>
                                        <Button onClick={() => handleSaveChildernClick(2, currentJP)} disabled={loading} style={styles.CDPillButtonSMGrey}>Save</Button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        }
                    </table>                                   
                );
            }
        }
    }

    function displayNewRating(currentJP) {
        if (!fromLookup) {
            const jpRequestList = sliderValue.filter((jobP) => jobP.EvaluationID === currentJP.EvaluationID);
            if (jpRequestList[0].newRatingClicked) {
                return (
                    <table class="table caption-top text-left">  
                        <thead>
                        </thead>                        
                        <tbody> 
                            <tr>
                                <td style={{textAlign:'left'}}>
                                    <div>
                                        <label htmlFor="reqRatings1" style={styles.LabelDataEntryNoPadding}>Ratings</label>
                                        <select id="reqRatings1" class="browser-default" style={styles.TextDataEntryNoPaddingHeight100} onChange={(e) => (handleAddChildernValues(6, currentJP.EvaluationID, e.target.value))}>
                                            {orgGoalRatings.map((rating, supIndex) => (
                                                <option value={rating.EvaluationRatingTypeID}>{rating.EvaluationRatingTypeName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                                <td style={{textAlign:'center'}}>
                                    <div>
                                        <Button onClick={() => handleSaveChildernClick(3, currentJP)} disabled={loading} style={styles.CDPillButtonSMGrey}>Save</Button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>                                   
                );
            }
        }
    }

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;        
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (row.EvaluationID === rowIndex)) {
                bReturn = row.showDetails;
            }
        })
        return bReturn;
    }
 
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);//[...sliderValue];
        updatedSliderValues.map((row, index) => {
            if (row.EvaluationID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
    }

    async function getSelectedValue() {
        let currentSelectedValue = '';
        sliderChildrenValue.map((row, index) => {
            if (row.selectedClicked) {
                if (currentSelectedValue !== '') {
                    currentSelectedValue += '&';
                }
                currentSelectedValue += row.EvaluationQuestionID + '=' + row.EvaluationQuestion + '|' + row.EvaluationID + '|' + row.EvaluationName + '|' + row.EvaluationQuestionGroupID + '|' + row.EvaluationQuestionGroupName;
            } 
        });
        setSelectedValue(currentSelectedValue);
        await props.assignLookupSelection(14, currentSelectedValue);
        return currentSelectedValue;
    }

    function getSubGoalDetailSwitchValue(id, id1) {
        let bReturn = false;        
        sliderChildrenValue && sliderChildrenValue.map((row, index) => {
            if ((row) && (row.EvaluationID === id) && (row.EvaluationQuestionID === id1)) {
                bReturn = row.selectedClicked;
            }
        })
        return bReturn;
    }
 
    function handleSubGoalSwitchDetailChange(id, id1) {
        const updatedSliderValues = sliderChildrenValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if ((row.EvaluationID === id) && (row.EvaluationQuestionID === id1)) {
                if (row.selectedClicked) {
                    row.selectedClicked = false;
                } else {
                    row.selectedClicked = true;
                }
            }
        });
        setSliderChildrenValue(updatedSliderValues);
        getSelectedValue();
    }

    function displayAddButtons(tabValue, currentJP) {
        if (!fromLookup) {
            const jpRequestList = sliderValue.filter((jobP) => jobP.EvaluationID === currentJP.EvaluationID);

            if (tabValue === 2 ) {
                if (!jpRequestList[0].disableNewQuestion) {
                    if (jpRequestList[0].newQuestionClicked) {
                        return (
                            <a onClick={() => handleAddChildernClick(2, currentJP.EvaluationID)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Question'>shadow_minus</span>
                            </a>
                        );
                    } else {
                        return (
                            <a onClick={() => handleAddChildernClick(2, currentJP.EvaluationID)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Question'>note_add</span>
                            </a>
                        );
                    }
                }
            } else if (tabValue === 3 ) {
                if (jpRequestList[0].newRatingClicked) {
                    return (
                        <a onClick={() => handleAddChildernClick(3, currentJP.EvaluationID)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Rating'>shadow_minus</span>
                        </a>
                    );
                } else {
                    return (
                        <a onClick={() => handleAddChildernClick(3, currentJP.EvaluationID)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Rating'>note_add</span>
                        </a>
                    );
                }
            } 
        }
    }

    function displayEvaluationRatings(currentEval){
        const evalRatings = evaluationratings?.filter((evalRec) => (evalRec.EvaluationID === currentEval.EvaluationID));

        if (!fromLookup) {
            return (
                <table class="table table-sm caption-top">  
                <caption></caption>
                <thead>
                    <tr>
                        <th style={styles.tableTextLeft}>Ratings</th>
                        <th style={styles.tableTextRight}>{displayAddButtons(3, currentEval)}</th>
                    </tr>
                </thead>                        
                <tbody> 
                    <tr>
                        <td colSpan="6"> 
                        {displayNewRating(currentEval)}
                        {(evalRatings.length === 0) ? <></> : 
                            <table class="table table-sm caption-top">  
                                <thead>
                                    <tr>
                                        <th style={styles.tableText}>#</th>
                                        <th style={styles.tableTextMain}>Name</th>
                                        <th style={styles.tableText}>Score</th>
                                        <th style={styles.tableText}>Order</th>
                                        <th style={styles.tableText}>Color</th>
                                        <th style={styles.tableText}>Delete</th>
                                    </tr>
                                </thead>                        
                                <tbody> 
                                    {getEvaluationRatings(evalRatings)}  
                                </tbody>
                            </table>    
                        }
                        </td> 
                    </tr>             
                </tbody>
                </table>    
            );
        } else {
            return (<></>);
        }
    }

    function getDetailSwitchTR(currentEval) {
        const evalRequestList = sliderValue?.filter((evalRec) => evalRec.EvaluationID === currentEval.EvaluationID);
        const evalQuestions = evaluationquestions?.filter((evalRec) => (evalRec.EvaluationID === currentEval.EvaluationID));
        if (evalRequestList[0]?.showDetails) {
            return (
                <tr id={`tr${'detail' + currentEval.EvaluationID}`}>
                <td colSpan="6"> 
                    <table class="table table-sm caption-top">  
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Questions</th>
                                    <th style={styles.tableTextRight}>{displayAddButtons(2, currentEval)}</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                                <tr>
                                    <td colSpan="6"> 
                                    {displayNewQuestion(currentEval)}
                                    {(evalQuestions.length === 0) ? <></> :
                                        <table class="table table-sm caption-top">  
                                            <thead>
                                                <tr>
                                                    <th style={styles.tableText}>#</th>
                                                    <th style={styles.tableTextLeft}>Group</th>
                                                    <th style={styles.tableTextLeft}>Question</th>
                                                    <th style={styles.tableText}>Order</th>
                                                    {/* <th style={styles.tableText}>Weight</th>
                                                    <th style={styles.tableText}>Optional</th> */}
                                                    <th style={styles.tableText}>Action</th>
                                                </tr>
                                            </thead>                        
                                            <tbody> 
                                                {getEvaluationQuestions(evalQuestions)}  
                                            </tbody> 
                                        </table> 
                                        }
                                    </td> 
                                </tr>             
                            </tbody>
                        </table>    

                        {displayEvaluationRatings(currentEval)}
                </td> 
            </tr>             
            );
        }
    }

    function getEvaluationQuestions(evalQuestions) {
        if (evalQuestions.length === 0) {
            return (
                <></>
            );
        } else {
            return evalQuestions.map((question, questionIndex) => (
                <tr> 
                    <td style={styles.tableText}>{questionIndex + 1}</td> 
                    <td style={styles.tableTextLeft}>{question?.EvaluationQuestionGroupName}</td> 
                    <td style={styles.tableTextLeft}>{question?.EvaluationQuestion}</td> 
                    <td style={styles.tableText}>{question?.DisplayOrder}</td> 
                    {/* <td style={styles.tableText}>{(question?.Weight) ? question?.Weight : '100'}</td> 
                    <td style={styles.tableText}>{(question?.Optional) ? 'Yes' : 'No'}</td>  */}
                    <td style={styles.tableTextAction}>{checkMainPillar(2, question?.EvaluationID, question?.EvaluationQuestionID)}</td> 
                </tr> 
            ));        
        }
    }

    function getEvaluationRatings(evalRatings) {
        if (evalRatings.length === 0) {
            return (
                <></>
            );
        } else {
            return evalRatings.map((rating, ratingIndex) => (
                <tr> 
                    <td style={styles.tableText}>{ratingIndex + 1}</td> 
                    <td style={styles.tableTextLeft}>{rating?.EvaluationRatingTypeName}</td> 
                    <td style={styles.tableText}>{rating?.Score}</td> 
                    <td style={styles.tableText}>{rating?.Order}</td> 
                    <td style={styles.tableText}>{(rating?.ColorCode) ? rating?.ColorCode : 'N/A'}</td> 
                    <td style={styles.tableText}>{checkMainPillar(3, rating?.EvaluationID, rating?.EvaluationRatingID)}</td> 
                </tr> 
            ));        
        }        
    }

    function displayAddMainGoal() {
        if (!fromLookup) {
            if (addNewPillar) {
                return (
                    <a onClick={() => handleAddChildernClick(1, null)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Goal'>shadow_minus</span>
                    </a>
                );   
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(1, null)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Goal'>note_add</span>
                    </a>
                );    
            }
        } else {
            return (<></>);
        }
    }

    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Goals</h3></td>
                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field'>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Goal'/>
                        <a onClick={() => searchPillars()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                        {displayAddMainGoal()}
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );
    }

    return (
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          

                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewPillar()}                                               
                            {((!evaluation) || (evaluation.length === 0)) ? (             
                                <div style={styles.tableText}>
                                    No Goals.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextMain}>Name</th>
                                                <th style={styles.tableText}>Description</th>
                                                <th style={styles.tableText}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {evaluation && evaluation.map((evalRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{evalRec?.EvaluationID}</td>
                                                        <td style={styles.tableTextSubHeader}>
                                                            {evalRec?.EvaluationName}
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox" role="switch" 
                                                                    checked={getDetailSwitchValue(evalRec?.EvaluationID)} id={`switch${'evaldetail' + evalRec?.EvaluationID}`} 
                                                                    style={(getDetailSwitchValue(evalRec?.EvaluationID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                                                    onChange={(e) => {handleSwitchDetailChange(evalRec?.EvaluationID)}}>
                                                                </input>
                                                                <label class="form-check-label" for={`switch${'detail' + evalRec?.UserID}`}>Details</label>
                                                            </div>
                                                        </td>
                                                        <td style={styles.tableText}>{evalRec?.EvaluationHeader}</td>
                                                        <td style={styles.tableText}>{checkMainPillar(1, evalRec?.EvaluationID, evalRec?.EvaluationID)}</td>
                                                    </tr>
                                                    {getDetailSwitchTR(evalRec)}
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-left">
                        </Card.Footer>
                </Card>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      goals: state.entity.goals, 
      userattributelookups: state.entity.userattributelookups,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getGoals: (evalParams) => dispatch(getGoals(evalParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertEvaluation: (evalParams) => dispatch(insertEvaluation(evalParams)),
        deleteEvaluation: (evalParams) => dispatch(deleteEvaluation(evalParams)),
        updateEvaluation: (evalParams) => dispatch(updateEvaluation(evalParams)),
        insertEvaluationQuestion: (evalParams) => dispatch(insertEvaluationQuestion(evalParams)),
        updateEvaluationQuestion: (evalParams) => dispatch(updateEvaluationQuestion(evalParams)),
        deleteEvaluationQuestion: (evalParams) => dispatch(deleteEvaluationQuestion(evalParams)),
        insertEvaluationRating: (evalParams) => dispatch(insertEvaluationRating(evalParams)),
        deleteEvaluationRating: (evalParams) => dispatch(deleteEvaluationRating(evalParams)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoalList)