import React from "react";
import "../App.css";

const CustomTooltip = ({ active, payload, label, displayName, displayValue, NoDisplay }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">

          <div style={{ display: "inline-block", padding: 5 }}>
            <div style={{ display: "flex", flexDirection: "row", gap: "3px" }}>
              <div style={{ fontWeight: 800 }}>{payload[0].payload[displayName]}</div>
            </div>
            <div></div>
          </div>

        {payload.map((pld) => ((pld.dataKey !== NoDisplay) ? 
          <div style={{ display: "inline-block", padding: 5 }}>
            <div style={{ display: "flex", flexDirection: "row", gap: "3px" }}>
              <div className="bar" style={{ background: pld.fill }} />
              <div style={{ fontWeight: 500 }}>{pld.dataKey} - {(displayValue === 'Currency') ? 
                pld.value.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                            })
                : 
                pld.value.toLocaleString()}</div>
            </div>
          </div>
          :
          <></>
        ))}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
