import React from "react";

export default function ScatterCustomDot(props) {
  const { cx, cy, payload, selectedPoint, companyData } = props;

  const RADIUS_SELECTED = 6;
  const RADIUS_UNSELECTED = 3;
  const COLOR_SELECTED = companyData?.AccentColor;
  const COLOR_UNSELECTED = companyData?.AccentColorSecondary;
  
    const isSelected =
    selectedPoint?.ScoredProbabilities === payload?.ScoredProbabilities && selectedPoint?.AtRiskAmount === payload?.AtRiskAmount;

  return (
    <g
      className="custom-dot"
      data-chart-x={cx}
      data-chart-y={cy}
      data-x-value={payload.ScoredProbabilities}
      data-y-value={payload.AtRiskAmount}
      data-radius={isSelected ? RADIUS_UNSELECTED : RADIUS_SELECTED}
    >
      {!isSelected ? (
        <circle cx={cx} cy={cy} r={RADIUS_UNSELECTED} fill={COLOR_UNSELECTED} />
      ) : (
        <>
          <circle
            cx={cx}
            cy={cy}
            r={RADIUS_SELECTED / 2}
            fill={COLOR_SELECTED}
          />
          <circle
            cx={cx}
            cy={cy}
            r={RADIUS_SELECTED}
            fill="none"
            stroke={COLOR_SELECTED}
          />
        </>
      )}
    </g>
  );
}
