import React, { useRef, useState, useEffect } from 'react';
import { Button, Card, Modal, Table, Image, ToggleButton, ButtonGroup, ProgressBar } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getDivisionManagers, assignRestructureDetails, getOrgDivisionLeadershipAssessmentLevels, getOrgDivisionCompetencyAnalysis, getOrgDivisionPillarAnalysis } from '../../store/actions/authActions';
import { Tree } from 'react-arborist';
import OrganizationTree from './OrganizationTree';
import Spinner from '../layout/Spinner';
import { ResponsiveContainer, PieChart, Pie, Sector, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Rectangle, 
    ScatterChart, Scatter, LabelList, ReferenceArea, LineChart, Line, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar } from 'recharts';
import CustomTooltip from '../layout/CustomTooltip';
import CylinderBar from '../userentities/CylinderBar';

const Divisions = (props) => {
    const { auth, companyData, profile, mygroups, orgDivisions, restructureDetail, loadingMenu, orgDivisionSelectedLALs, orgDivisionSelectedCompetencyAnalysis, 
        orgDivisionSelectedPillarAnalysis } = props;    
    const [loading, setLoading] = useState(false);
    const [nodeName, setnodeName] = useState('');
    const [selectedNode, setSelectedNode] = useState('');
    const [radioValue, setRadioValue] = useState('2');
    const [viewMoreLALClicked, setViewMoreLALClicked] = useState(false);
    const radios = [
        { name: 'Small', value: '1' },
        { name: 'Medium', value: '2' },
        { name: 'Large', value: '3' },
    ];

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    function displayListRadioButtons() {
        return (
            radios.map((radio, idx) => (
                <ToggleButton
                    key={'mantree' + idx}
                    id={`radio-${'mantree' + idx}`}
                    type="radio"
                    style={radioValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    size='sm'
                    onChange={(e) => {setRadioValue(e.currentTarget.value)}}
                >
                    {radio.name}
                </ToggleButton>
            ))
        );
    }

    async function handleViewMoreLALClick() {
        setLoading(true);

        if (viewMoreLALClicked) {
            setViewMoreLALClicked(false);
        } else {
            setViewMoreLALClicked(true);
        }

        setLoading(false);
    }

    async function handleHideMoreLALClick() {
        setLoading(true);

        setViewMoreLALClicked(false);

        setLoading(false);
    }

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    async function treeSelectedNodes(nodes) {
        setLoading(true);
        const divisionId = nodes[0]?.data?.id;
        // console.log(nodes);
        if (divisionId) {
            setViewMoreLALClicked(false);
            setSelectedNode(nodes[0]?.data);
            await props.assignRestructureDetails(null);
            await props.getDivisionManagers(divisionId);
            await props.getOrgDivisionLeadershipAssessmentLevels(divisionId);
            await props.getOrgDivisionCompetencyAnalysis(divisionId);
            await props.getOrgDivisionPillarAnalysis(divisionId);
        }
        setLoading(false);
    }

    function Node({ node, style, dragHandle }) {
        /* This node instance can do many things. See the API reference. */
        if (node.isLeaf) {
            return (
                <div style={style} ref={dragHandle}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                        <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
                        <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z"/>
                    </svg>
                    {node.data.name}
              </div>
            );
        } else if (node.isClosed) {
            return (
                <div style={style} ref={dragHandle}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building-add" viewBox="0 0 16 16">
                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0"/>
                        <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6.5a.5.5 0 0 1-1 0V1H3v14h3v-2.5a.5.5 0 0 1 .5-.5H8v4H3a1 1 0 0 1-1-1z"/>
                        <path d="M4.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
                    </svg>
                    {node.data.name}
              </div>
            );
        } else {
            return (
            <div style={style} ref={dragHandle}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building-dash" viewBox="0 0 16 16">
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M11 12h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1"/>
                    <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6.5a.5.5 0 0 1-1 0V1H3v14h3v-2.5a.5.5 0 0 1 .5-.5H8v4H3a1 1 0 0 1-1-1z"/>
                    <path d="M4.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
                </svg>
                {node.data.name}
            </div>
            );
        }
    }

    function displayRestructureDetails() {
        if (restructureDetail && restructureDetail?.AdjustedTotalAmount) {
            if (restructureDetail?.AdjustedTotalAmount < 0) {
                return (
                    <tr>
                        <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <span style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <h5 style={styles.SubHeader}>Restructure Adjustments</h5>
                                            <Table responsive="sm" hover variant="light" style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                            <thead>
                                                <tr>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Total</h6></th>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Percentage</h6></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                        <div style={{ display: 'flex', justifyContent: "left" }}>
                                                            <h5 style={styles.SubHeaderSecondary}>{restructureDetail?.AdjustedTotalAmount.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                            })}</h5>&nbsp;&nbsp;
                                                        <span class="material-symbols-outlined" style={{color:companyData?.AccentColor, fontSize:'25px', cursor: 'pointer'}}>arrow_circle_down</span>
                                                        </div>
                                                    </td>
                                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                        <h5 style={styles.SubHeaderSecondary}>{((restructureDetail?.AdjustedTotalAmount * 100)/selectedNode?.Salaries).toFixed(2)}%</h5>                                                            
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </span>
                                </div>
                        </td>
                    </tr>
                );
            } else {
                return (
                    <tr>
                        <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <span style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <h5 style={styles.SubHeader}>Restructure Adjustments</h5>
                                            <Table responsive="sm" hover variant="light" style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                            <thead>
                                                <tr>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Total</h6></th>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Percentage</h6></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                        <div style={{ display: 'flex', justifyContent: "left" }}>
                                                            <h5 style={styles.SubHeaderSecondary}>{restructureDetail?.AdjustedTotalAmount.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                            })}
                                                            </h5>&nbsp;&nbsp;
                                                            <span class="material-symbols-outlined" style={{color:companyData?.AccentColor, fontSize:'25px'}}>arrow_circle_up</span>
                                                        </div>
                                                    </td>
                                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                        <h5 style={styles.SubHeaderSecondary}>{((restructureDetail?.AdjustedTotalAmount * 100)/selectedNode?.Salaries).toFixed(2)}%</h5>                                                            
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </span>
                                </div>
                        </td>
                    </tr>
                );
            }
        }                                                    
    }

    function displayViewMoreLALButton() {
        if (viewMoreLALClicked) {
            return (
                <a onClick={() => handleHideMoreLALClick()}>
                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide details'>shadow_minus</span>
                </a>
            );
        } else {
            return (
                <a onClick={() => handleViewMoreLALClick()}>
                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show details'>note_add</span>
                </a>

            );
        }
    }
    
    function displayDivisionLALCounts() {
        if (viewMoreLALClicked) {
            return (
                <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                    <thead>
                        <tr>
                            <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Leadership Assessment Level - Counts</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                <ResponsiveContainer width={'100%'} height={220}>
                                    <BarChart 
                                        data={orgDivisionSelectedLALs}
                                        >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="LeadershipAssessmentLevelID"/>
                                        <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                        <Tooltip content={<CustomTooltip displayName='LeadershipAssessmentLevelDescription'/>} formatter={(value) => value && value.toLocaleString()}/>
                                        <Bar dataKey="LeaderTypesSafe" stackId="a" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"LALSafeCount"} lowerbar={true} fatbar={true}/>} />
                                        <Bar dataKey="LeaderTypesAtRisk" stackId="a" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"LALAtRiskCount"} lowerbar={true} fatbar={true}/>} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </td>
                        </tr>
                    </tbody>
                </Table>     
            );
        } else {
            return (
                <></>
            );
        }
    }

    function displayDivisionLALAmounts() {
        if (viewMoreLALClicked) {
            return (
                <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                    <thead>
                        <tr>
                            <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Leadership Assessment Level - Amounts</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                <ResponsiveContainer width={'100%'} height={220}>
                                    <BarChart 
                                        data={orgDivisionSelectedLALs}
                                        >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="LeadershipAssessmentLevelID"/>
                                        <YAxis tickFormatter={tick => { return tick.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                            })}} domain={['auto', 'dataMax + 5']}/>
                                        <Tooltip content={<CustomTooltip displayName='LeadershipAssessmentLevelDescription' displayValue='Currency'/>} formatter={(value) => value && value.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                        })}/>
                                        <Bar dataKey="Salaries" stackId="a" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"LALSafeAmount"} lowerbar={true} fatbar={true}/>} />
                                        <Bar dataKey="AtRiskAmount" stackId="a" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"LALAtRiskAmount"} lowerbar={true} fatbar={true}/>} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </td>
                        </tr>
                    </tbody>
                </Table>     
            );
        } else {
            return (
                <></>
            );
        }
    }

    function displayDivisionRadarChart() {
        if ((orgDivisionSelectedPillarAnalysis) && (orgDivisionSelectedPillarAnalysis.length > 0)) {
            var currentDivision = orgDivisionSelectedPillarAnalysis[0];
            const radarChartData = [{
                Pillar:'Performance',
                UserScore: currentDivision.AveragePerformanceScore,
                fullMark: 5,
            },
            {
                Pillar:'Safety',
                UserScore: currentDivision.AverageWorkSafetyScore,
                fullMark: 5,
            },
            {
                Pillar:'Stress',
                UserScore: currentDivision.AverageJobStressScore,
                fullMark: 5,
            },
            {
                Pillar:'Satisfaction',
                UserScore: currentDivision.AverageJobSatisfactionScore,
                fullMark: 5,
            },
            {
                Pillar:'Social',
                UserScore: currentDivision.AverageSocialSupportScore,
                fullMark: 5,
            },
            {
                Pillar:'Control',
                UserScore: currentDivision.AverageControlScore,
                fullMark: 5,
            },
            {
                Pillar:'Efficacy',
                UserScore: currentDivision.AveragePersonalEfficacyScore,
                fullMark: 5,
            },
            {
                Pillar:'Turnover',
                UserScore: currentDivision.AverageTurnoverIntentionScore,
                fullMark: 5,
            },
            {
                Pillar:'Commitment',
                UserScore: currentDivision.AverageOrganizationalCommitmentScore,
                fullMark: 5,
            },
            {
                Pillar:'Cybersecurity',
                UserScore: currentDivision.AverageCyberSecurityScore,
                fullMark: 5,
            }];    

            if (viewMoreLALClicked) {
                return (
                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                        <thead>
                            <tr>
                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Pillar Analysis</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <ResponsiveContainer width={'100%'} height={220}>
                                        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarChartData}>
                                            <PolarGrid />
                                            <PolarAngleAxis dataKey="Pillar" />
                                            <PolarRadiusAxis />
                                            <Radar name={currentDivision.DivisionName} dataKey="UserScore" stroke={companyData?.AccentColorSecondary} fill={companyData?.AccentColorSecondary} fillOpacity={0.6} />
                                        </RadarChart>
                                    </ResponsiveContainer>
                                </td>
                            </tr>
                        </tbody>
                    </Table>     
                );
            } else {
                return (
                    <></>
                );
            }        
        } else  {
            return (
                <></>
            );
        }     
    }

    function displayCompliantPercentage(successor) {
        if (orgDivisionSelectedCompetencyAnalysis == null) {
            return (<></>);
        } else if (orgDivisionSelectedCompetencyAnalysis?.length == 0) {
            return (<></>);
        } else {
            var iJPEQGCount = orgDivisionSelectedCompetencyAnalysis[0]?.UserJPEvaluationGroupCount;
            var iUJPEQGCount = orgDivisionSelectedCompetencyAnalysis[0]?.UserJPEvaluationGroupMatchedCount;
    
            var iJPSkillCount = orgDivisionSelectedCompetencyAnalysis[0]?.UserJPSkillCount;
            var iUJPSkillCount = orgDivisionSelectedCompetencyAnalysis[0]?.UserJPSkillMatchedCount;
    
            var iJPRequirementCount = orgDivisionSelectedCompetencyAnalysis[0]?.UserJPRequirementCount;
            var iUJPRequirementCount = orgDivisionSelectedCompetencyAnalysis[0]?.UserJPRequirementMatchedCount;
               
            var iCompliantPercentage = 0;
            var sProgressBarVariant = 'danger';       
            var iJPEQGCompliantPercentage = 0;
            var sJPEQGProgressBarVariant = 'danger';       
            var iJPSkillCompliantPercentage = 0;
            var sJPSkillProgressBarVariant = 'danger';       
            var iJPRequirementCompliantPercentage = 0;
            var sJPRequirementProgressBarVariant = 'danger';       
    
            // console.log(iJPEQGCount.toLocaleString() + ':' + iJPRequirementCount.toLocaleString() + ':' + iJPSkillCount.toLocaleString());
            if ((iJPEQGCount > 0) || (iJPRequirementCount > 0) || (iJPSkillCount > 0)) {
                iCompliantPercentage =  Math.ceil((((iUJPEQGCount + iUJPRequirementCount + iUJPSkillCount) * 100) / (iJPEQGCount + iJPRequirementCount + iJPSkillCount)));
                if ((iCompliantPercentage >= 75) && (iCompliantPercentage <= 100)) {
                    sProgressBarVariant = 'success';       
                } else if ((iCompliantPercentage >= 50) && (iCompliantPercentage < 75)) {
                    sProgressBarVariant = 'warning';       
                }
            }
    
            if (iJPEQGCount > 0) {
                iJPEQGCompliantPercentage =  Math.ceil(((iUJPEQGCount * 100) / iJPEQGCount));
                if ((iJPEQGCompliantPercentage >= 75) && (iJPEQGCompliantPercentage <= 100)) {
                    sJPEQGProgressBarVariant = 'success';       
                } else if ((iJPEQGCompliantPercentage >= 50) && (iJPEQGCompliantPercentage < 75)) {
                    sJPEQGProgressBarVariant = 'warning';       
                }
            }
    
            if (iJPSkillCount > 0) {
                iJPSkillCompliantPercentage =  Math.ceil(((iUJPSkillCount * 100) / iJPSkillCount));
                if ((iJPSkillCompliantPercentage >= 75) && (iJPSkillCompliantPercentage <= 100)) {
                    sJPSkillProgressBarVariant = 'success';       
                } else if ((iJPSkillCompliantPercentage >= 50) && (iJPSkillCompliantPercentage < 75)) {
                    sJPSkillProgressBarVariant = 'warning';       
                }
            }
    
            if (iJPRequirementCount > 0) {
                iJPRequirementCompliantPercentage =  Math.ceil(((iUJPRequirementCount * 100) / iJPRequirementCount));
                if ((iJPRequirementCompliantPercentage >= 75) && (iJPRequirementCompliantPercentage <= 100)) {
                    sJPRequirementProgressBarVariant = 'success';       
                } else if ((iJPRequirementCompliantPercentage >= 50) && (iJPRequirementCompliantPercentage < 75)) {
                    sJPRequirementProgressBarVariant = 'warning';       
                }
            }

            return (
                <tr>
                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                            <div>
                                <span style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <h5 style={styles.SubHeader}>Competency & Gap Analysis</h5>
                                        <Table responsive="sm" hover variant="light" style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                            <thead>
                                                <tr>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor, width:'30%'}}><h6 style={styles.SubHeader}>Overall</h6></th>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor}}>
                                                            <ProgressBar animated variant={sProgressBarVariant} now={iCompliantPercentage} label={`${iCompliantPercentage}%`} style={styles.progressBarInnerText} />
                                                    </th>
                                                </tr>
                                            </thead>
                                        </Table>
                                        <Table responsive="sm" hover variant="light" style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                            <thead>
                                                <tr>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor, width:'30%'}}><h6 style={styles.SubHeader}>Performance</h6></th>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor, width:'10%'}}></th>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor}}>
                                                            <ProgressBar animated variant={sJPEQGProgressBarVariant} now={iJPEQGCompliantPercentage} label={`${iJPEQGCompliantPercentage}%`} style={styles.progressBarInnerText} />
                                                    </th>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor, width:'10%'}}></th>
                                                </tr>
                                                <tr>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor, width:'30%'}}><h6 style={styles.SubHeader}>Competency & Skill</h6></th>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor, width:'10%'}}></th>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor}}>
                                                            <ProgressBar animated variant={sJPSkillProgressBarVariant} now={iJPSkillCompliantPercentage} label={`${iJPSkillCompliantPercentage}%`} style={styles.progressBarInnerText} />
                                                    </th>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor, width:'10%'}}></th>
                                                </tr>
                                                <tr>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor, width:'30%'}}><h6 style={styles.SubHeader}>Job Requirement</h6></th>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor, width:'10%'}}></th>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor}}>
                                                            <ProgressBar animated variant={sJPRequirementProgressBarVariant} now={iJPRequirementCompliantPercentage} label={`${iJPRequirementCompliantPercentage}%`} style={styles.progressBarInnerText} />
                                                    </th>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor, width:'10%'}}></th>
                                                </tr>
                                            </thead>
                                        </Table>
                                </span>
                            </div>
                    </td>
                </tr>
            );    
        }
    }

    function displayDivisionOverallNumbers() {
        if ((selectedNode) && (selectedNode?.id)) {
        return (
                <Table responsive="sm" variant="light" style={{width:'100%', height:'100%', backgroundColor:companyData?.PagesBGColor}}>
                <thead>
                    <tr>
                        <th style={{textAlign:'center', backgroundColor:companyData?.PagesBGColor}}><h5 style={styles.SubHeaderCenter}>{selectedNode?.name}</h5></th>
                        <th style={{backgroundColor:companyData?.PagesBGColor, alignItems:'right'}}><h6 style={styles.SubHeader}>{displayViewMoreLALButton()}</h6></th>                                                
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <span><h5 style={styles.SubHeader}>Employees</h5></span>
                                    <Table responsive="sm" hover variant="light" style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                        <thead>
                                            <tr>
                                                <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Total</h6></th>
                                                <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>At Risk</h6></th>
                                                <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Percentage</h6></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                    <h1 style={styles.SubHeaderSecondary}>{selectedNode?.SafeCount + selectedNode?.AtRiskCount}</h1>                                                            
                                                </td>
                                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                    <h1 style={styles.SubHeaderPrimary}>{selectedNode?.AtRiskCount}</h1>                                                            
                                                </td>
                                                <td colSpan='2' style={{backgroundColor:companyData?.PagesBGColor}}>
                                                    <h1 style={styles.SubHeaderPrimary}>{((selectedNode?.AtRiskCount * 100)/(selectedNode?.SafeCount + selectedNode?.AtRiskCount)).toFixed(2)}%</h1>                                                            
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                        </td>
                    </tr>   
                    <tr>
                        <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <span style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <h5 style={styles.SubHeader}>Compensations</h5>
                                            <Table responsive="sm" hover variant="light" style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                            <thead>
                                                <tr>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Total</h6></th>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Retention At Risk</h6></th>
                                                    {/* <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Percentage</h6></th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                        <h1 style={styles.SubHeaderSecondary}>{selectedNode?.Salaries.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                        })}</h1>
                                                    </td>
                                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                    <h1 style={styles.SubHeaderPrimary}>{selectedNode?.AtRiskAmount.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                        })}</h1>
                                                    </td>
                                                    {/* <td style={{backgroundColor:pagesbgcolor}}>
                                                        <h1 style={styles.SubHeaderPrimary}>{((selectedNode?.AtRiskCount * 100)/selectedNode?.SafeCount).toFixed(2)}%</h1>                                                            
                                                    </td> */}
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </span>
                                </div>
                        </td>
                    </tr>
                    {displayCompliantPercentage(null)}
                    {displayRestructureDetails()}
                </tbody>
            </Table>                                                    

            );
        }
    }

    return (
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Table style={{background:'transparent'}} >
                    <thead>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                    <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                                        <Card.Title>
                                            <div>
                                                <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                                                    <tbody>
                                                    <tr>
                                                        <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Organization Tree</h3></td>
                                                        <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                                                            <div className='input-field'>
                                                                <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding} onChange={setSearchNodeName} placeholder='Search Division'/>
                                                            </div>                                            
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </Table>                            
                                            </div>
                                        </Card.Title>
                                    </Card.Header>                   
                                    <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                        <Table responsive="sm" bordered style={{backgroundColor:companyData?.PagesBGColor, padding:'20px 20px 20px 20px'}}>
                                            <tbody>
                                                <tr>
                                                    <td style={{textAlign:'left', width:'55%', backgroundColor:companyData?.PagesBGColor, 
                                                                padding:'0px 0px 0px 40px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                        <Tree 
                                                            width='100%'
                                                            data={orgDivisions} 
                                                            openByDefault={false}
                                                            indent={30}
                                                            padding={10}
                                                            searchTerm={nodeName}
                                                            searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}   
                                                            onSelect={(nodes) => {treeSelectedNodes(nodes)}}         
                                                            disableEdit={false}                                    
                                                            disableDrag={false}                                    
                                                            disableDrop={false}                                    
                                                            disableMultiSelection={false}                                    
                                                        >
                                                            {/* {Node} */}
                                                        </Tree>
                                                    </td>
                                                    <td style={{textAlign:'center', width:'45%', backgroundColor:companyData?.PagesBGColor, 
                                                                padding:'0px 00px 0px 0px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                            {displayDivisionOverallNumbers()}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>                            
                                        <Table style={{backgroundColor:companyData?.PagesBGColor, padding:'20px 20px 20px 20px'}}>
                                            <tbody>
                                                <tr>
                                                    <td style={{textAlign:'center', width:'40%', backgroundColor:companyData?.PagesBGColor, 
                                                                padding:'0px 50px 0px 0px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                        {displayDivisionRadarChart()}
                                                    </td>
                                                    <td style={{textAlign:'center', width:'30%', backgroundColor:companyData?.PagesBGColor, 
                                                                padding:'0px 50px 0px 0px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                        {displayDivisionLALCounts()}
                                                    </td>
                                                    <td style={{textAlign:'center', width:'30%', backgroundColor:companyData?.PagesBGColor, 
                                                                padding:'0px 50px 0px 0px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                        {displayDivisionLALAmounts()}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>                            
                                    </Card.Body>
                                    <Card.Footer className="text-left">
                                    </Card.Footer>
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                {(loading) ? (            
                                    <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                        <Spinner/>
                                    </div>
                                ) 
                                : 
                                (                                          
                                    <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                                            <Card.Title>
                                                <div>
                                                    <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                                                        <tbody>
                                                        <tr>
                                                            <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Management Tree</h3></td>
                                                            <td style={{textAlign:'right', width:'40%', backgroundColor:companyData?.PagesBGColor}}>
                                                                <ButtonGroup className="mb-2" size='sm'>
                                                                    {displayListRadioButtons()}
                                                                </ButtonGroup>                                                                   
                                                            </td>                                                            
                                                        </tr>
                                                        </tbody>
                                                    </Table>                            
                                                </div>
                                            </Card.Title>
                                        </Card.Header>                   
                                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                            <OrganizationTree zoomLevel={radioValue}/>
                                        </Card.Body>
                                    </Card>                                    
                                )}
                            </td>
                        </tr>
                    </tbody>
                </Table>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      mygroups: state.auth.mygroups,
      orgDivisions: state.auth.orgDivisions,
      restructureDetail: state.auth.restructureDetail,
      loadingMenu: state.auth.loadingMenu,
      orgDivisionSelectedLALs: state.auth.orgDivisionSelectedLALs,
      orgDivisionSelectedCompetencyAnalysis: state.auth.orgDivisionSelectedCompetencyAnalysis,
      orgDivisionSelectedPillarAnalysis: state.auth.orgDivisionSelectedPillarAnalysis,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDivisionManagers: (id) => dispatch(getDivisionManagers(id)),
        assignRestructureDetails: (rParams) => dispatch(assignRestructureDetails(rParams)),
        getOrgDivisionLeadershipAssessmentLevels: (id) => dispatch(getOrgDivisionLeadershipAssessmentLevels(id)),
        getOrgDivisionCompetencyAnalysis: (id) => dispatch(getOrgDivisionCompetencyAnalysis(id)),
        getOrgDivisionPillarAnalysis: (id) => dispatch(getOrgDivisionPillarAnalysis(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Divisions)