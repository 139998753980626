import React, { useEffect, useState } from 'react'
import { Card, Table, ButtonGroup, ToggleButton } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { styles } from '../layout/styles'
import moment from 'moment';
import { getEvaluation, getEvaluationQuestions, getEvaluationRatings } from '../../store/actions/entityActions';
import { connect } from 'react-redux'
import { RetentasSurveys } from '../../config/Constant';


const UserEvaluationSummary = (props) => {
    const { userevaluationsessions, processleveltypeid, companyData, uesid } = props;
    const typeCompanySummary = (companyData.CompanyID === '32') ? 2 : 12;
    const typeSummary = (processleveltypeid === null) ? typeCompanySummary : processleveltypeid;
    const navigate = useNavigate();
    const [radioValue, setRadioValue] = useState('1');
    const radios = [
        { name: 'Assigned', value: '1' },
        { name: 'Completed', value: '2' },
    ];
    const [loading, setloading] = useState(false);
    const [currentUESID, setcurrentUESID] = useState(-1);
    let currentRecIndex = 0;

    useEffect(() => {
        if ((uesid) && (uesid > 0)) {
            if (userevaluationsessions != null) {
                var launchUserEvalRow = userevaluationsessions?.filter((usereval) => (parseInt(usereval.UserEvaluationSessionID) === parseInt(uesid)));
                if ((launchUserEvalRow) && (launchUserEvalRow.length > 0)) {
                    handleLaunchEvaluation(launchUserEvalRow[0].EvaluationID, uesid);
                }
            }
        }
    }, [])  

    function getcurrentRecordIndex(){
        currentRecIndex++;        
        return currentRecIndex;
    }

    async function handleLaunchEvaluation(evalid, userevalsessionid) {
        //e.preventDefault();
        try {
            setcurrentUESID(userevalsessionid);
            setloading(true);
            await props.getEvaluation(evalid);
            await props.getEvaluationQuestions(evalid);
            await props.getEvaluationRatings(evalid);
            setloading(false);
            setcurrentUESID(-1);
            navigate('/launchevaluation/' + userevalsessionid);    
        } catch (err){
            console.log('Error is getting evaluation details - ' + evalid);
            setloading(false);
        }
    }

    return ( 
        <div>
        <Card className="text-center">
        <Card.Header style={{backgroundColor:companyData?.PagesBGColor}}> 
            <Table responsive="lg" >
                    <thead>
                    <tr style={{backgroundColor:companyData?.PagesBGColor}}>
                        <th style={{width:'40%', backgroundColor:companyData?.PagesBGColor, alignItems:'left'}}>
                            {processleveltypeid === null ?
                                    <h3 style={styles.SubHeader}>Performance Reviews</h3>
                                :
                                    <h3 style={styles.SubHeader}>Questionnaires</h3>
                                }                                
                        </th>
                        <th style={{alignItems:'center', backgroundColor:companyData?.PagesBGColor}}>
                            <Card.Title>
                            </Card.Title>
                        </th>
                        <th style={{width:'20%', alignItems:'right', backgroundColor:companyData?.PagesBGColor, color:companyData?.AccentColor}}>
                                <ButtonGroup className="mb-2" size='sm'>
                                    {radios.map((radio, idx) => (
                                        <ToggleButton
                                            key={typeSummary + idx}
                                            id={`radio-${typeSummary + idx}`}
                                            type="radio"
                                            style={radioValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                                            name="radio"
                                            value={radio.value}
                                            checked={radioValue === radio.value}
                                            size='sm'
                                            onChange={(e) => {currentRecIndex = 0; setRadioValue(e.currentTarget.value)}}
                                        >
                                            {radio.name}
                                        </ToggleButton>
                                    ))}
                                </ButtonGroup>                        
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    </tbody>
            </Table>

        </Card.Header>
        <Card.Body style={{backgroundColor:companyData?.PagesBGColor}}>

        {(userevaluationsessions.length === 0) ? (
                    <div style={styles.tableText}>
                        No previous performance reviews.
                    </div>
                ) : (
                    <div>
                        <Table responsive="sm" striped bordered hover variant="light">
                            <thead>
                            <tr>
                                <th style={styles.tableText}>#</th>
                                <th style={styles.tableText}>Name</th>
                                <th style={styles.tableText}>Assigned</th>
                                <th style={styles.tableText}>Due</th>
                                <th style={styles.tableText}>Completed</th>
                                <th style={styles.tableText}>Launch</th>
                            </tr>
                            </thead>
                            <tbody>
                            {userevaluationsessions && userevaluationsessions.map((userevaluation, index) => {
                                    if (userevaluation.ProcessLevelTypeID === typeSummary) {
                                        const isCompleted = userevaluation.CompletedDate ? 1 : 2
                                        const displayEvalName = (typeSummary === RetentasSurveys) ? userevaluation?.EvaluationName : userevaluation?.AssignmentName;
                                        if ((radioValue !== isCompleted) && (userevaluation?.UserEvaluationTypeID !== 5) && (userevaluation?.UserEvaluationTypeID !== 6)) {
                                            return (
                                                <tr>
                                                    <td style={styles.tableText}>{getcurrentRecordIndex()}</td>
                                                    <td style={styles.tableTextSubHeader}>{displayEvalName}</td>
                                                    <td style={styles.tableText}>{userevaluation?.AssignedDate && moment(userevaluation?.AssignedDate).format("MM/DD/YYYY")}</td>
                                                    <td style={styles.tableText}>{userevaluation?.DueDate && moment(userevaluation?.DueDate).format("MM/DD/YYYY")}</td>
                                                    <td style={styles.tableText}>{userevaluation?.CompletedDate && moment(userevaluation?.CompletedDate).format("MM/DD/YYYY")}</td>
                                                    <td style={styles.tableText}>      
                                                        {(userevaluation?.CompletedDate) ? 
                                                            <a>
                                                                <span class="material-symbols-outlined" style={{color:companyData?.Grey, fontSize:'25px', cursor: 'pointer'}}>monitoring</span>
                                                            </a>
                                                            :
                                                            ((loading && userevaluation?.UserEvaluationSessionID === currentUESID) ?    
                                                                <div>                                                    
                                                                    <span class="material-symbols-outlined" style={{color:companyData?.AccentColor, fontSize:'25px', cursor: 'pointer'}}>rocket_launch</span>
                                                                    <span style={styles.LinkBoldGrey}>Launching...</span>
                                                                </div>
                                                            :
                                                            <a onClick={() => handleLaunchEvaluation(userevaluation?.EvaluationID, userevaluation?.UserEvaluationSessionID)}> 
                                                                <span class="material-symbols-outlined" style={{color:companyData?.Grey, fontSize:'25px', cursor: 'pointer'}}>rocket</span>
                                                            </a>)
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        } else 
                                        {
                                            return (<></>)
                                        }
                                    }
                                }
                            ) 
                            }    

                            </tbody>
                        </Table>        
                </div>
                )}

            <div>
            </div>

            </Card.Body>

            <Card.Footer className="text-right" style={{alignItems:'end', backgroundColor:companyData?.PagesBGColor}}>
                <div>
                </div>
            </Card.Footer>
            </Card>
        </div>
    )        

}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth.user,
        companyData: state?.auth?.companyData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEvaluation: (id) => dispatch(getEvaluation(id)),
        getEvaluationQuestions: (id) => dispatch(getEvaluationQuestions(id)),
        getEvaluationRatings: (id) => dispatch(getEvaluationRatings(id)),
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(UserEvaluationSummary)
