import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, Modal, Table, Image, Nav, NavItem, Tooltip, Badge, CardImg, CardTitle, ButtonGroup } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { Tree, TreeNode } from 'react-organizational-chart';
import { getMyTeamStatSelected, assignRestructureDetails } from '../../store/actions/authActions';
import { getJobPositions } from '../../store/actions/entityActions';

import Spinner from '../layout/Spinner';
import JobPositions from '../auth/JobPositions'

const OrganizationTree = (props) => {
    const { auth, companyData, profile, mygroups, orgDivisions, orgDivisionManagers, zoomLevel, myTeamStatSelected, lookupselections, jobpositions } = props;    
    const [loading, setloading] = useState(false);
    const [zoomPer, setZoomPer] = useState('75%');
    const [sliderValue, setSliderValue] = useState([]);
    const [showRestructure, setShowRestructure] = useState(false);
    const [restructureUserID, setRestructureUserID] = useState(-1);
    const [restructureUserFullName, setRestructureUserFullName] = useState('');
    const [applyRestructureNumbers, setApplyRestructureNumbers] = useState(false);
    const [restructureTotalAmount, setRestructureTotalAmount] = useState(-1);
    const [restructureAtRiskAmount, setRestructureAtRiskAmount] = useState(-1);
    const [addNewJobPositionClicked, setAddNewJobPositionClicked] = useState(false);
    const [careerJobPositionID, setCareerJobPositionID] = useState(-1);
    const [careerJobPositionName, setCareerJobPositionName] = useState('');
    const [careerJobPositionSalary, setCareerJobPositionSalary] = useState('');
    const [showJPList, setShowJPList] = useState(false);

    const handleCloseJPList = () => {
        setShowJPList(false);
        updateJobPositionSelection();
    }

    async function handleShowJPList() {
        setloading(true);
        const jpParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getJobPositions(jpParams);
        setShowJPList(true); 
        setloading(false);
    };

    function updateJobPositionSelection() {
        const sSelections = lookupselections?.JobPosition;
        console.log(sSelections);
        if (sSelections != null) {
            setCareerJobPositionID(sSelections.split('&')[0].split('=')[0]);
            setCareerJobPositionName(sSelections.split('&')[0].split('=')[1].split('|')[0]);
            setCareerJobPositionSalary(sSelections.split('&')[0].split('=')[1].split('|')[1]);

            const newJPAddition = [{
                JobPositionID:sSelections.split('&')[0].split('=')[0], 
                JobPositionName:sSelections.split('&')[0].split('=')[1].split('|')[0],
                AtRiskAmount:sSelections.split('&')[0].split('=')[1].split('|')[1],
                currentJobs:1,
                newJobs:1,
                newlyAdded:true,
            }];

            console.log(newJPAddition);
            const oldSliderValues = sliderValue.slice(0);
            const newSliderValues = newJPAddition.concat(oldSliderValues);
            console.log(newSliderValues);
            setSliderValue(newSliderValues);
        }
    }

    async function handleShowRestructureDialog() {
        setShowRestructure(true); 
    };

    async function handleCloseRestructureDialog() {
        setShowRestructure(false);
        let adjustedTotalAmt = 0;
        let adjustedAtRiskAmt = 0;
        sliderValue.map((row, index) => {
            if (row.newlyAdded) {
                adjustedTotalAmt += (row.AtRiskAmount * row.newJobs);
            } else if (row.newJobs > row.currentJobs) {
                adjustedTotalAmt += (row.AtRiskAmount * (row.newJobs - row.currentJobs));
            } else if (row.newJobs < row.currentJobs) {
                adjustedTotalAmt -= (row.AtRiskAmount * (row.currentJobs - row.newJobs));
            }            
        });
        console.log(adjustedTotalAmt);
        const rParams = {
            AdjustedTotalAmount:adjustedTotalAmt,
        };
        await props.assignRestructureDetails(rParams);
    }

    async function showRestructureDialog(userid, name) {
        setRestructureUserID(userid);
        setRestructureUserFullName(name);
        setloading(true);
        await props.getMyTeamStatSelected(userid, auth?.CompanyID);
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray();
        setSliderValue(initialScoreValues);
        setShowRestructure(true);
        setloading(false);
    }

    function getSuccessorDetailSwitchValue(jpIndex) {
        let bReturn = false;
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (parseInt(row.JobPositionID) === parseInt(jpIndex))) {       
                bReturn = row.newJobs;
            }
        });
        return bReturn;
    }
 
    function handleSuccessorAddChange(value, jpIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        const previousShowValue = getSuccessorDetailSwitchValue(jpIndex);
        updatedSliderValues.map((row, index) => {
            if (row.JobPositionID === jpIndex) {
                row.newJobs = value;
            }
        });
        setSliderValue(updatedSliderValues);
    }

    useEffect(() => {
        if (!jobpositions) {
            setloading(true);
            const jpParams = {
                RecordSize:'10',
                PageNumber:'1',
            };            
            props.getJobPositions(jpParams);
            setloading(false);    
        }
    }, [])

    useEffect(() => {
        if (zoomLevel == '1') {
            setZoomPer('50%');
        } else if (zoomLevel == '2') {
            setZoomPer('75%');
        } else {
            setZoomPer('normal');
        }
    }, [zoomLevel])

    const addInitialSliderArray = () => {
        if (myTeamStatSelected?.JobPositionAmount) {
            var uniqueJP = myTeamStatSelected?.JobPositionSalaries;
            if (uniqueJP) {
                return uniqueJP?.map((jpDetail) => ({
                    JobPositionID:jpDetail.JobPositionID, 
                    JobPositionName:jpDetail.JobPositionName,
                    AtRiskAmount:jpDetail.AverageSalary,
                    currentJobs:1,
                    newJobs:1,
                    newlyAdded:false,
                }))
            }
        } 
    }
        
    function handleAddChildernClick() {
        (addNewJobPositionClicked) ? setAddNewJobPositionClicked(false) : setAddNewJobPositionClicked(true);
    }

    function displayNewJobPosition() {
        if (addNewJobPositionClicked) {
            return (
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <a onClick={() => setShowJPList(true)}>
                                        <label style={styles.LabelDataEntryNoPadding}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Job Position List'>mystery</span>                                    
                                            Job Position
                                        </label>
                                    </a>
                                    <input id="jpid" type="text" disabled style={styles.TextDataEntryNoPadding} value={careerJobPositionName}/>                                    
                                </div>
                            </td>
                        </tr>                
                    </tbody>
                </table>                                   
            );
        }
    }

    function displayRestructureDialogHeader() {
        return (
            <Table responsive="lg" >
                <thead>
                <tr>
                    <th style={{ width:'90%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> <h3 style={styles.SubHeader}>{restructureUserFullName}</h3>
                    </th>
                    <th style={{ width:'10%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                    {(addNewJobPositionClicked) ? 
                        <a onClick={() => handleAddChildernClick()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Add Job Position'>shadow_minus</span>
                        </a>
                        :
                        <a onClick={() => handleAddChildernClick()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Add Job Position'>note_add</span>
                        </a>
                    }
                    </th>
                </tr>
                </thead>
                <tbody>
                </tbody>
            </Table>
        );
    }

    function displayRestructureJobPositions() {
        // if (myTeamStatSelected?.JobPositionAmount) {
        //     var topJPs = myTeamStatSelected?.JobPositionAmount.slice(0,20);


        if (sliderValue) {
            var topJPs = sliderValue.slice(0,20);
            if (topJPs.length !== 0) {
                return (
                    <>
                    {displayNewJobPosition()}                    
                    <table class="table table-sm caption-top text-center">  
                        <thead>
                            <tr>
                                <th style={styles.tableText}>Change</th>
                                <th style={styles.tableTextLeft}>Name</th>
                                <th style={styles.tableText}>Average Salary</th>
                            </tr>
                        </thead>                        
                        <tbody> 
                            {topJPs.map((jp, supIndex) => (
                                <tr> 
                                    <td style={styles.tableText}>
                                        <input id="jobcount" type="number" min='0' step='1' defaultValue={getSuccessorDetailSwitchValue(jp?.JobPositionID)} style={styles.TextDataEntryNoPaddingSmall} onChange={(e) => (handleSuccessorAddChange(e.target.value, jp?.JobPositionID))}/>
                                    </td> 
                                    <td style={styles.tableTextLeft}>{jp?.JobPositionName}</td> 
                                    <td style={styles.tableText}>{jp?.AtRiskAmount.toLocaleString('en-US', {
                                                                    style: 'currency',
                                                                    currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                                }) }</td> 
                                </tr> 
                            ))}
                        </tbody>
                    </table>                                       
                    </>
                );
            } else {
                return (<></>);
            }
        } 
    }

    function displayRestructureDialog() {
        return (
            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    {displayRestructureDialogHeader()}
                </Card.Header>
                    <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {(myTeamStatSelected?.JobPositionAmount.length === 0) ? (
                                <div style={styles.tableText}>
                                    No Job Positions available.
                                </div>
                            ) : (
                                <div>
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                        </thead>
                                        <tbody>
                                            {displayRestructureJobPositions()}
                                        </tbody>
                                    </Table>        
                                </div>
                            )}
                        </Card.Body>
                <Card.Footer className="text-right" style={{alignItems:'end'}}>
                </Card.Footer>
            </Card>
        );
    }

    function displayRootNode(root) {
        return (
            // <Card style={{ width: '10rem', display:'inline-block', backgroundColor:pagesbgcolor }}>{root.name}</Card>
            <Card style={{ width: '10rem', display:'inline-block', backgroundColor:companyData?.PagesBGColor }}>
                <CardHeader>
                    <Card.Title>{root.name}</Card.Title>
                    <Card.Text>
                        {(root.JobPositionDescription) ? root.JobPositionDescription : ''}
                    </Card.Text>
                </CardHeader>
                {((root.AtRiskCount) && (root.AtRiskCount !== 0)) ? (
                    <Card.Body>
                        <Card.Text>
                            At Risk
                            <Table style={{background:'transparent'}} >
                                <tbody>
                                    <tr>
                                        <td style={{color:companyData?.AccentColor, background:'transparent', textAlign:'center'}} >
                                            {((root.AtRiskCount) && (root.AtRiskCount !== 0)) ? root.AtRiskCount.toLocaleString() + ' Employees': ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{color:companyData?.AccentColor, background:'transparent', textAlign:'center'}} >
                                            {((root.AtRiskAmount) && (root.AtRiskAmount !== 0)) ? root.AtRiskAmount.toLocaleString('en-US', {
                                                                    style: 'currency',
                                                                    currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                                }) : ''}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Text>
                    </Card.Body>
                    )
                    : (<></>)
                }
            </Card>                  
        );
    }

    function displayChildNode(child) {
        return (
            // <Card style={{ width: '10rem', display:'inline-block', backgroundColor:pagesbgcolor }}>{child.name}</Card>
            <Card style={{ width: '10rem', display:'inline-block', backgroundColor:companyData?.PagesBGColor }}>
                <Card.Header>
                    <Card.Title>
                        {child.name}
                    </Card.Title>
                    <Card.Text>
                        {(child.JobPositionDescription) ? child.JobPositionDescription : ''}
                    </Card.Text>
                </Card.Header>
                {((child.AtRiskCount) && (child.AtRiskCount !== 0)) ? (
                    <Card.Body>
                        <Card.Text>                            
                            <label style={styles.LabelDataEntryNoPadding}>At Risk</label>&nbsp;&nbsp;
                            <a onClick={() => {showRestructureDialog(child?.id, child?.name)}}>
                                <span style={styles.ActionInsideIconGrey} class="material-symbols-outlined" title='Restructure Org'>account_tree</span>
                            </a>

                            <Table style={{background:'transparent'}} >
                                <tbody>
                                    <tr>
                                        <td style={{color:companyData?.AccentColor, background:'transparent', textAlign:'center'}} >
                                            {((child.AtRiskCount) && (child.AtRiskCount !== 0)) ? child.AtRiskCount.toLocaleString() + ' Employees': ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{color:companyData?.AccentColor, background:'transparent', textAlign:'center'}} >
                                            {((child.AtRiskAmount) && (child.AtRiskAmount !== 0)) ? child.AtRiskAmount.toLocaleString('en-US', {
                                                                    style: 'currency',
                                                                    currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                                }) : ''}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Text>
                    </Card.Body>
                    )
                    : (<></>)
                }
            </Card>  
        )
    }

    function CheckAndDisplayChildren(parent) {
        if (parent?.children && parent?.children.length !== 0) {
            return displayChildNodes(parent.children);
        }
    }

    function displayChildNodes(parent) {
        return (
            parent?.map((child) => (
                    <TreeNode label={displayChildNode(child)}>
                        {CheckAndDisplayChildren(child)}
                        {/* {(child?.children && child?.children.length !== 0) ? (displayChildNodes(child.children)) : ('')} */}
                    </TreeNode>
                )
            )
        );
    }

    function displayOrgTree() {
        if (orgDivisionManagers) {
            return (
                <Tree
                    lineWidth={'2.5px'}
                    lineColor={companyData?.AccentColorSecondary}
                    lineBorderRadius={'10px'}
                    label={displayRootNode(orgDivisionManagers[0])}
                >
                    {((orgDivisionManagers) && (orgDivisionManagers.length !== 0) && (orgDivisionManagers[0]?.children)) ? 
                            (displayChildNodes(orgDivisionManagers[0].children)) 
                        : 
                            (<></>)}
                </Tree>
            );
        } else {
            return (<></>);
        }

    }

    function StyledTreeExample() { 
        return (
            <Tree
                lineWidth={'2px'}
                lineColor={companyData?.AccentColor}
                lineBorderRadius={'10px'}
                label={<Card>Root</Card>}
            >
                <TreeNode label={<Card>Child 1</Card>}>
                    <TreeNode label={<Card>Grand Child</Card>}>
                    </TreeNode>
                </TreeNode>
                <TreeNode label={<Card>Child 2</Card>}>
                <TreeNode label={<Card>Grand Child</Card>}>
                    <TreeNode label={<Card>Great Grand Child 1</Card>} />
                    <TreeNode label={<Card>Great Grand Child 2</Card>} />
                </TreeNode>
                </TreeNode>
                <TreeNode label={<Card>Child 3</Card>}>
                <TreeNode label={<Card>Grand Child 1</Card>} />
                <TreeNode label={<Card>Grand Child 2</Card>} />
                </TreeNode>
            </Tree>
        );
    }

    return (
        <>
            <Modal style={{background:'transparent', maxHeight:'100%'}} show={showRestructure} onShow={handleShowRestructureDialog} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseRestructureDialog}>
                <Modal.Header closeButton>
                <Modal.Title>Restructure</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {displayRestructureDialog()}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleCloseRestructureDialog}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal style={{background:'transparent', maxHeight:'100%'}} show={showJPList} onShow={handleShowJPList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseJPList}>
                <Modal.Header closeButton>
                <Modal.Title>Lookup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <JobPositions fromLookupParam={true}/>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleCloseJPList}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

        {(loading) ? (            
            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                <Spinner/>
            </div>
        ) 
        : 
        (                                          
        <div style={{width:'90vw', maxHeight:'80vh', display:'inline-block', overflow:'auto'}}>
            {/* {StyledTreeExample()} */}
            <div style={{zoom:zoomPer}}>
                {displayOrgTree()}
            </div>
        </div>
        )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      mygroups: state.auth.mygroups,
      orgDivisions: state.auth.orgDivisions,
      orgDivisionManagers: state.auth.orgDivisionManagers,
      myTeamStatSelected: state.auth.myTeamStatSelected,
      lookupselections: state.entity.lookupselections,
      jobpositions: state?.entity?.jobpositions,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getMyTeamStatSelected: (id, cid) => dispatch(getMyTeamStatSelected(id, cid)),
        assignRestructureDetails: (rParams) => dispatch(assignRestructureDetails(rParams)),
        getJobPositions: (jpParams) => dispatch(getJobPositions(jpParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationTree)