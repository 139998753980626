import React from "react"
import { Navigate } from "react-router-dom"
import { connect, useSelector } from 'react-redux'

function PrivateRoute({ children, ...rest }) {
    const userId = useSelector((state) => state?.auth?.user?.UserID)
    //console.log(userId);

    // if (!userId){
    //     return (
    //       <div>Loading...</div>
    //     )
    //   } else {
    //     return ((userId != null) && (userId != undefined)) ? children : <Navigate to="/login" />
    // }    

    return ((userId != null) && (userId != undefined)) ? children : <Navigate to="/login" />
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.user,
        profile: state.auth.profile
    }
}

export default connect(mapStateToProps)(PrivateRoute)