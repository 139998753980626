import React from "react";

const CustomLineChartLegend = ({ active, payload, label, displayName, displayValue, NoDisplay, accentColor, accentColorSecondary }) => {
    return (
        <div class="recharts-legend-wrapper" style={{height:'auto'}}>
            <ul class="recharts-default-legend" style={{padding:'0px', margin:'0px', textAlign:'center'}}>
                <li class="recharts-legend-item legend-item-0" style={{display:'inline-block', marginRight:'10px'}}>
                    <span class="material-symbols-outlined" style={{color:accentColorSecondary}}> timeline </span>
                    <span class="recharts-legend-item-text" style={{color:accentColorSecondary}}>Industry</span>
                </li>
                <li class="recharts-legend-item legend-item-0" style={{display:'inline-block', marginRight:'10px'}}>
                    <span class="material-symbols-outlined" style={{color:accentColor}}> timeline </span>
                    <span class="recharts-legend-item-text" style={{color:accentColor}}>Score</span>
                </li>
            </ul>
        </div>
    );
  }

export default CustomLineChartLegend;
