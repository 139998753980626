import authReducer from "./authReducer";
import entityReducer from "./entityReducer";
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'entity']
}

// const rootReducer = (state, action) => {
//     console.log(action);
//     if (action.type === 'RESET') {
//       return rootReducer(null, action)
//     }

//     // const rootReducer = combineReducers({
//     //     auth: authReducer,
//     //     entity: entityReducer,
//     // })

//     // return rootReducer;

//     // return combineReducers({
//     //     auth: authReducer,
//     //     entity: entityReducer,
//     // })    
// }

const rootReducer = combineReducers({
    auth: authReducer,
    entity: entityReducer,
})

export default persistReducer(persistConfig, rootReducer);
