import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
import { styles } from '../layout/styles'
//import { Image } from 'react-bootstrap'
import { grey, white } from '../../config/Colors';

const SignedInLinks = (props) => {
    const navigate = useNavigate();

    function handleLogout(e) {
        props.signOut();
        navigate('/Login');
    }    

    return (
        <div>
            <ul className='right'>
                {/* <li><NavLink style={styles.MenuLink} to="/chatgpt">Chat</NavLink></li> */}
                <li><a style={styles.MenuLink} onClick={handleLogout}>Log Out</a></li>
                <li>
                    <NavLink to="/UpdateProfile">                    
                        {(props.profile?.profileimageurl === undefined) ? 
                            <span class="material-symbols-outlined" style={{color:'#D3D3D3', fontSize:'30px'}}>account_circle</span>
                            :
                            <span class="material-symbols-outlined" style={{color:'#D3D3D3', fontSize:'30px'}}>account_circle</span>
                        } 
                        {/* <Image style={styles.Image60Border} src={props.profile.profileimageurl} roundedCircle class="border"></Image> */}
                        {/* {props.profile.initials} */}
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignedInLinks)