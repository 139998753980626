import axios from "axios";

const RetentasMLAPI = axios.create();


RetentasMLAPI.interceptors.request.use(async req => {
    req.headers.ContentType = 'application/json';   
    req.headers.Authorization = `Bearer ${process.env.REACT_APP_RETENTASAPI_RETENTASMLKEY}`
    console.log("Request Header - " + req.headers.Authorization);
    console.log("Request Header - " + req.headers.ContentType);
    return req;
})

export default RetentasMLAPI;
