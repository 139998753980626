import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, Modal, Table, Image, Nav, NavItem, Tooltip, Badge, CardImg, CardTitle, ButtonGroup } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { Tree } from 'react-arborist';
import Spinner from '../layout/Spinner';
import { getOrgJobPositionPillarAnalysis } from '../../store/actions/authActions';
import { ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar } from 'recharts';

const JobClassTree = (props) => {
    const { auth, companyData, profile, mygroups, orgJobClasses, orgJobPositionSelectedPillarAnalysis, loadingMenu } = props;    
    const [loading, setLoading] = useState(false);
    const [sliderValue, setSliderValue] = useState([]);
    const [nodeName, setnodeName] = useState('');
    const [selectedNode, setSelectedNode] = useState('');
    const [selectedNodeType, setSelectedNodeType] = useState(1);

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    async function treeSelectedNodes(nodes) {
        setLoading(true);
        const jpId = nodes[0]?.data?.id.replace('JCL','').replace('JCO','').replace('JP','');
        const jpType = nodes[0]?.data?.JPType;
        // console.log(jpId);
        if (jpId) {
            setSelectedNode(nodes[0]?.data);
            await props.getOrgJobPositionPillarAnalysis(jpType, jpId);
        }
        setLoading(false);
    }

    function displayJobPositionOverallNumbers() {
        if ((selectedNode) && (selectedNode?.id)) {
        var dRiskPercentage = 0;
        if ((selectedNode?.AtRiskCount > 0) || (selectedNode?.SafeCount > 0)) {
            dRiskPercentage = ((selectedNode?.AtRiskCount * 100)/(selectedNode?.SafeCount + selectedNode?.AtRiskCount)).toFixed(2);
        }

        return (
            <>
                <Table responsive="sm" variant="light" style={{width:'100%', height:'100%', backgroundColor:companyData?.PagesBGColor}}>
                <thead>
                    <tr>
                        <th style={{textAlign:'center', backgroundColor:companyData?.PagesBGColor}}><h5 style={styles.SubHeaderCenter}>{selectedNode?.name}</h5></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <span><h5 style={styles.SubHeader}>Employees</h5></span>
                                    <Table responsive="sm" hover variant="light" style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                        <thead>
                                            <tr>
                                                <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Total</h6></th>
                                                <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>At Risk</h6></th>
                                                <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Percentage</h6></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                    <h1 style={styles.SubHeaderSecondary}>{selectedNode?.SafeCount + selectedNode?.AtRiskCount}</h1>                                                            
                                                </td>
                                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                    <h1 style={styles.SubHeaderPrimary}>{selectedNode?.AtRiskCount}</h1>                                                            
                                                </td>
                                                <td colSpan='2' style={{backgroundColor:companyData?.PagesBGColor}}>
                                                    <h1 style={styles.SubHeaderPrimary}>{dRiskPercentage}%</h1>                                                            
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                        </td>
                    </tr>   
                    <tr>
                        <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <span style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <h5 style={styles.SubHeader}>Compensations</h5>
                                            <Table responsive="sm" hover variant="light" style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                            <thead>
                                                <tr>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Total</h6></th>
                                                    <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Retention At Risk</h6></th>
                                                    {/* <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Percentage</h6></th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                        <h1 style={styles.SubHeaderSecondary}>{selectedNode?.Salaries.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                        })}</h1>
                                                    </td>
                                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                    <h1 style={styles.SubHeaderPrimary}>{selectedNode?.AtRiskAmount.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                        })}</h1>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </span>
                                </div>
                        </td>
                    </tr>
                    </tbody>
            </Table>                                                    
            <Table style={{width:'100%', height:'100%', backgroundColor:companyData?.PagesBGColor}}>
                <tbody>
                    <tr>
                        <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <span style={{backgroundColor:companyData?.PagesBGColor}}>
                                            <Table responsive="sm" hover variant="light" style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                            <tbody>
                                                <tr>
                                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                    {displayJobPositionRadarChart()}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </span>
                                </div>
                        </td>
                    </tr>
                </tbody>
            </Table>                                                    
            </>
            );
        }
    }

    function displayJobPositionRadarChart() {
        if ((orgJobPositionSelectedPillarAnalysis) && (orgJobPositionSelectedPillarAnalysis.length > 0)) {
            var currentJP = orgJobPositionSelectedPillarAnalysis[0];
            const radarChartData = [{
                Pillar:'Performance',
                UserScore: currentJP.AveragePerformanceScore,
                fullMark: 5,
            },
            {
                Pillar:'Safety',
                UserScore: currentJP.AverageWorkSafetyScore,
                fullMark: 5,
            },
            {
                Pillar:'Stress',
                UserScore: currentJP.AverageJobStressScore,
                fullMark: 5,
            },
            {
                Pillar:'Satisfaction',
                UserScore: currentJP.AverageJobSatisfactionScore,
                fullMark: 5,
            },
            {
                Pillar:'Social',
                UserScore: currentJP.AverageSocialSupportScore,
                fullMark: 5,
            },
            {
                Pillar:'Control',
                UserScore: currentJP.AverageControlScore,
                fullMark: 5,
            },
            {
                Pillar:'Efficacy',
                UserScore: currentJP.AveragePersonalEfficacyScore,
                fullMark: 5,
            },
            {
                Pillar:'Turnover',
                UserScore: currentJP.AverageTurnoverIntentionScore,
                fullMark: 5,
            },
            {
                Pillar:'Commitment',
                UserScore: currentJP.AverageOrganizationalCommitmentScore,
                fullMark: 5,
            },
            {
                Pillar:'Cybersecurity',
                UserScore: currentJP.AverageCyberSecurityScore,
                fullMark: 5,
            }];    

            return (
                <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                    <thead>
                        <tr>
                            <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Pillar Analysis</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                <ResponsiveContainer width={'100%'} height={220}>
                                    <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarChartData}>
                                        <PolarGrid />
                                        <PolarAngleAxis dataKey="Pillar" />
                                        <PolarRadiusAxis />
                                        <Radar name={currentJP.Name} dataKey="UserScore" stroke={companyData?.AccentColorSecondary} fill={companyData?.AccentColorSecondary} fillOpacity={0.6} />
                                    </RadarChart>
                                </ResponsiveContainer>
                            </td>
                        </tr>
                    </tbody>
                </Table>     
            );
        }     
    }    

    return (
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
            {(loading) ? (            
            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                <Spinner/>
            </div>
        ) 
        : 
        (                                          
                <Table style={{background:'transparent'}} >
                    <thead>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                    <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                                        <Card.Title>
                                            <div>
                                                <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                                                    <tbody>
                                                    <tr>
                                                        <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Job Profile Tree</h3></td>
                                                        <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                                                            <div className='input-field'>
                                                                <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding} onChange={setSearchNodeName} placeholder='Search Job Profile'/>
                                                            </div>                                            
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </Table>                            
                                            </div>
                                        </Card.Title>
                                    </Card.Header>                   
                                    <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                        <Table responsive="sm" bordered style={{backgroundColor:companyData?.PagesBGColor, padding:'20px 20px 20px 20px'}}>
                                            <tbody>
                                                <tr>
                                                    <td style={{textAlign:'left', width:'55%', backgroundColor:companyData?.PagesBGColor, 
                                                                padding:'0px 0px 0px 40px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                        <Tree 
                                                            width='100%'
                                                            data={orgJobClasses} 
                                                            openByDefault={false}
                                                            indent={30}
                                                            padding={10}
                                                            searchTerm={nodeName}
                                                            searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}   
                                                            onSelect={(nodes) => {treeSelectedNodes(nodes)}}         
                                                            disableEdit={false}                                    
                                                            disableDrag={false}                                    
                                                            disableDrop={false}                                    
                                                            disableMultiSelection={false}                                    
                                                        >
                                                            {/* {Node} */}
                                                        </Tree>
                                                    </td>
                                                    <td style={{textAlign:'center', width:'45%', backgroundColor:companyData?.PagesBGColor, 
                                                                padding:'0px 00px 0px 0px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                            {displayJobPositionOverallNumbers()}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>                            
                                    </Card.Body>
                                    <Card.Footer className="text-left">
                                    </Card.Footer>
                                </Card>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            )}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      orgJobClasses: state.auth.orgJobClasses,
      orgJobPositionSelectedPillarAnalysis: state.auth.orgJobPositionSelectedPillarAnalysis,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getOrgJobPositionPillarAnalysis: (ijptype, id) => dispatch(getOrgJobPositionPillarAnalysis(ijptype, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobClassTree)