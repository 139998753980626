import * as actionTypes from '../actions/actionTypes' 

const initState = {
    entityError: null,
    entitySuccess: null,
    userevaluationsessions: null,
    evaluation: null, 
    evaluationquestions: null,
    evaluationratings: null,
    userevaluationsessionsresponses: null,
    retentionSessionResults: null,
    evaluations: null, 
    assignments: null,
    userattributelookups: null,
    jobpositions:null,
    lookupselections:null,
    goals: null,
    skills: null, 
    skillChecklist: null,
    skillChecklistGroupBy: null,
}

const entityReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GETUSEREVALUATIONSESSION_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETUSEREVALUATIONSESSION_SUCCESS:
            return {
                ...state, 
                userevaluationsessions: action.payload.userevaluationsessions,
                error: null
            }
        case actionTypes.GETUSEREVALUATIONSESSIONRESPONSES_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETUSEREVALUATIONSESSIONRESPONSES_SUCCESS:
            return {
                ...state, 
                userevaluationsessionresponses: action.payload.userevaluationsessionresponses,
                error: null
            }
        case actionTypes.GETEVALUATION_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETEVALUATION_SUCCESS:
            return {
                ...state, 
                evaluation: action.payload.evaluation,
                error: null
            }
        case actionTypes.GETEVALUATIONQUESTIONS_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETEVALUATIONQUESTIONS_SUCCESS:
            return {
                ...state, 
                evaluationquestions: action.payload.evaluationquestions,
                error: null
            }
        case actionTypes.GETEVALUATIONRATINGS_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETEVALUATIONRATINGS_SUCCESS:
            return {
                ...state, 
                evaluationratings: action.payload.evaluationratings,
                error: null
            }
        case actionTypes.UPDATEEVALUATIONRESPONSES_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEEVALUATIONRESPONSES_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEEVALUATIONSESSION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEEVALUATIONSESSION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETRETENTIONPREDICTION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETRETENTIONPREDICTION_SUCCESS:
            return {
                ...state, 
                retentionSessionResults: action.payload
            }
        case actionTypes.GETEVALUATIONS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETEVALUATIONS_SUCCESS:
            return {
                ...state, 
                evaluations: action.payload
            }
        case actionTypes.GETASSIGNMENTS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETASSIGNMENTS_SUCCESS:
            return {
                ...state, 
                assignments: action.payload
            }
        case actionTypes.GETUSERATTRIBUTELOOKUPS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETUSERATTRIBUTELOOKUPS_SUCCESS:
            return {
                ...state, 
                userattributelookups: action.payload.userattributeslookups
            }
        case actionTypes.INSERTUSERLICENSE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTUSERLICENSE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEUSERLICENSE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEUSERLICENSE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTUSERCREDENTIAL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTUSERCREDENTIAL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEUSERCREDENTIAL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEUSERCREDENTIAL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTUSERGOAL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTUSERGOAL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEUSERGOAL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEUSERGOAL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTUSERSKILL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTUSERSKILL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEUSERSKILL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEUSERSKILL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEUSERSKILL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEUSERSKILL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETSKILLS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETSKILLS_SUCCESS:
            return {
                ...state, 
                skills: action.payload
            }
        case actionTypes.INSERTSKILL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTSKILL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETESKILL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETESKILL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATESKILL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATESKILL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETSKILLCHECKLIST_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETSKILLCHECKLIST_SUCCESS:
            return {
                ...state, 
                skillChecklist: action.payload
            }
        case actionTypes.GETSKILLCHECKLISTGROUPBY_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETSKILLCHECKLISTGROUPBY_SUCCESS:
            return {
                ...state, 
                skillChecklistGroupBy: action.payload
            }
        case actionTypes.GETJOBPOSITIONS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETJOBPOSITIONS_SUCCESS:
            return {
                ...state, 
                jobpositions: action.payload
            }
        case actionTypes.GETJOBPOSITIONREQUIREMENTS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETJOBPOSITIONREQUIREMENTS_SUCCESS:
            return {
                ...state, 
                jobpositions: {
                    ...state.jobpositions,  
                    jobpositions: {
                        ...state.jobpositions.jobpositions,
                        JobPositionRequirement: action.payload.JobPositionRequirement
                    }
                },
                authError: null
            }
        case actionTypes.GETJOBPOSITIONSKILLS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETJOBPOSITIONSKILLS_SUCCESS:
            return {
                ...state, 
                jobpositions: {
                    ...state.jobpositions,  
                    jobpositions: {
                        ...state.jobpositions.jobpositions,
                        JobPositionSkill: action.payload.JobPositionSkill
                    }
                },
                authError: null
            }
        case actionTypes.GETJOBPOSITIONEVALUATIONQUESTIONGROUPS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETJOBPOSITIONEVALUATIONQUESTIONGROUPS_SUCCESS:
            return {
                ...state, 
                jobpositions: {
                    ...state.jobpositions,  
                    jobpositions: {
                        ...state.jobpositions.jobpositions,
                        JobPositionEvaluationQuestionGroup: action.payload.JobPositionEvaluationQuestionGroup
                    }
                },
                authError: null
            }
        case actionTypes.UPDATEJPLOOKUPSELECTION_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.UPDATEJPLOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    JobPosition: action.payload.JobPosition
                },
                authError: null
            }
        case actionTypes.UPDATEGOALLOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    Goal: action.payload.Goal
                },
                authError: null
            }
        case actionTypes.UPDATEGOALLOOKUPSELECTION_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.UPDATESKILLLOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    Skill: action.payload.Skill
                },
                authError: null
            }
        case actionTypes.UPDATESKILLLOOKUPSELECTION_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.UPDATEUSERLOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    User: action.payload.User
                },
                authError: null
            }
        case actionTypes.INSERTEVALUATION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTEVALUATION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEEVALUATION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEEVALUATION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEEVALUATION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEEVALUATION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTEVALUATIONQUESTION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTEVALUATIONQUESTION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEEVALUATIONQUESTION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEEVALUATIONQUESTION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEEVALUATIONQUESTION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEEVALUATIONQUESTION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTEVALUATIONRATING_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTEVALUATIONRATING_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEEVALUATIONRATING_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEEVALUATIONRATING_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTASSIGNMENT_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTASSIGNMENT_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEASSIGNMENT_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEASSIGNMENT_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEASSIGNMENT_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEASSIGNMENT_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETGOALS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETGOALS_SUCCESS:
            return {
                ...state, 
                goals: action.payload
            }
        case actionTypes.GETCOMPETENCIES_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETCOMPETENCIES_SUCCESS:
            return {
                ...state, 
                userattributelookups: {
                    ...state.userattributelookups,  
                    Skills: action.payload.Skills
                },
                authError: null
            }
        case actionTypes.CLEARENTITYSTATE_SUCCESS:
            return {
                ...state, 
                entityError: null,
                entitySuccess: null,
                userevaluationsessions: null,
                evaluation: null, 
                evaluationquestions: null,
                evaluationratings: null,
                userevaluationsessionsresponses: null,
                retentionSessionResults: null,
                evaluations: null, 
                assignments: null,
                userattributelookups: null,
                jobpositions:null,
                lookupselections:null,
                goals: null,
                skills: null, 
                skillChecklist: null,
                skillChecklistGroupBy: null,                
            }
                                                                                                                
        default:
            return state;
    }
}

export default entityReducer



