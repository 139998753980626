
export const tokenGenerationUrl = 'https://medfountwebapi.azurewebsites.net/token'
export const tokendata = 'token_data'
export const user_data = 'user_data'
export const user_info = 'user_info'
export const user_id = 'user_id'
export const tokenExpiresOn = 'token_expires_on'
export const product_data = 'product_data'
export const user_subscription_details = ''
export const ThirdPartyLMSEvaluations = null
export const RetentasSurveys = 101
export const triggeredFromAnalytics = 1
export const triggeredFromMenu = 2
export const UserLookupPolicy = 1
export const UserLookupMyTeam = 2
export const AdminRights = 41
export const ManagerRights = 23
export const PreceptorRights = 22
export const CheckListGroupByAssignment = '1'
export const CheckListGroupByCompetency = '2'
export const CheckListGroupByUser = '3'
export const CheckListGroupByAll = '4'
export const MenuItemHome = 1
export const MenuItemProfile = 2
export const MenuItemQuestionnaire = 3
export const MenuItemPerformance = 4
export const MenuItemMyTeam = 5
export const MenuItemPillars = 6
export const MenuItemAssignments = 7
export const MenuItemReports = 8
export const MenuItemLogin = 9
export const MenuItemLogout = 10
export const MenuItemDivisions = 11
export const MenuItemRatings = 12
export const MenuItemJobPositions = 13
export const MenuItemGoals = 14
export const MenuItemCompetencies = 15
export const MenuItemUserLookup = 16
export const MenuItemCompetencyChecklist = 17
export const MenuItemJobClassTree = 18