import React, { useEffect, useState } from 'react'
import { Button, Card, ListGroup, Badge, Table, Image, ButtonGroup, ToggleButton, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { styles } from '../layout/styles'
import moment from 'moment';
import { getRetentionPrediction, updateUserSuggestion } from '../../store/actions/entityActions';
import { getMyTeamRetentionSuggestions, getMyTeamFull, getUserDetails, getMyTeamSelected } from '../../store/actions/authActions';
import { getJobPositionSuccession } from '../../store/actions/entityActions';
import { connect, useSelector } from 'react-redux'
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { SvgRegistry } from "survey-core";
import 'survey-core/defaultV2.min.css';
import NotGood from '../../assets/not-good.svg';
import {triggeredFromAnalytics} from '../../config/Constant';
import Spinner from '../layout/Spinner';
import UpdateProfile from '../auth/UpdateProfile';
import UserSuccession from './UserSuccession';
import { ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar } from 'recharts';

var svg =
'<svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M11.35 1.34999L10.65 0.649994L6.05002 5.24999L1.35002 0.649994L0.650024 1.34999L5.25002 6.04999L0.650024 10.65L1.35002 11.35L6.05002 6.74999L10.65 11.35L11.35 10.65L6.75002 6.04999L11.35 1.34999Z"/></svg>';

SvgRegistry.registerIconFromSvg("icon-test", svg);    

const UserList = (props) => {
    const { myTeam, companyData, retentionSessionResults, auth, myTeamSuggestions, triggeredFrom, myTeamSelected, clickedData, myTeamStat } = props;
    const userlist = (triggeredFrom === triggeredFromAnalytics) ? myTeamSelected?.Users : myTeam?.Users;
    const perfRatings = myTeamStat?.PerformanceRatings;
    const wsRatings = myTeamStat?.WorkSafetyRatings;
    const jsRatings = myTeamStat?.JobStressRatings;
    const jsatRatings = myTeamStat?.JobSatisfactionRatings;
    const csRatings = myTeamStat?.ControlRatings;
    const peRatings = myTeamStat?.PersonalEfficacyRatings;
    const ssRatings = myTeamStat?.SocialSupportRatings;
    const toRatings = myTeamStat?.TurnoverRatings;
    const ocRatings = myTeamStat?.CommitmentRatings;
    const cyRatings = myTeamStat?.CyberSecurityRatings;
    const userlistpaging = (triggeredFrom === triggeredFromAnalytics) ? myTeamSelected : myTeam;
    const typeSummary = 'userlist';
    const navigate = useNavigate();
    const [radioValue, setRadioValue] = useState(initialRadioValue());
    const radios = [
        { name: 'Happy', value: '1' },
        { name: 'Unhappy', value: '2' },
    ];
    const [oneActionClicked, setoneActionClicked] = useState(false);
    const [loading, setloading] = useState(false);
    const [askingMLModel, setAskingMLModel] = useState(false);
    const [currentUserID, setcurrentUserID] = useState(-1);
    const [detailClicked, setdetailClicked] = useState(true);
    const [showDeleteAlertModal, setshowDeleteAlertModal ] = useState(false);
    const [deleteSuggestion, setdeleteSuggestion] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [nodeName, setnodeName] = useState('');
    
    const [showProfileDetails, setShowProfileDetails] = useState(false);
    const [profileDialogUserID, setProfileDialogUserID] = useState(-1);
    const [profileDialogJobPositionID, setProfileDialogJobPositionID] = useState(-1);
    const [showProfileSuccession, setShowProfileSuccession] = useState(false);
    const handleCloseProfileDetails = () => {setShowProfileDetails(false);}
    const handleCloseProfileSuccession = () => {setShowProfileSuccession(false);}
    const [showProfileGapAnalysis, setShowProfileGapAnalysis] = useState(false);
    const handleCloseProfileGapAnalysis = () => {setShowProfileGapAnalysis(false);}

    async function handleShowProfileDetails() {
        setShowProfileDetails(true); 
    };

    async function handleShowProfileSuccession() {
        setShowProfileSuccession(true); 
    };

    async function handleShowProfileGapAnalysis() {
        setShowProfileGapAnalysis(true); 
    };

    async function showProfileDialog(userid) {
        setProfileDialogUserID(userid);
        setloading(true);
        await props.getUserDetails(userid);
        setShowProfileDetails(true);
        setloading(false);
    }

    async function showProfileSuccessionDialog(userid, jpid) {
        setProfileDialogUserID(userid);
        setProfileDialogJobPositionID(jpid);
        setloading(true);
        await props.getJobPositionSuccession(jpid);
        setShowProfileSuccession(true);
        setloading(false);
    }

    async function showProfileGapAnalysisDialog(userid, jpid) {
        setProfileDialogUserID(userid);
        setProfileDialogJobPositionID(jpid);
        setloading(true);
        await props.getJobPositionSuccession(jpid);
        setShowProfileGapAnalysis(true);
        setloading(false);
    }

    function initialRadioValue() {
        // if (triggeredFrom === triggeredFromAnalytics) {
        //     return '2';
        // } else {
        //     return '1';
        // }
        return '2';
    };

    function getSearchScorePValue() {
        if (radioValue === '1') {
            return 'Safe';
        } else {
            return 'AtRisk';
        }
    };

    async function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }
    
    async function searchUsers() {
        setloading(true);

        const oParams = {
            UserID:auth.UserID,
            SearchUser:nodeName,
            ScoredProbabilities:getSearchScorePValue(),
            RecordSize:'10',
            PageNumber:1,
        };            
        console.log(oParams);
        await props.getMyTeamFull(oParams);
        setloading(false);
    }

    function getClickedDataParams(oParams) {
        var clickedSearchData = '';
        if (clickedData) {
            if (clickedData.EntityRowTypeID === 2) {
                oParams.RegionID = clickedData.EntityID;
            } else if (clickedData.EntityRowTypeID === 3) {
                oParams.FacilityID = clickedData.EntityID;
            } else if (clickedData.EntityRowTypeID === 4) {
                oParams.DivisionID = clickedData.EntityID;
            } else if (clickedData.EntityRowTypeID === 5) {
                oParams.JobClassID = clickedData.EntityID;
            } else if (clickedData.EntityRowTypeID === 6) {
                oParams.JobCodeID = clickedData.EntityID;
            } else if (clickedData.EntityRowTypeID === 7) {
                oParams.JobPositionID = clickedData.EntityID;
            } else if (clickedData.EntityRowTypeID === 8) {
                oParams.EmploymentTypeID = clickedData.EntityID;
            } 
        }
        return oParams;
    }
    
    async function previousPage() {
        setloading(true);
        const previousPageNumber = userlistpaging?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            UserID:auth.UserID,
            SearchUser:nodeName,
            ScoredProbabilities:getSearchScorePValue(),
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };    
        if (triggeredFrom === triggeredFromAnalytics) {
            const oParams1 = getClickedDataParams(oParams);
            console.log(oParams1);
            await props.getMyTeamSelected(oParams1);    
        } else {
            console.log(oParams);
            await props.getMyTeamFull(oParams);    
        }
        setloading(false);
    }
    
    async function nextPage() {
        setloading(true);
        const nextPageNumber = userlistpaging?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            UserID:auth.UserID,
            SearchUser:nodeName,
            ScoredProbabilities:getSearchScorePValue(),
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };         
        if (triggeredFrom === triggeredFromAnalytics) {
            const oParams1 = getClickedDataParams(oParams);
            console.log(oParams1);
            await props.getMyTeamSelected(oParams1);    
        } else {
            console.log(oParams);
            await props.getMyTeamFull(oParams);    
        }
        setloading(false);
    }
    
    function getSelectedCount() {
        let iSelected = 0;
        sliderValue.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }

    async function getSelectedValue() {
        let currentSelectedValue = '';
        sliderValue.map((row, index) => {
            if (row.showDetails) {
                if (currentSelectedValue !== '') {
                    currentSelectedValue += '&';
                }
                currentSelectedValue += row.JobPositionID + '=' + row.JobPositionDescription;
            } 
        });
        setSelectedValue(currentSelectedValue);
        await props.assignLookupSelection(5, currentSelectedValue);
        return currentSelectedValue;
    }

    async function handleRadioChange(e) {
        currentRecIndex = 0; 
        setRadioValue(e.currentTarget.value);
        searchUsers();
    }

    function displayListRadioButtons() {
        if (triggeredFrom === triggeredFromAnalytics) {
            let toggleReturn = '';
            radios.map((radio, idx) => {
                if (radio.value === '2') {
                    toggleReturn =  (
                        <ToggleButton
                        key={typeSummary + idx}
                        id={`radio-${typeSummary + idx}`}
                        type="radio"
                        style={radioValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                        name="radio"
                        value={radio.value}
                        checked='true'
                        size='sm'
                        // onChange={(e) => {currentRecIndex = 0; setRadioValue(e.currentTarget.value)}}
                    >
                        {radio.name}
                        </ToggleButton>
                    );
                }
            });
            // setRadioValue('2');
            return toggleReturn;
        } else {
            return (
                radios.map((radio, idx) => (
                    <ToggleButton
                        key={typeSummary + idx}
                        id={`radio-${typeSummary + idx}`}
                        type="radio"
                        style={radioValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        size='sm'
                        onChange={(e) => handleRadioChange(e)}
                    >
                        {radio.name}
                    </ToggleButton>
                ))
            );
        }

    }

    function displayMyTeamHeader() {
        if (triggeredFrom === triggeredFromAnalytics) {
            return <></>;
        } else {
            return (
                <Table responsive="lg" style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <thead>
                    <tr>
                        <th style={{width:'50%',  backgroundColor:companyData?.PagesBGColor, alignItems:'left'}}> <h3 style={styles.SubHeader}>My Team</h3>
                            <div style={{display:'none'}}>
                                <Survey model={survey} />
                            </div>
                        </th>
                        <th style={{width:'5%',  backgroundColor:companyData?.PagesBGColor, alignItems:'center'}}>
                        </th>
                        <th style={{width:'45%',  backgroundColor:companyData?.PagesBGColor, alignItems:'right'}}>
                            <Card.Title>
                                <Table responsive="lg" style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                    <thead>
                                        <tr>
                                            <th style={{width:'70%',  backgroundColor:companyData?.PagesBGColor, alignItems:'right'}}>
                                                <div className='input-field'>
                                                    <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding90} onChange={setSearchNodeName} placeholder='Search Users'/>
                                                    <a onClick={() => searchUsers()}>
                                                        <span style={styles.ActionInsideIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                                                    </a>
                                                </div> 
                                            </th>
                                            <th style={{width:'30%',  backgroundColor:companyData?.PagesBGColor, alignItems:'right'}}>
                                                <ButtonGroup className="mb-2" size='sm'>
                                                    {displayListRadioButtons()}
                                                </ButtonGroup>                                                                   
                                            </th>
                                        </tr>
                                    </thead>                                           
                                </Table>
                            </Card.Title>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </Table>
            );
        }
    }

    function handleDeleteAlertModalClose() { 
        setshowDeleteAlertModal(false);
    }
    
    function handleDeleteAlertModalShow(suggestion) { 
        setshowDeleteAlertModal(true);
        setdeleteSuggestion(suggestion);
    }

    async function handleDeleteAlertModalYes() { 
        setshowDeleteAlertModal(false);
        const suggestions = [{
            RetentasUserSuggestionID:deleteSuggestion.RetentasUserSuggestionID,
            UserID:deleteSuggestion.UserID,
            SuggestionDate: deleteSuggestion.SuggestionDate,
            PerformanceScore: deleteSuggestion.PerformanceScore,
            WorkSafetyScore: deleteSuggestion.WorkSafetyScore,
            IsDeleted: 'true',
            JobStressScore: deleteSuggestion.JobStressScore,
            JobSatisficationScore: deleteSuggestion.JobSatisficationScore,
            Comments: "",
            SocialSupportScore: deleteSuggestion.SocialSupportScore,
            ControlScore: deleteSuggestion.ControlScore,
            PersonalEfficacyScore: deleteSuggestion.PersonalEfficacyScore,
            TurnoverIntentionScore: deleteSuggestion.TurnoverIntentionScore,
            OrganizationalCommitmentScore: deleteSuggestion.OrganizationalCommitmentScore,
            ILTHours: deleteSuggestion.ILTHours,
            CreditUnits: deleteSuggestion.CreditUnits,
            CreditHours: deleteSuggestion.CreditHours,
            CreditOthers: deleteSuggestion.CreditOthers,
            OldScoredLabels: deleteSuggestion.OldScoredLabels,
            OldScoredProbabilities: deleteSuggestion.OldScoredProbabilities,
            NewScoredLabels: deleteSuggestion.NewScoredLabels,
            NewScoredProbabilities: deleteSuggestion.NewScoredProbabilities,
            DataSyncOut:'',
            DataSyncMessageOut:''
        }];
        console.log(suggestions);
        await props.updateUserSuggestion(suggestions);
        await props.getMyTeamRetentionSuggestions(auth.UserID);
    }

    function handleDeleteAlertModalNo() { 
        setshowDeleteAlertModal(false);
        console.log('Do not delete.');
    }    

    let currentRecIndex = 0;
    const survey = new Model({});

    const [sliderValue, setSliderValue] = useState([]);

    const addInitialSliderArray = (userList) => {
        return userList?.map((user) => ({
                            UserID:user.UserID, 
                            Active:(user.Active === true ? 1 : 0),
                            DivisionID:user.DivisionID,
                            FacilityID:user.FacilityID,
                            RegionID:user.RegionID, 
                            JobPositionID:user.JobPositionID,
                            JobClassID:user.JobClassID,
                            JobCodeID:user.JobCodeID,
                            EmploymentTypeID:user.EmploymentTypeID,
                            EmploymentYears:user.EmploymentYears,
                            Age:user.Age,
                            PerformanceScore:user.PerformanceScore, 
                            PerformanceRatingTypeID:user.PerformanceRatingTypeID,
                            PerformanceRatingTypeName:user.PerformanceRatingTypeName,
                            WorkSafetyScore:user.WorkSafetyScore, 
                            WorkSafetyRatingTypeID:user.WorkSafetyRatingTypeID, 
                            WorkSafetyRatingTypeName:user.WorkSafetyRatingTypeName, 
                            JobStressScore:user.JobStressScore,
                            JobStressRatingTypeID:user.JobStressRatingTypeID,
                            JobStressRatingTypeName:user.JobStressRatingTypeName,
                            JobSatisficationScore:user.JobSatisficationScore,
                            JobSatisficationRatingTypeID:user.JobSatisficationRatingTypeID,
                            JobSatisficationRatingTypeName:user.JobSatisficationRatingTypeName,
                            SocialSupportScore:user.SocialSupportScore,
                            SocialSupportRatingTypeID:user.SocialSupportRatingTypeID,
                            SocialSupportRatingTypeName:user.SocialSupportRatingTypeName,
                            ControlScore:user.ControlScore,
                            ControlRatingTypeID:user.ControlRatingTypeID,
                            ControlRatingTypeName:user.ControlRatingTypeName,
                            PersonalEfficacyScore:user.PersonalEfficacyScore,
                            PersonalEfficacyRatingTypeID:user.PersonalEfficacyRatingTypeID,
                            PersonalEfficacyRatingTypeName:user.PersonalEfficacyRatingTypeName,
                            TurnoverIntentionScore:user.TurnoverIntentionScore,
                            TurnoverIntentionRatingTypeID:user.TurnoverIntentionRatingTypeID,
                            TurnoverIntentionRatingTypeName:user.TurnoverIntentionRatingTypeName,
                            OrganizationalCommitmentScore:user.OrganizationalCommitmentScore,
                            OrganizationalCommitmentRatingTypeID:user.OrganizationalCommitmentRatingTypeID,
                            OrganizationalCommitmentRatingTypeName:user.OrganizationalCommitmentRatingTypeName,
                            CyberSecurityScore:user.CyberSecurityScore,
                            CyberSecurityRatingTypeID:user.CyberSecurityRatingTypeID,
                            CyberSecurityRatingTypeName:user.CyberSecurityRatingTypeName,
                            ILTHours:user.ILTHours,
                            CreditUnits:user.CreditUnits,
                            CreditHours:user.CreditHours,
                            CreditOthers:user.CreditOthers,
                            OldScoredLabels:user.ScoredLabels,
                            OldScoredProbabilities:user.ScoredProbabilities,
                            takeAction:false,
                            showDetails:false,
                    }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        // console.log(userlist);
        const initialScoreValues = addInitialSliderArray(userlist);
        setSliderValue(initialScoreValues);
    }, [userlist])

    function getSliderValue(rowIndex, colIndex) {
        let iReturn = 1;
        //console.log(sliderValue);
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (row.UserID === rowIndex)) {
                if (colIndex === 1) {
                    iReturn = row.PerformanceScore;
                } else if (colIndex === 2) {
                    iReturn = row.WorkSafetyScore;
                } else if (colIndex === 3) {
                    iReturn = row.JobStressScore;
                } else if (colIndex === 4) {
                    iReturn = row.JobSatisficationScore;
                } else if (colIndex === 5) {
                    iReturn = row.SocialSupportScore;
                } else if (colIndex === 6) {
                    iReturn = row.ControlScore;
                } else if (colIndex === 7) {
                    iReturn = row.PersonalEfficacyScore;
                } else if (colIndex === 8) {
                    iReturn = row.TurnoverIntentionScore;
                } else if (colIndex === 9) {
                    iReturn = row.OrganizationalCommitmentScore;
                } else {
                    iReturn = 1;
                }
            }
        })
        return iReturn;
    }

    function getSliderText(rowIndex, colIndex) {
        let sReturn = '';
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (row.UserID === rowIndex)) {
                if (colIndex === 1) {
                    sReturn = row.PerformanceRatingTypeName;
                } else if (colIndex === 2) {
                    sReturn = row.WorkSafetyRatingTypeName;
                } else if (colIndex === 3) {
                    sReturn = row.JobStressRatingTypeName;
                } else if (colIndex === 4) {
                    sReturn = row.JobSatisficationRatingTypeName;
                } else if (colIndex === 5) {
                    sReturn = row.SocialSupportRatingTypeName;
                } else if (colIndex === 6) {
                    sReturn = row.ControlRatingTypeName;
                } else if (colIndex === 7) {
                    sReturn = row.PersonalEfficacyRatingTypeName;
                } else if (colIndex === 8) {
                    sReturn = row.TurnoverIntentionRatingTypeName;
                } else if (colIndex === 9) {
                    sReturn =  row.OrganizationalCommitmentRatingTypeName;
                } else {
                    sReturn = '1';
                }
            }
        });
        return sReturn;
    }

    function handleCellSliderChange(rowIndex, colIndex, value) {
        setoneActionClicked(true);
        const valueInt = parseInt(value);
        let updatedSliderValues = sliderValue;
        updatedSliderValues.map((row, index) => {
            if (row.UserID === rowIndex) {
                row.takeAction = true;
                if (colIndex === 1) {
                    row.PerformanceScore = valueInt;
                    const ratingDetails = perfRatings.filter((rating) => (parseInt(rating.Score) === valueInt));
                    if ((ratingDetails) && (ratingDetails.length > 0)) {
                        row.PerformanceRatingTypeID = ratingDetails[0].EvaluationRatingTypeID;
                        row.PerformanceRatingTypeName = ratingDetails[0].EvaluationRatingTypeName;
                    }
                } else if (colIndex === 2) {
                    row.WorkSafetyScore = valueInt;
                    const ratingDetails = wsRatings.filter((rating) => (parseInt(rating.Score) === valueInt));
                    if ((ratingDetails) && (ratingDetails.length > 0)) {
                        row.WorkSafetyRatingTypeID = ratingDetails[0].EvaluationRatingTypeID;
                        row.WorkSafetyRatingTypeName = ratingDetails[0].EvaluationRatingTypeName;
                    }
                } else if (colIndex === 3) {
                    row.JobStressScore = valueInt;
                    const ratingDetails = jsRatings.filter((rating) => (parseInt(rating.Score) === valueInt));
                    if ((ratingDetails) && (ratingDetails.length > 0)) {
                        row.JobStressRatingTypeID = ratingDetails[0].EvaluationRatingTypeID;
                        row.JobStressRatingTypeName = ratingDetails[0].EvaluationRatingTypeName;
                    }
                } else if (colIndex === 4) {
                    row.JobSatisficationScore = valueInt;
                    const ratingDetails = jsatRatings.filter((rating) => (parseInt(rating.Score) === valueInt));
                    if ((ratingDetails) && (ratingDetails.length > 0)) {
                        row.JobSatisficationRatingTypeID = ratingDetails[0].EvaluationRatingTypeID;
                        row.JobSatisficationRatingTypeName = ratingDetails[0].EvaluationRatingTypeName;
                    }
                } else if (colIndex === 5) {
                    row.SocialSupportScore = valueInt;
                    const ratingDetails = ssRatings.filter((rating) => (parseInt(rating.Score) === valueInt));
                    if ((ratingDetails) && (ratingDetails.length > 0)) {
                        row.SocialSupportRatingTypeID = ratingDetails[0].EvaluationRatingTypeID;
                        row.SocialSupportRatingTypeName = ratingDetails[0].EvaluationRatingTypeName;
                    }
                } else if (colIndex === 6) {
                    row.ControlScore = valueInt;
                    const ratingDetails = csRatings.filter((rating) => (parseInt(rating.Score) === valueInt));
                    if ((ratingDetails) && (ratingDetails.length > 0)) {
                        row.ControlRatingTypeID = ratingDetails[0].EvaluationRatingTypeID;
                        row.ControlRatingTypeName = ratingDetails[0].EvaluationRatingTypeName;
                    }
                } else if (colIndex === 7) {
                    row.PersonalEfficacyScore = valueInt;
                    const ratingDetails = peRatings.filter((rating) => (parseInt(rating.Score) === valueInt));
                    if ((ratingDetails) && (ratingDetails.length > 0)) {
                        row.PersonalEfficacyRatingTypeID = ratingDetails[0].EvaluationRatingTypeID;
                        row.PersonalEfficacyRatingTypeName = ratingDetails[0].EvaluationRatingTypeName;
                    }
                } else if (colIndex === 8) {
                    row.TurnoverIntentionScore = valueInt;
                    const ratingDetails = toRatings.filter((rating) => (parseInt(rating.Score) === valueInt));
                    if ((ratingDetails) && (ratingDetails.length > 0)) {
                        row.TurnoverIntentionRatingTypeID = ratingDetails[0].EvaluationRatingTypeID;
                        row.TurnoverIntentionRatingTypeName = ratingDetails[0].EvaluationRatingTypeName;
                    }
                } else if (colIndex === 9) {
                    row.OrganizationalCommitmentScore = valueInt;
                    const ratingDetails = ocRatings.filter((rating) => (parseInt(rating.Score) === valueInt));
                    if ((ratingDetails) && (ratingDetails.length > 0)) {
                        row.OrganizationalCommitmentRatingTypeID = ratingDetails[0].EvaluationRatingTypeID;
                        row.OrganizationalCommitmentRatingTypeName = ratingDetails[0].EvaluationRatingTypeName;
                    }
                }
            }
        });
        setSliderValue(updatedSliderValues);
        setoneActionClicked(false);
    }

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;        
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (parseInt(row.UserID) === parseInt(rowIndex))) {
                bReturn = row.showDetails;
            }
        })
        return bReturn;
    }
 
    function getDetailPreviousPredictions(currentUser) {
        if (myTeamSuggestions !== null) {
            const previousSuggestions = myTeamSuggestions?.filter((user) => ((user.UserID === currentUser.UserID) && (!user.IsDeleted)));
            if (previousSuggestions.length === 0) {
                return (
                    <></>
                );
            } else {
                return previousSuggestions.map((suggestion, suggestionIndex) => (
                                <tr> 
                                    <td style={styles.tableText}>{suggestionIndex + 1}</td> 
                                    <td style={styles.tableText}>
                                        {suggestion?.SuggestionDate && moment(suggestion?.SuggestionDate).format("MM/DD/YYYY hh:mm a")}                                                                      
                                    </td> 
                                    <td style={styles.tableText}>
                                        <span title={suggestion?.PerformanceRatingTypeName}>
                                            <Form.Range
                                                key={(suggestionIndex+1).toString() + 'pperf' + currentUser?.UserID}
                                                id={`range-${(suggestionIndex+1).toString() + 'ppref' + currentUser?.UserID}`}
                                                value={suggestion?.PerformanceScore}
                                                name='hello'
                                                min={1}
                                                max={5}
                                                title={suggestion?.PerformanceRatingTypeName}
                                                disabled
                                                className="custom-slider"/>
                                        </span>                                            
                                    </td> 
                                    <td style={styles.tableText}>
                                        <span title={suggestion?.WorkSafetyRatingTypeName}>
                                        <Form.Range
                                            key={(suggestionIndex+1).toString() + 'pworkSafety' + currentUser?.UserID}
                                            id={`range-${(suggestionIndex+1).toString() + 'pworkSafety' + currentUser?.UserID}`}
                                            value={suggestion?.WorkSafetyScore}
                                            name='hello'
                                            min={1}
                                            max={5}
                                            title={suggestion?.WorkSafetyRatingTypeName}
                                            disabled
                                            className="custom-slider"/>
                                        </span>
                                    </td> 
                                    <td style={styles.tableText}>
                                        <span title={suggestion?.JobStressRatingTypeName}>
                                        <Form.Range
                                            key={(suggestionIndex+1).toString() + 'pstress' + currentUser?.UserID}
                                            id={`range-${(suggestionIndex+1).toString() + 'pstress' + currentUser?.UserID}`}
                                            value={suggestion?.JobStressScore}
                                            name='hello'
                                            min={1}
                                            max={5}
                                            title={suggestion?.JobStressRatingTypeName}
                                            disabled
                                            className="custom-slider"/>
                                        </span>
                                    </td> 
                                    <td style={styles.tableText}>
                                        <span title={suggestion?.JobSatisficationRatingTypeName}>
                                        <Form.Range
                                            key={(suggestionIndex+1).toString() + 'psatisf' + currentUser?.UserID}
                                            id={`range-${(suggestionIndex+1).toString() + 'psatisf' + currentUser?.UserID}`}
                                            value={suggestion?.JobSatisficationScore}
                                            name='hello'
                                            min={1}
                                            max={5}
                                            title={suggestion?.JobSatisficationRatingTypeName}
                                            disabled
                                            className="custom-slider"/>
                                        </span>
                                    </td> 
                                    <td style={styles.tableText}>
                                        <span title={suggestion?.SocialSupportRatingTypeName}>
                                            <Form.Range
                                                key={(suggestionIndex+1).toString() + 'psocial' + currentUser?.UserID}
                                                id={`range-${(suggestionIndex+1).toString() + 'psocial' + currentUser?.UserID}`}
                                                value={suggestion?.SocialSupportScore}
                                                name='hello'
                                                min={1}
                                                max={5}
                                                disabled
                                                className="custom-slider"/>
                                        </span>                                    
                                    </td> 
                                    <td style={styles.tableText}>
                                        <span title={suggestion?.ControlRatingTypeName}>
                                            <Form.Range
                                                key={(suggestionIndex+1).toString() + 'pcontrol' + currentUser?.UserID}
                                                id={`range-${(suggestionIndex+1).toString() + 'pcontrol' + currentUser?.UserID}`}
                                                value={suggestion?.ControlScore}
                                                name='hello'
                                                min={1}
                                                max={5}
                                                title={suggestion?.ControlRatingTypeName}
                                                disabled
                                                className="custom-slider"/>
                                        </span>
                                    </td> 
                                    <td style={styles.tableText}>
                                        <span title={suggestion?.PersonalEfficacyRatingTypeName}>
                                            <Form.Range
                                                key={(suggestionIndex+1).toString() + 'pefficacy' + currentUser?.UserID}
                                                id={`range-${(suggestionIndex+1).toString() + 'pefficacy' + currentUser?.UserID}`}
                                                value={suggestion?.PersonalEfficacyScore}
                                                name='hello'
                                                min={1}
                                                max={5}
                                                title={suggestion?.PersonalEfficacyRatingTypeName}
                                                disabled
                                                className="custom-slider"/>
                                        </span>
                                    </td> 
                                    <td style={styles.tableText}>
                                        <span title={suggestion?.TurnoverIntentionRatingTypeName}>
                                            <Form.Range
                                                key={(suggestionIndex+1).toString() + 'pturnover' + currentUser?.UserID}
                                                id={`range-${(suggestionIndex+1).toString() + 'pturnover' + currentUser?.UserID}`}
                                                value={suggestion?.TurnoverIntentionScore}
                                                name='hello'
                                                min={1}
                                                max={5}
                                                title={suggestion?.TurnoverIntentionRatingTypeName}
                                                disabled
                                                className="custom-slider"/>
                                        </span>
                                    </td> 
                                    <td style={styles.tableText}>
                                        <span title={suggestion?.OrganizationalCommitmentRatingTypeName}>
                                            <Form.Range
                                                key={(suggestionIndex+1).toString() + 'pcommit' + currentUser?.UserID}
                                                id={`range-${(suggestionIndex+1).toString() + 'pcommit' + currentUser?.UserID}`}
                                                value={suggestion?.OrganizationalCommitmentScore}
                                                name='hello'
                                                min={1}
                                                max={5}
                                                title={suggestion?.OrganizationalCommitmentRatingTypeName}
                                                disabled
                                                className="custom-slider"/>
                                        </span>
                                    </td> 
                                    <td style={styles.tableText}>
                                        <div style={{ justifyContent: "center" }}>
                                            {getNewPredictionFromDB(currentUser, suggestion)}  
                                        </div>
                                    </td> 
                                </tr> 
                ));        
            }
        } else {
            <></>
        }
    }

    function displayUserRadarChart(currentUser) {
        const radarChartData = [{
            Pillar:'1',
            UserScore: currentUser.PerformanceScore,
            fullMark: 5,
        },
        {
            Pillar:'2',
            UserScore: currentUser.WorkSafetyScore,
            fullMark: 5,
        },
        {
            Pillar:'3',
            UserScore: currentUser.JobStressScore,
            fullMark: 5,
        },
        {
            Pillar:'4',
            UserScore: currentUser.JobSatisficationScore,
            fullMark: 5,
        },
        {
            Pillar:'5',
            UserScore: currentUser.SocialSupportScore,
            fullMark: 5,
        },
        {
            Pillar:'6',
            UserScore: currentUser.ControlScore,
            fullMark: 5,
        },
        {
            Pillar:'7',
            UserScore: currentUser.PersonalEfficacyScore,
            fullMark: 5,
        },
        {
            Pillar:'8',
            UserScore: currentUser.TurnoverIntentionScore,
            fullMark: 5,
        },
        {
            Pillar:'9',
            UserScore: currentUser.OrganizationalCommitmentScore,
            fullMark: 5,
        },
        {
            Pillar:'10',
            UserScore: currentUser.CyberSecurityScore,
            fullMark: 5,
        }];
        // const radarChartData = [{
        //     Pillar:'Performance',
        //     UserScore: currentUser.PerformanceScore,
        //     fullMark: 5,
        // },
        // {
        //     Pillar:'Safety',
        //     UserScore: currentUser.WorkSafetyScore,
        //     fullMark: 5,
        // },
        // {
        //     Pillar:'Stress',
        //     UserScore: currentUser.JobStressScore,
        //     fullMark: 5,
        // },
        // {
        //     Pillar:'Satisfaction',
        //     UserScore: currentUser.JobSatisficationScore,
        //     fullMark: 5,
        // },
        // {
        //     Pillar:'Social',
        //     UserScore: currentUser.SocialSupportScore,
        //     fullMark: 5,
        // },
        // {
        //     Pillar:'Control',
        //     UserScore: currentUser.ControlScore,
        //     fullMark: 5,
        // },
        // {
        //     Pillar:'Efficacy',
        //     UserScore: currentUser.PersonalEfficacyScore,
        //     fullMark: 5,
        // },
        // {
        //     Pillar:'Turnover',
        //     UserScore: currentUser.TurnoverIntentionScore,
        //     fullMark: 5,
        // },
        // {
        //     Pillar:'Commitment',
        //     UserScore: currentUser.OrganizationalCommitmentScore,
        //     fullMark: 5,
        // },
        // {
        //     Pillar:'Cybersecurity',
        //     UserScore: currentUser.CyberSecurityScore,
        //     fullMark: 5,
        // }];
              
        return (
            <ResponsiveContainer width={160} height={160}>
              <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarChartData}>
                <PolarGrid />
                <PolarAngleAxis dataKey="Pillar" />
                <PolarRadiusAxis />
                <Radar name={currentUser.FirstName} dataKey="UserScore" stroke={companyData?.AccentColorSecondary} fill={companyData?.AccentColorSecondary} fillOpacity={0.6} />
              </RadarChart>
            </ResponsiveContainer>
          );
    }

    function getDetailSwitchTR(currentUser) {
        const userRequestList = sliderValue.filter((user) => user.UserID === currentUser.UserID);
        let previousSuggestions = null;
        if (myTeamSuggestions != null) {
            previousSuggestions = myTeamSuggestions?.filter((user) => ((user.UserID === currentUser.UserID) && (!user.IsDeleted)));
        }
        if (userRequestList[0]?.showDetails) {
            return (
                <tr id={`tr${'detail' + currentUser.UserID}`}>
                <td colSpan="13" style={{backgroundColor:companyData?.PagesBGColor}}> 

                    <table class="table table-sm caption-top">  
                        <caption style={styles.tableTextLeftBold}>New Prediction</caption>
                        <thead>
                            <tr>
                                <th style={styles.tableText}></th>
                                <th style={styles.tableText}>Date</th>
                                <th style={styles.tableText}>Performance</th>
                                <th style={styles.tableText}>Safety</th>
                                <th style={styles.tableText}>Stress</th>
                                <th style={styles.tableText}>Satisfaction</th>
                                <th style={styles.tableText}>Social</th>
                                <th style={styles.tableText}>Control</th>
                                <th style={styles.tableText}>Efficacy</th>
                                <th style={styles.tableText}>Turnover</th>
                                <th style={styles.tableText}>Commitment</th>
                                <th style={styles.tableText}>Action</th>
                            </tr>
                        </thead>                        
                        {/* -- mb-0 */}
                        <tbody> 
                            <tr> 
                                <td style={styles.tableText}></td> 
                                <td style={styles.tableText10}>
                                    {moment().format("MM/DD/YYYY hh:mm a")}                                                                    
                                </td> 
                                <td style={styles.tableText}>
                                    <OverlayTrigger 
                                        delay={{ hide: 450, show: 300 }} 
                                        overlay={(props) => ( 
                                        <Tooltip {...props}> 
                                            {getSliderText(currentUser?.UserID, 1)} 
                                        </Tooltip> 
                                        )} 
                                        placement="bottom"
                                    >                               
                                        <Form.Range
                                            key={'perf' + currentUser?.UserID}
                                            id={`range-${'pref' + currentUser?.UserID}`}
                                            defaultValue={getSliderValue(currentUser?.UserID, 1)}//{userRequestList[0]?.PerformanceScore}
                                            name='perfRange'
                                            onChange={(e) => {handleCellSliderChange(currentUser?.UserID, 1, e.currentTarget.value)}}
                                            min={1}
                                            max={5}
                                            className="custom-slider"/>
                                    </OverlayTrigger>                                
                                </td> 
                                <td style={styles.tableText}>
                                    <OverlayTrigger 
                                        delay={{ hide: 450, show: 300 }} 
                                        overlay={(props) => ( 
                                        <Tooltip {...props}> 
                                            {getSliderText(currentUser?.UserID, 2)} 
                                        </Tooltip> 
                                        )} 
                                        placement="bottom"
                                    >                               
                                    <Form.Range
                                        key={'workSafety' + currentUser?.UserID}
                                        id={`range-${'workSafety' + currentUser?.UserID}`}
                                        defaultValue={getSliderValue(currentUser?.UserID, 2)}//{userRequestList[0]?.WorkSafetyScore}
                                        name='wsRange'
                                        onChange={(e) => {handleCellSliderChange(currentUser?.UserID, 2, e.currentTarget.value)}}
                                        min={1}
                                        max={5}
                                        className="custom-slider"/>
                                    </OverlayTrigger>
                                </td> 
                                <td style={styles.tableText}>
                                    <OverlayTrigger 
                                        delay={{ hide: 450, show: 300 }} 
                                        overlay={(props) => ( 
                                        <Tooltip {...props}> 
                                            {getSliderText(currentUser?.UserID, 3)} 
                                        </Tooltip> 
                                        )} 
                                        placement="bottom"
                                    >                               
                                    <Form.Range
                                        key={'stress' + currentUser?.UserID}
                                        id={`range-${'stress' + currentUser?.UserID}`}
                                        defaultValue={getSliderValue(currentUser?.UserID, 3)}//{userRequestList[0]?.JobStressScore}
                                        name='jsRange'
                                        onChange={(e) => {handleCellSliderChange(currentUser?.UserID, 3, e.currentTarget.value)}}
                                        min={1}
                                        max={5}
                                        className="custom-slider"/>
                                    </OverlayTrigger>    
                                </td> 
                                <td style={styles.tableText}>
                                    <OverlayTrigger 
                                        delay={{ hide: 450, show: 300 }} 
                                        overlay={(props) => ( 
                                        <Tooltip {...props}> 
                                            {getSliderText(currentUser?.UserID, 4)} 
                                        </Tooltip> 
                                        )} 
                                        placement="bottom"
                                    >                               
                                    <Form.Range
                                        key={'satisf' + currentUser?.UserID}
                                        id={`range-${'satisf' + currentUser?.UserID}`}
                                        defaultValue={getSliderValue(currentUser?.UserID, 4)}//{userRequestList[0]?.JobSatisficationScore}
                                        name='jsatisRange'
                                        onChange={(e) => {handleCellSliderChange(currentUser?.UserID, 4, e.currentTarget.value)}}
                                        min={1}
                                        max={5}
                                        className="custom-slider"/>
                                    </OverlayTrigger>
                                </td> 
                                <td style={styles.tableText}>
                                    <OverlayTrigger 
                                        delay={{ hide: 450, show: 300 }} 
                                        overlay={(props) => ( 
                                        <Tooltip {...props}> 
                                            {getSliderText(currentUser?.UserID, 5)} 
                                        </Tooltip> 
                                        )} 
                                        placement="bottom"
                                    >                               
                                    <Form.Range
                                        key={'social' + currentUser?.UserID}
                                        id={`range-${'social' + currentUser?.UserID}`}
                                        defaultValue={getSliderValue(currentUser?.UserID, 5)}//{userRequestList[0]?.SocialSupportScore}
                                        name='socialRange'
                                        onChange={(e) => {handleCellSliderChange(currentUser?.UserID, 5, e.currentTarget.value)}}
                                        min={1}
                                        max={5}
                                        className="custom-slider"/>
                                    </OverlayTrigger>
                                </td> 
                                <td style={styles.tableText}>
                                    <OverlayTrigger 
                                        delay={{ hide: 450, show: 300 }} 
                                        overlay={(props) => ( 
                                        <Tooltip {...props}> 
                                            {getSliderText(currentUser?.UserID, 6)} 
                                        </Tooltip> 
                                        )} 
                                        placement="bottom"
                                    >                               
                                    <Form.Range
                                        key={'control' + currentUser?.UserID}
                                        id={`range-${'control' + currentUser?.UserID}`}
                                        defaultValue={getSliderValue(currentUser?.UserID, 6)}//{userRequestList[0]?.ControlScore}
                                        name='conRange'
                                        onChange={(e) => {handleCellSliderChange(currentUser?.UserID, 6, e.currentTarget.value)}}
                                        min={1}
                                        max={5}
                                        className="custom-slider"/>
                                    </OverlayTrigger>
                                </td> 
                                <td style={styles.tableText}>
                                    <OverlayTrigger 
                                        delay={{ hide: 450, show: 300 }} 
                                        overlay={(props) => ( 
                                        <Tooltip {...props}> 
                                            {getSliderText(currentUser?.UserID, 7)} 
                                        </Tooltip> 
                                        )} 
                                        placement="bottom"
                                    >                               
                                    <Form.Range
                                        key={'efficacy' + currentUser?.UserID}
                                        id={`range-${'efficacy' + currentUser?.UserID}`}
                                        defaultValue={getSliderValue(currentUser?.UserID, 7)}//{userRequestList[0]?.PersonalEfficacyScore}
                                        name='effRange'
                                        onChange={(e) => {handleCellSliderChange(currentUser?.UserID, 7, e.currentTarget.value)}}
                                        min={1}
                                        max={5}
                                        className="custom-slider"/>
                                    </OverlayTrigger>
                                </td> 
                                <td style={styles.tableText}>
                                    <OverlayTrigger 
                                        delay={{ hide: 450, show: 300 }} 
                                        overlay={(props) => ( 
                                        <Tooltip {...props}> 
                                            {getSliderText(currentUser?.UserID, 8)} 
                                        </Tooltip> 
                                        )} 
                                        placement="bottom"
                                    >                               
                                    <Form.Range
                                        key={'turnover' + currentUser?.UserID}
                                        id={`range-${'turnover' + currentUser?.UserID}`}
                                        defaultValue={getSliderValue(currentUser?.UserID, 8)}//{userRequestList[0]?.TurnoverIntentionScore}
                                        name='toRange'
                                        onChange={(e) => {handleCellSliderChange(currentUser?.UserID, 8, e.currentTarget.value)}}
                                        min={1}
                                        max={5}
                                        className="custom-slider"/>
                                    </OverlayTrigger>    
                                </td> 
                                <td style={styles.tableText}>
                                    <OverlayTrigger 
                                        delay={{ hide: 450, show: 300 }} 
                                        overlay={(props) => ( 
                                        <Tooltip {...props}> 
                                            {getSliderText(currentUser?.UserID, 9)} 
                                        </Tooltip> 
                                        )} 
                                        placement="bottom"
                                    >                               
                                    <Form.Range
                                        key={'commit' + currentUser?.UserID}
                                        id={`range-${'commit' + currentUser?.UserID}`}
                                        defaultValue={getSliderValue(currentUser?.UserID, 9)}//{userRequestList[0]?.OrganizationalCommitmentScore}
                                        name='commRange'
                                        onChange={(e) => {handleCellSliderChange(currentUser?.UserID, 9, e.currentTarget.value)}}
                                        min={1}
                                        max={5}
                                        className="custom-slider"/>
                                        {/* <p>Selected Value: {sliderValue}</p> */}
                                    </OverlayTrigger>
                                </td> 
                                <td style={styles.tableText}>
                                    {((askingMLModel && currentUser?.UserID === currentUserID) ?    
                                        <div>                                                    
                                            <span class="material-symbols-outlined" style={{color:companyData?.AccentColor, fontSize:'36px', cursor: 'pointer'}}>network_intelligence_history</span>
                                            <span style={styles.LinkBoldGrey}>Predicting...</span>
                                        </div>
                                        :
                                            <a title='Predict via ML Model' onClick={(e) => askObservatisSingle(e, currentUser)}>
                                                <span class="material-symbols-outlined" style={{color:companyData?.Grey, fontSize:'36px', cursor: 'pointer'}}>network_intelligence</span>
                                            </a>
                                        )}
                                </td> 
                            </tr> 
                        </tbody> 
                    </table> 


                    {/* {getDetailPreviousPredictions(currentUser)}   */}

                    {(previousSuggestions.length === 0) ? <></> : 
                        <table class="table table-sm caption-top">  
                            <caption style={styles.tableTextLeftBold}>Previous Predictions</caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableText}>#</th>
                                    <th style={styles.tableTextMain}>Date</th>
                                    <th style={styles.tableText}>Performance</th>
                                    <th style={styles.tableText}>Safety</th>
                                    <th style={styles.tableText}>Stress</th>
                                    <th style={styles.tableText}>Satisfaction</th>
                                    <th style={styles.tableText}>Social</th>
                                    <th style={styles.tableText}>Control</th>
                                    <th style={styles.tableText}>Efficacy</th>
                                    <th style={styles.tableText}>Turnover</th>
                                    <th style={styles.tableText}>Commitment</th>
                                    <th style={styles.tableText15}>Result</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                                {getDetailPreviousPredictions(currentUser)}  
                            </tbody>
                        </table>    
                    }
                </td> 
            </tr>             
            );
        }
    }

    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);//[...sliderValue];
        updatedSliderValues.map((row, index) => {
            if (row.UserID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
    }

    async function askObservatis(e) {
        e.preventDefault();
        console.log(sliderValue);
        const userRequestList = sliderValue.filter((user) => user.takeAction === true);
        console.log(userRequestList);
        const retentionSessions = {
            'Inputs': {
                'input1':userRequestList
            },
            "GlobalParameters": {}
        }
        console.log(retentionSessions);
        // check predictions
        const retentionSessionsResults = await props.getRetentionPrediction(retentionSessions);
        console.log(retentionSessionsResults);
    }

    async function askObservatisSingle(e, currentUser) {
        e.preventDefault();
        setcurrentUserID(currentUser.UserID);
        //setloading(true);
        setAskingMLModel(true);


        // console.log(sliderValue);
        const userRequestList = sliderValue.filter((user) => user.takeAction === true);
        // console.log(userRequestList);
        const retentionSessions = {
            'Inputs': {
                'input1':userRequestList
            },
            "GlobalParameters": {}
        }
        console.log(retentionSessions);
        // check predictions
        const retentionSessionsResults = await props.getRetentionPrediction(retentionSessions);
        console.log(retentionSessionsResults);
        await props.getMyTeamRetentionSuggestions(auth.UserID);
        setcurrentUserID(-1);
        //setloading(false);
        setAskingMLModel(false);
    }

    function getNewPredictionFromDB(currentUser, suggestionRow) {
        if ((suggestionRow) && (suggestionRow['OldScoredProbabilities'] != undefined) && (suggestionRow['OldScoredProbabilities'] != null) && 
            (suggestionRow['NewScoredProbabilities'] != undefined) && (suggestionRow['NewScoredProbabilities'] != null)) {
            const newPrediction = suggestionRow['NewScoredProbabilities'];
            const oldPrediction = suggestionRow['OldScoredProbabilities'];
            const percentageUpDown = (((newPrediction - oldPrediction) * 100) / oldPrediction).toFixed(2);
            if (percentageUpDown > 0) {
                return (
                    <div style={{ display: 'flex', justifyContent: "center" }}>
                        <span>{percentageUpDown}%</span>
                        <span class="material-symbols-outlined" style={{color:companyData?.StronglyAgreeColor, fontSize:'25px', cursor: 'pointer'}}>arrow_circle_up</span>
                        <a onClick={() => handleDeleteAlertModalShow(suggestionRow)}>
                            <span class="material-symbols-outlined" style={{color:companyData?.Grey, fontSize:'25px', cursor: 'pointer'}}>delete</span>
                        </a>
                    </div>
                );
            } else {
                return (
                    <div style={{ display: 'flex', justifyContent: "center" }}>
                        <span>{percentageUpDown}%</span>
                        <span class="material-symbols-outlined" style={{color:companyData?.AccentColor, fontSize:'25px', cursor: 'pointer'}}>arrow_circle_down</span>
                        <a onClick={() => handleDeleteAlertModalShow(suggestionRow)}>
                            <span class="material-symbols-outlined" style={{color:companyData?.Grey, fontSize:'25px', cursor: 'pointer'}}>delete</span>
                        </a>
                    </div>
                );
            }    
        }
    }


    function getNewPrediction(id, oldPrediction) {
        //const newPredictionRow = sliderValue.filter((user) => user.UserID === id)[0];
        const newPredictionRow = retentionSessionResults?.retentionSessionResults?.Results?.WebServiceOutput0?.filter((user) => user.UserID === id)[0];
        if ((newPredictionRow) && (newPredictionRow['Scored Probabilities'] != undefined) && (newPredictionRow['Scored Probabilities'] != null)) {
            const newPrediction = newPredictionRow['Scored Probabilities'];
            const percentageUpDown = (((newPrediction - oldPrediction) * 100) / oldPrediction);
            if (percentageUpDown > 0) {
                return (
                    <div>
                        <span>{percentageUpDown}%<span class="material-symbols-outlined" style={{color:companyData?.AccentColor, fontSize:'25px', cursor: 'pointer'}}>arrow_circle_up</span></span>
                    </div>
                );
            } else {
                return (
                    <div>
                        <span>{percentageUpDown}%<span class="material-symbols-outlined" style={{color:companyData?.AccentColor, fontSize:'25px', cursor: 'pointer'}}>arrow_circle_down</span></span>
                    </div>
                );
            }
    
        }
    }

    function getcurrentRecordIndex(){
        currentRecIndex++;        
        return currentRecIndex;
    }
    
    function getSmileyRating(ratingScore) {
        if (ratingScore === 1) {
            return (    <label class="sd-rating__item-smiley sd-rating__item-smiley--allowhover sd-rating__item-smiley--scale-colored" style={styles.smileyNotGood}>
                            <svg class="sv-svg-icon" role="img"><use href='#icon-not-good'></use><title>1</title></svg>
                        </label>
                    );
        } else if (ratingScore === 2) {
            return (    <label class="sd-rating__item-smiley sd-rating__item-smiley--allowhover sd-rating__item-smiley--scale-colored" style={styles.smileyAverage}>
                            <svg class="sv-svg-icon" role="img"><use href="#icon-average"></use><title>2</title></svg>
                        </label>    
            );
        } else if (ratingScore === 3) {
            return (    <label class="sd-rating__item-smiley sd-rating__item-smiley--allowhover sd-rating__item-smiley--scale-colored" style={styles.smileyNormal}>
                            <svg class="sv-svg-icon" role="img"><use href="#icon-normal"></use><title>3</title></svg>
                        </label>
            );
        } else if (ratingScore === 4) {
            return (    <label class="sd-rating__item-smiley sd-rating__item-smiley--allowhover sd-rating__item-smiley--scale-colored" style={styles.smileyGood}>
                            <svg class="sv-svg-icon" role="img"><use href="#icon-good"></use><title>4</title></svg>
                        </label>
            );
        } else if (ratingScore === 5) {
            return (    <label class="sd-rating__item-smiley sd-rating__item-smiley--allowhover sd-rating__item-smiley--scale-colored" style={styles.smileyVeryGood}>
                            <svg class="sv-svg-icon" role="img"><use href="#icon-very-good"></use><title>5</title></svg>
                        </label>
            );
        } 
    }

    function getGoogleSmileyRating(ratingScore, ratingTypeName) {
        if (ratingScore === 1) {
            return (    
                <Table style={{background:'transparent'}} >
                    <thead>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <span class="material-symbols-outlined" title={ratingScore} style={{color:companyData?.StronglyDisagreeColor, fontSize:'48px', fontWeight:'lighter', fontStyle:'normal'}}>sentiment_very_dissatisfied</span>
                            </th>
                        </tr>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <label class="form-check-label">{ratingTypeName}</label>
                            </th>
                        </tr>
                    </thead>
                </Table>
            );
        } else if (ratingScore === 2) {
            return (    
                <Table style={{background:'transparent'}} >
                    <thead>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <span class="material-symbols-outlined" title={ratingScore} style={{color:companyData?.DisagreeColor, fontSize:'48px', fontWeight:'lighter', fontStyle:'normal'}}>sentiment_dissatisfied</span>
                            </th>
                        </tr>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <label class="form-check-label">{ratingTypeName}</label>
                            </th>
                        </tr>
                    </thead>
                </Table>
            );
        } else if (ratingScore === 3) {
            return (    
                <Table style={{background:'transparent'}} >
                    <thead>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <span class="material-symbols-outlined" title={ratingScore} style={{color:companyData?.NeutralColor, fontSize:'48px', fontWeight:'lighter', fontStyle:'normal'}}>sentiment_neutral</span>                
                            </th>
                        </tr>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <label class="form-check-label">{ratingTypeName}</label>
                            </th>
                        </tr>
                    </thead>
                </Table>
            );
        } else if (ratingScore === 4) {
            return (    
                <Table style={{background:'transparent'}} >
                    <thead>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <span class="material-symbols-outlined" title={ratingScore} style={{color:companyData?.AgreeColor, fontSize:'48px', fontWeight:'lighter', fontStyle:'normal'}}>sentiment_satisfied</span>                
                            </th>
                        </tr>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <label class="form-check-label">{ratingTypeName}</label>
                            </th>
                        </tr>
                    </thead>
                </Table>
            );
        } else if (ratingScore === 5) {
            return (    
                <Table style={{background:'transparent'}} >
                    <thead>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <span class="material-symbols-outlined" title={ratingScore} style={{color:companyData?.StronglyAgreeColor, fontSize:'48px', fontWeight:'lighter', fontStyle:'normal'}}>sentiment_very_satisfied</span>                
                            </th>
                        </tr>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <label class="form-check-label">{ratingTypeName}</label>
                            </th>
                        </tr>
                    </thead>
                </Table>
            );
        } 
    }

    function displayListNameCell(user) {
        return (
            <Table style={{background:'transparent'}} >
                <thead>
                    <tr>
                        <th style={styles.tableTextMainColumnTransparent}>
                            {user?.FullName}&nbsp;&nbsp;&nbsp;&nbsp;
                        </th>
                        <th style={styles.tableTextMainColumnTransparent}>
                            <a onClick={() => {showProfileDialog(user?.UserID)}}>
                                <span style={styles.ActionInsideIconGrey} class="material-symbols-outlined" title='View Profile'>person_pin</span>
                            </a>
                        </th>
                    </tr>
                    <tr>
                        <th colSpan='2' style={styles.tableTextMainColumnTransparent}>
                            <label style={{fontSize:'13px'}}>{user?.JobPositionDescription}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                        </th>
                    </tr>
                    <tr>
                        <th style={styles.tableTextMainColumnTransparent}>
                            <label>Competency Analysis</label>
                        </th>
                        <th style={styles.tableTextMainColumnTransparent}>
                            <a onClick={() => {showProfileGapAnalysisDialog(user?.UserID, user?.JobPositionID)}}>
                                <span style={styles.ActionInsideIconGrey} class="material-symbols-outlined" title='Competency & Gap Analysis'>workspace_premium</span>
                            </a>
                        </th>
                    </tr>
                    <tr>
                        <th style={styles.tableTextMainColumnTransparent}>
                            <label>Succession Planning</label>
                        </th>
                        <th style={styles.tableTextMainColumnTransparent}>
                            <a onClick={() => {showProfileSuccessionDialog(user?.UserID, user?.JobPositionID)}}>
                                <span style={styles.ActionInsideIconGrey} class="material-symbols-outlined" title='View Succession'>follow_the_signs</span>
                            </a>
                        </th>
                    </tr>
                    <tr>
                        <th style={styles.tableTextMainColumnTransparent}>
                            <label htmlFor={`switch${'detail' + user.UserID}`}>Retention Details</label>
                        </th>
                        <th style={styles.tableTextMainColumnTransparent}>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" 
                                    checked={getDetailSwitchValue(user?.UserID)} id={`switch${'detail' + user.UserID}`} 
                                    style={(getDetailSwitchValue(user?.UserID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                    onChange={(e) => {handleSwitchDetailChange(user?.UserID)}}>
                                </input>
                            </div>
                        </th>
                    </tr>
                </thead>
            </Table>
        );
    }

    return ( 
        
        <div>
            <Modal style={{background:'transparent'}} aria-labelledby="contained-modal-title-vcenter" centered 
                show={showDeleteAlertModal} onHide={handleDeleteAlertModalClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton={false}>
                <Modal.Title>Do you want to delete this prediction?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>If you select 'Yes', it cannot be reverted. Please confirm.</p>                            
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" style={{backgroundColor:companyData?.AccentColor}} onClick={handleDeleteAlertModalYes}>
                    Yes
                </Button>
                <Button variant="secondary" onClick={handleDeleteAlertModalNo}>
                    No
                </Button>
                </Modal.Footer>
            </Modal> 

            <Modal style={{background:'transparent', maxHeight:'100%'}} show={showProfileDetails} onShow={handleShowProfileDetails} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseProfileDetails}>
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UpdateProfile fromUserList={true}/>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleCloseProfileDetails}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal style={{background:'transparent', maxHeight:'100%'}} show={showProfileSuccession} onShow={handleShowProfileSuccession} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseProfileSuccession}>
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserSuccession isSelfAnalysis={false} viewUserID={profileDialogUserID}/>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleCloseProfileSuccession}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal style={{background:'transparent', maxHeight:'100%'}} show={showProfileGapAnalysis} onShow={handleShowProfileGapAnalysis} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseProfileGapAnalysis}>
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserSuccession isSelfAnalysis={true} viewUserID={profileDialogUserID}/>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleCloseProfileGapAnalysis}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            {(userlist) ? 
                (

                    <Card className="text-center">
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            {displayMyTeamHeader()}
                        </Card.Header>
                        {(loading) ? (            
                            <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                    <Spinner/>
                                </div>
                            </Card.Body>
                        ) 
                        : 
                        (                                          
                            <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                {(userlist?.length === 0) ? (
                                            <div style={styles.tableText}>
                                                No direct reports.
                                            </div>
                                        ) : (
                                            <div>
                                                <Table responsive="sm" striped bordered hover variant="light">
                                                    <thead>
                                                    <tr>
                                                        <th style={styles.tableText}>#</th>
                                                        <th style={styles.tableTextMain}>Name</th>
                                                        <th style={styles.tableText}>Performance-1</th>
                                                        <th style={styles.tableText}>Safety-2</th>
                                                        <th style={styles.tableText}>Stress-3</th>
                                                        <th style={styles.tableText}>Satisfaction-4</th>
                                                        <th style={styles.tableText}>Social-5</th>
                                                        <th style={styles.tableText}>Control-6</th>
                                                        <th style={styles.tableText}>Efficacy-7</th>
                                                        <th style={styles.tableText}>Turnover-8</th>
                                                        <th style={styles.tableText}>Commitment-9</th>
                                                        <th style={styles.tableText}>Cybersecurity-10</th>
                                                        <th style={styles.tableText}>Overall</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {userlist && userlist.map((user, index) => {
                                                            const isUnHappy = (parseFloat(user?.ScoredProbabilities) === 1) ? 1 : 2;
                                                            //console.log(user?.FullName + ' ' + user?.ScoredProbabilities + ' ' + radioValue + ' ' + isUnHappy);
                                                            if (parseInt(radioValue) === isUnHappy)
                                                            return (
                                                                <>
                                                                <tr>
                                                                    <td style={styles.tableText}>{getcurrentRecordIndex()}</td>
                                                                    <td style={styles.tableText}>{displayListNameCell(user)}</td>
                                                                    <td style={styles.tableText}>
                                                                        {getGoogleSmileyRating(user?.PerformanceScore, user?.PerformanceRatingTypeName)}
                                                                    </td>
                                                                    <td style={styles.tableText}>
                                                                        {getGoogleSmileyRating(user?.WorkSafetyScore, user?.WorkSafetyRatingTypeName)}
                                                                    </td>
                                                                    <td style={styles.tableText}>
                                                                        {getGoogleSmileyRating(user?.JobStressScore, user?.JobStressRatingTypeName)}
                                                                    </td>
                                                                    <td style={styles.tableText}>
                                                                        {getGoogleSmileyRating(user?.JobSatisficationScore, user?.JobSatisficationRatingTypeName)}
                                                                    </td>
                                                                    <td style={styles.tableText}>
                                                                        {getGoogleSmileyRating(user?.SocialSupportScore, user?.SocialSupportRatingTypeName)}
                                                                    </td>
                                                                    <td style={styles.tableText}>
                                                                        {getGoogleSmileyRating(user?.ControlScore, user?.ControlRatingTypeName)}
                                                                    </td>
                                                                    <td style={styles.tableText}>
                                                                        {getGoogleSmileyRating(user?.PersonalEfficacyScore, user?.PersonalEfficacyRatingTypeName)}
                                                                    </td>
                                                                    <td style={styles.tableText}>   
                                                                        {getGoogleSmileyRating(user?.TurnoverIntentionScore, user?.TurnoverIntentionRatingTypeName)}
                                                                    </td>
                                                                    <td style={styles.tableText}>
                                                                        {getGoogleSmileyRating(user?.OrganizationalCommitmentScore, user?.OrganizationalCommitmentRatingTypeName)}
                                                                    </td>
                                                                    <td style={styles.tableText}>
                                                                        {getGoogleSmileyRating(user?.CyberSecurityScore, user?.CyberSecurityRatingTypeName)}
                                                                    </td>
                                                                    <td style={styles.tableText}>      
                                                                        {displayUserRadarChart(user)}
                                                                    </td>
                                                                </tr>

                                                                {getDetailSwitchTR(user)}

                                                                </>
                                                            )
                                                        }
                                                    ) 
                                                    }    

                                                    </tbody>
                                                </Table>        
                                            </div>
                                        )}

                                    <div>
                                    </div>
                            </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{backgroundColor:companyData?.PagesBGColor, alignItems:'end'}}>
                            <Table variant="light" style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{userlistpaging?.RecordFound.toLocaleString()}   Users&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(userlistpaging?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(userlistpaging?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {userlistpaging?.PagingMetaData?.PageNumber}  of  {userlistpaging?.PagingMetaData?.TotalPage.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                        </Card>
                    )
                    :
                    (<></>)}
                </div>
            )        

}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth.user,
        companyData: state?.auth?.companyData,
        myTeamSuggestions: state.auth.myTeamSuggestionData,
        myTeamSelected: state.auth.myTeamSelected,
        retentionSessionResults: state.entity.retentionSessionResults,
        myTeamStat: state.auth.myTeamStat,
   }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRetentionPrediction: (retentionSessions) => dispatch(getRetentionPrediction(retentionSessions)),
        getMyTeamRetentionSuggestions: (id) => dispatch(getMyTeamRetentionSuggestions(id)),
        updateUserSuggestion: (suggestions) => dispatch(updateUserSuggestion(suggestions)),
        getMyTeamFull: (teamParams) => dispatch(getMyTeamFull(teamParams)),
        getUserDetails: (id) => dispatch(getUserDetails(id)),
        getJobPositionSuccession: (id) => dispatch(getJobPositionSuccession(id)),
        getMyTeamSelected: (teamParams) => dispatch(getMyTeamSelected(teamParams)),
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
